import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-scan-packages',
  templateUrl: './scan-packages.component.html',
  styleUrls: ['./scan-packages.component.scss']
})
export class ScanPackagesComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
