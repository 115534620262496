import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'oka-admin-base-details-page',
  templateUrl: './base-details-page.component.html',
  styleUrls: ['./base-details-page.component.scss']
})
export class BaseDetailsPageComponent implements OnInit {
  @Input() title = '';
  @Input() subtitle = '';
  @Input() description = '';

  constructor() {}

  ngOnInit(): void {}
}
