<nz-page-header>
  <nz-page-header-title>All Products</nz-page-header-title>
  <nz-page-header-subtitle></nz-page-header-subtitle>
  <nz-page-header-extra>
    <button nz-button (click)="exportData('csv')">Export as CSV</button>
    <!-- <button nz-button (click)="exportData('xls')">Export as XLS</button> -->
  </nz-page-header-extra>
</nz-page-header>

<p *ngIf="rowData">
  <nz-table
    #rowSelectionTable
    nzShowPagination
    nzShowSizeChanger
    [nzData]="rowData"
    [nzScroll]="{ x: '2000px' }"
    nzPageSize="1000"
  >
    <thead>
      <tr>
        <th
          nzLeft
          nzCustomFilter
          nzCustomFilternzLeft
          nzWidth="200px"
          [(nzSortOrder)]="columnDefs[0].sortOrder"
          [nzSortDirections]="['ascend', 'descend']"
          [nzSortFn]="columnDefs[0].sortFn"
        >
          Product Serial
          <nz-filter-trigger [nzActive]="productSerialFilter.length > 0" [nzDropdownMenu]="menu">
            <i nz-icon nzType="search"></i>
          </nz-filter-trigger>
        </th>
        <th
          *ngFor="let header of columnDefs | slice: 1; trackBy: trackByName"
          [(nzSortOrder)]="header.sortOrder"
          [nzSortDirections]="['ascend', 'descend']"
          [nzSortFn]="header.sortFn"
          [nzWidth]="header.width || '200px'"
        >
          {{ header.preferred_name || header.name }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of rowSelectionTable.data">
        <td nzLeft>{{ data.product_serial }}</td>
        <td>{{ data.upc }}</td>
        <td>{{ data.sku }}</td>
        <td>{{ data.tracking_number }}</td>
        <td>{{ data.created_at | date: 'EEEE MMM d y, h:mm a' }}</td>
        <td>{{ data.check_in_time | date: 'EEEE MMM d y, h:mm a' }}</td>
        <td>{{ data.purchase_order_id }}</td>
        <!-- <td>{{ data.hso_check_number }}</td> -->
        <td>{{ data.product_name }}</td>
        <!-- <td>{{ data.product_description }}</td> -->
        <td>{{ data.price | currency }}</td>
      </tr>
    </tbody>
  </nz-table>
</p>

<nz-dropdown-menu #menu>
  <div class="ant-table-filter-dropdown">
    <div class="search-box">
      <input type="text" nz-input placeholder="Search name" [(ngModel)]="productSerialFilter" />
      <button nz-button nzSize="small" nzType="primary" (click)="searchFilter()" class="search-button">Search</button>
      <button nz-button nzSize="small" (click)="resetFilter()">Reset</button>
    </div>
  </div>
</nz-dropdown-menu>
