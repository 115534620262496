import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseUserPage } from '../user-page';

@Component({
  selector: 'app-accountant-base',
  template: `
    <nz-layout class="layout">
      <app-nav-bar [navBarMenu]="childPages"></app-nav-bar>
      <nz-content>
        <div class="inner-content">
          <router-outlet></router-outlet>
        </div>
      </nz-content>
      <nz-footer>HSO ©2020</nz-footer>
    </nz-layout>
  `,
  styles: [
    `
      .layout {
        min-height: 100vh;
      }

      nz-content {
        padding: 0 50px;
        margin-top: 20px;
      }

      .inner-content {
        padding: 24px;
        min-height: 380px;
      }

      nz-footer {
        text-align: center;
      }
    `
  ]
})
export class AccountantBaseComponent extends BaseUserPage implements OnInit {
  childPages: string[];

  constructor(activatedRoute: ActivatedRoute) {
    super(activatedRoute);
  }

  ngOnInit(): void {
    this.setChildPages();
  }
}
