import { Component, Injector, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-action-popover',
  // standalone: true,
  // imports: [],
  templateUrl: './action-popover.component.html',
  styleUrl: './action-popover.component.scss'
})
export abstract class ActionPopoverBaseComponent {
  @Input() popoverContent: TemplateRef<any>;

  isPopoverOpen = false;

  constructor(injector: Injector) {}

  showActionPopover() {
    this.isPopoverOpen = !this.isPopoverOpen;
  }

  onSelect(event) {
    // const buttonName = extractButtonName(event);
    // let direction;
    // if (buttonName.toUpperCase() === 'NAME') {
    // } else if (buttonName.toUpperCase() === 'AGE') {
    // }
  }

  onVisibleChange(event) {}
}

function extractButtonName(element: HTMLElement): string | null {
  // Find the element with class "am-popover-item-content" inside the given element
  const contentElement = element.querySelector('.am-popover-item-content');
  if (contentElement) {
    return contentElement.textContent.trim().toLocaleLowerCase();
  }
  return null;
}
