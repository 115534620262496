import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { AppPageComponent } from '../../../common/app-page/app-page.component';
import { ShadchanService } from '../services/shadchan.service';
import { SearchService } from '../../../services/search-service.service';
import { ActionSheetService, ModalService } from 'ng-zorro-antd-mobile';
import { ActivatedRoute } from '@angular/router';
import {
  MatchActivityAction,
  MatchPopulated
} from '../../../../../types/match';

@Component({
  selector: 'shdchn-match-page-mobile',
  templateUrl: './match-page-mobile.component.html',
  styleUrl: './match-page-mobile.component.scss'
})
export class MatchPageMobileComponent
  extends AppPageComponent<MatchPopulated>
  implements OnInit, OnDestroy
{
  activeKey = [0, 1, 2];
  isMatchLoaded = false;

  matchId: number;
  match: MatchPopulated;

  isShowingMatchActivityModal = false;
  isUpdatingMatchActivity = false;

  matchActivityOptions: string[] = Object.keys(MatchActivityAction).filter(
    (key) => isNaN(Number(key))
  );

  constructor(
    injector: Injector,
    private shadchanService: ShadchanService,
    private _actionSheet: ActionSheetService,
    private modalMobileService: ModalService,
    private activeRoute: ActivatedRoute
  ) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();

    this.matchId = this.activeRoute.snapshot.params.matchId;
    this.getFullMatch(this.matchId);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  deleteMatchActivity(matchActivityId: number) {
    this.isPageDataLoaded = false;
    this.shadchanService
      .deleteMatchActivity(this.matchId, matchActivityId)
      .subscribe((result) => {
        this.isPageDataLoaded = true;
        this.isEditMode = false;
        // Remove the match activity from the ui, without reloading
        this.match.match_activities = this.match.match_activities.filter(
          (m) => m.id !== matchActivityId
        );
      });
  }

  getFullMatch(matchId: number, refresh = false) {
    this.isPageDataLoaded = false;
    this.shadchanService.getFullMatch(matchId, refresh).subscribe((match) => {
      this.match = match;
      this.isPageDataLoaded = true;
    });
  }

  hideMatchActivityModal() {
    this.isShowingMatchActivityModal = false;
  }

  onAccordionChange(openPanels: string[]) {}

  selectMatchActivity(selectedActivity: keyof typeof MatchActivityAction) {
    this.isUpdatingMatchActivity = true;
    this.shadchanService
      .updateMatch(this.match.id, { match_action: selectedActivity })
      .subscribe((result) => {
        this.getFullMatch(this.matchId, true);
        this.isUpdatingMatchActivity = false;
        this.hideMatchActivityModal();
      });
  }

  showMatchActivityModal() {
    this.isShowingMatchActivityModal = true;
  }
}
