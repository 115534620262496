import { Component, Input } from '@angular/core';
import { ProfileSummary } from '../../../../types/profile';
import { MatchActivity, MatchPopulated } from '../../../../types/match';
import { Router } from '@angular/router';

@Component({
  selector: 'shdchn-match-card',
  templateUrl: './match-card.component.html',
  styleUrl: './match-card.component.scss'
})
export class MatchCardComponent {
  @Input() match: MatchPopulated;
  @Input() focusedProfile: ProfileSummary;
  @Input() matchedProfile: ProfileSummary;
  @Input() activities: MatchActivity[] = [];

  constructor(private router: Router) {}

  onMatchClick() {
    this.router.navigate(['/user/shadchan/match/' + this.match.id]);
  }
}
