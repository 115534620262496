<nz-table
  #rowSelectionTable
  nzShowPagination
  nzShowSizeChanger
  [nzData]="dataForDisplay"
  nzTableLayout="auto"
  [nzPageSize]="1000"
>
  <thead>
    <tr>
      <th
        *ngFor="let columnHeaderDef of columnDefs"
        [nzCustomFilter]="columnHeaderDef.headerName"
        [nzSortDirections]="['ascend', 'descend']"
        [nzSortFn]="columnHeaderDef.sortFn"
      >
        {{ columnHeaderDef.headerName }}
        <nz-filter-trigger
          [nzVisible]="searchValues[columnHeaderDef.headerName].visible"
          (nzVisibleChange)="searchValuesOpened($event, columnHeaderDef.headerName)"
          [nzActive]="searchValues[columnHeaderDef.headerName].value.length > 0"
          [nzDropdownMenu]="menu"
        >
          <i nz-icon nzType="search"></i>
        </nz-filter-trigger>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of rowSelectionTable.data">
      <!-- First column depends on payment status -->
      <td *ngIf="!data.accepted_at && !data.rejected_at">
        <button nz-button nzSize="small" nzType="primary" (click)="acceptPayment(data)">Accept</button>
        <button nz-button nzSize="small" (click)="rejectPayment(data)" style="margin-left: 0.5rem">Reject</button>
      </td>
      <td style="text-align: center" *ngIf="data.rejected_at"><i style="color: rgb(150, 0, 0)"> Rejected </i></td>
      <td
        *ngIf="data.accepted_at && !data.cheque_generated_at"
        [nzChecked]="isSelectedPayment(data)"
        (nzCheckedChange)="onPaymentSelected(data, $event)"
      ></td>
      <td style="text-align: center" *ngIf="data.cheque_generated_at">
        <b style="font-weight: 600; color: rgb(47, 123, 47)"> Paid </b>
      </td>

      <td>{{ data.who }}</td>
      <td>{{ data.amount | currency }}</td>
      <td>{{ data.requested_at | date: 'MM/d/y hh:mm' }}</td>
      <td>{{ data.cancelled_at | date: 'MM/d/y hh:mm' }}</td>
      <td>{{ data.cheque_generated_at | date: 'MM/d/y hh:mm' }}</td>
      <td>{{ data.request_notes }}</td>
    </tr>
  </tbody>
</nz-table>

<!-- TODO: Move to super class -->
<nz-dropdown-menu #menu="nzDropdownMenu">
  <div class="ant-table-filter-dropdown">
    <div class="search-box">
      <input type="text" nz-input placeholder="Search name" [(ngModel)]="searchValues[currentSearchColumn].value" />
      <button nz-button nzSize="small" nzType="primary" (click)="searchColumn()" class="search-button">Search</button>
      <button nz-button nzSize="small" (click)="resetSearchColumn()">Reset</button>
    </div>
  </div>
</nz-dropdown-menu>
