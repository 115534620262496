import { AdminTrackingNumberReport } from './../types-frontend/admin-types.d';
export const adminTrackingNumberReports: AdminTrackingNumberReport[] = [
  {
    tracking_number: 'Z100200301',
    buyer: '345',
    created_at: new Date().toLocaleDateString(),
    number_of_products: 5,
    purchase_order: 'po123',
    quickbooks_po_url: 'https://app.sandbox.qbo.intuit.com/app/purchaseorder?txnId=189',
    // product_name: 'Cool Product',
    // UPC: '123456',
    // SKU: 'A100',
    // serial: '000001',
    check_in_time: new Date().toLocaleDateString()
  }
];
