<ng-container>
  <form [formGroup]="profileForm">
    <List [renderHeader]="'Gender'">
      <div class="am-list-body">
        <RadioItemGroup formControlName="gender">
          <RadioItem
            name="male"
            [value]="'male'"
            [disabled]="profileForm.get('gender').disabled"
          >
            Man
          </RadioItem>

          <RadioItem
            name="female"
            [value]="'female'"
            [disabled]="profileForm.get('gender').disabled"
          >
            Woman
          </RadioItem>
        </RadioItemGroup>
      </div>
    </List>

    <List [renderHeader]="'Description'">
      <div class="am-list-body">
        <div class="am-list-body">
          <TextareaItem
            [rows]="5"
            [count]="1000"
            [defaultValue]="'a very nice person...'"
            formControlName="description"
          ></TextareaItem>
        </div>
      </div>
    </List>

    <List [renderHeader]="'Personal'">
      <div class="am-list-body">
        <InputItem
          [placeholder]="''"
          [compositionFilter]="false"
          [content]="'First Name'"
          formControlName="first_name"
        >
        </InputItem>
      </div>

      <div class="am-list-body">
        <InputItem
          [placeholder]="''"
          [compositionFilter]="false"
          [content]="'Last Name'"
          formControlName="last_name"
        >
        </InputItem>
      </div>

      <div class="am-list-body">
        <InputItem
          [placeholder]="''"
          [compositionFilter]="false"
          [content]="'Tehillim Name'"
          formControlName="tehillim_name"
        ></InputItem>
      </div>

      <div class="am-list-body">
        <List [className]="'date-picker-list'">
          <ListItem
            DatePicker
            [extra]="currentDateFormat(dobValue, 'yyyy-mm-dd')"
            [arrow]="'horizontal'"
            [mode]="'date'"
            [minDate]="minDate"
            [maxDate]="maxDate"
            [showErrorToast]="false"
            (onOk)="onDobOk($event)"
            formControlName="date_of_birth"
          >
            Date of Birth
            <Brief>Age: {{ dobValue | age }}</Brief>
          </ListItem>
        </List>
      </div>
    </List>

    <div class="am-list-body">
      <InputItem
        [placeholder]="''"
        [compositionFilter]="false"
        [content]="'City'"
        formControlName="city"
      >
      </InputItem>
    </div>

    <div class="am-list-body">
      <InputItem
        [compositionFilter]="false"
        [placeholder]="''"
        [content]="'Country'"
        formControlName="country"
      >
      </InputItem>
    </div>

    <List [renderHeader]="'Status'">
      <div class="am-list-body">
        <RadioItemGroup formControlName="marital_status">
          <RadioItem name="single" [value]="'single'"> Single </RadioItem>
          <RadioItem name="divorced" [value]="'divorced'"> Divorced </RadioItem>
          <RadioItem name="widowed" [value]="'widowed'"> Widowed </RadioItem>
        </RadioItemGroup>
      </div>
    </List>

    <List [renderHeader]="'Religious Category'">
      <ListItem
        Picker
        [extra]="profileForm.get('religious_identity').value"
        [arrow]="'horizontal'"
        [data]="religiousCategories"
        formControlName="religious_identity"
      >
        Religious Type {{ religiousCategoriesValue }}
      </ListItem>
    </List>

    <WhiteSpace></WhiteSpace>

    <List [renderHeader]="'Profile Images'">
      <!-- <ImagePicker
        [files]="imageFiles"
        [multiple]="'1'"
        [selectable]="imageFiles.length < 5"
        (onChange)="fileChange($event)"
        (onImageClick)="imageClick($event)"
      ></ImagePicker> -->

      <nz-upload
        [nzAction]="fileUploadUrl"
        nzFileType="image/png,image/bmp,image/jpeg,image/gif"
        nzListType="picture-card"
        [(nzFileList)]="fileList"
        [nzShowUploadList]="{
          showPreviewIcon: true,
          showRemoveIcon: true,
          showDownloadIcon: true
        }"
        [nzShowButton]="fileList.length < 4"
        [nzPreview]="handlePreview"
        (nzChange)="handleImageUploadResponse($event)"
      >
        <div>
          <span nz-icon nzType="plus"></span>
          <div style="margin-top: 8px">Upload</div>
        </div>
      </nz-upload>
    </List>

    <!-- TODO not behaving on mobile devices -->
    <!-- [disabled]="profileForm.invalid" -->
    <div Button [type]="'primary'" (onClick)="save()">Save</div>
  </form>
</ng-container>
