import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ValueFormatterParams } from 'ag-grid-community';
import { NzTableSortFn } from 'ng-zorro-antd/table';

export function currencyFormatter(params: ValueFormatterParams) {
  if (!params.value) {
    return '';
  }
  return '$' + params.value; //  params.value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

export function dateFormatter(params: ValueFormatterParams) {
  if (!params.value) {
    return '';
  }

  const generatedDate = new Date(params.value);
  return generatedDate.toLocaleDateString() + ' ' + generatedDate.toLocaleTimeString();
}

interface KeysAreStringsOrNumbers {
  [n: number]: any;
  [s: string]: any;
}

export function stringCompare<P extends KeysAreStringsOrNumbers>(propertyName: keyof P): NzTableSortFn {
  return (a: P, b: P) => {
    return (a[propertyName] as string).localeCompare(b[propertyName]);
  };
}

export function numberCompare<P extends KeysAreStringsOrNumbers>(propertyName: keyof P): NzTableSortFn {
  return (a: P, b: P) => a[propertyName] - b[propertyName];
}

export function dateCompare<P extends KeysAreStringsOrNumbers>(propertyName: keyof P): NzTableSortFn {
  return (a: P, b: P) => new Date(a[propertyName]).getTime() - new Date(b[propertyName]).getTime();
}

export const confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  if (!control.parent || !control) {
    return null;
  }

  const password = control.parent.get('password');
  const passwordConfirm = control.parent.get('confirmPassword');

  if (!password || !passwordConfirm) {
    return null;
  }

  if (passwordConfirm.value === '') {
    return null;
  }

  if (password.value === passwordConfirm.value) {
    return null;
  }

  return { passwordsNotMatching: true };
};

export function tableSortFn<RowItem>(fieldName: string) {
  return (a: RowItem, b: RowItem) => {
    if (typeof a[fieldName] === 'number') {
      return (a[fieldName] as number) - (b[fieldName] as number);
    } else if (typeof a[fieldName] === 'string') {
      return a[fieldName].toString().localeCompare(b[fieldName].toString());
    } else if (typeof a[fieldName] === 'boolean') {
      return a[fieldName] ? 1 : -1;
    } else {
      return 1;
      // throw new Error('Unexpected type for ' + a[fieldName] + ', ' + b[fieldName]);
    }
  };
}

export function getValuesFromForm<T>(form: FormGroup, formControlNames: (keyof T)[]) {
  const result = {} as T;

  formControlNames.forEach((formControlName) => {
    if (form.value[formControlName]) {
      result[formControlName] = form.value[formControlName];
    }
  });

  return result;
}
