import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { adminTrackingNumberReports } from './admin-tracking-numbers-report.mock';
import { buyerReportsMock } from './buyer-reports.mock';
import { availableDealsMock } from './deals.mock';
import { usersMock } from './users.mock';

@Injectable()
export class MockBackendInterceptor implements HttpInterceptor {
  constructor() {
    console.log('%cMock Backend is ACTIVE', 'color:green ;font-weight:bold;');
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const { url, method, headers, body } = request;

    console.log('URL', url);

    switch (url) {
      // BUYER
      case '/api/buyer/get-tracking-numbers-report':
        return of(new HttpResponse({ status: 200, body: buyerReportsMock }));
      case '/api/buyer/get-deals':
        return of(new HttpResponse({ status: 200, body: availableDealsMock }));

      // ADMIN
      case '/api/admin/users':
        return of(new HttpResponse({ status: 613, body: usersMock }));
      case '/api/admin/get-tracking-numbers-report':
        return of(new HttpResponse({ status: 200, body: adminTrackingNumberReports }));

      // AUTH
      case '/auth/sign-in':
      default:
    }

    return next.handle(request);
  }
}
