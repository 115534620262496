import { VERSION } from './version';

export const environment = {
  env_name: 'dev-live',
  version: VERSION,
  production: false,
  baseUrl: window.location.origin,
  apiUrl: '/api',
  rapidKey: '879478796dmshb0716494a79e213p1b1a9djsne935e53efc39'
};
