import { Response, Request } from "express";

export type UserType =
  | "admin"
  | "accountant"
  | "buyer"
  | "warehouse"
  | "customer"
  | "shadchan";

export type GenderType = "male" | "female";

export type DBEntityName =
  | "Accountant"
  | "ActiveDeal"
  | "Admin"
  | "PackedProduct"
  | "Package"
  | "PurchaseOrder"
  | "CustomerInvoice"
  | "Customer"
  | "PackagedProductsSku";

export type HttpStatus =
  | 200 // OK
  | 400 // access denied
  | 401 // access denied?
  | 404 // doesn't exist
  | 500 // server error
  | 503; // server error

export interface CustomRequest<T> extends Request {
  body: T;
}

export interface CustomHttpResponse<T = any>
  extends Response<HttpResponse<T>> {}

export interface HttpResponseBody<D> {
  status: HttpStatus;
  data: D;
}

export interface HttpResponse<D = any> {
  status?: HttpStatus;
  message?: string;
  data?: D;
}
