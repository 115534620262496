import { EventEmitter, Injectable } from "@angular/core";

export type SearchEventType =
  | "blur"
  | "cancel"
  | "change"
  | "clear"
  | "focus"
  | "submit";
export interface SearchEvent {
  type: SearchEventType;
  data?: any;
}

@Injectable({
  providedIn: "root",
})
export class SearchService {
  searchEvent = new EventEmitter<SearchEvent>();

  constructor() {}

  emitSearchEvent(searchEvent: SearchEvent) {
    this.searchEvent.emit(searchEvent);
  }
}
