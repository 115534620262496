<ng-template #tpl>
  <p><strong>Product Serial</strong> {{ inputs.productSerial }}</p>
  <form nz-form nzLayout="vertical">
    <form [formGroup]="setPriceForm">
      <nz-form-item nzBlock>
        <nz-form-label [nzSm]="8" [nzXs]="24" nzRequired>Product Price</nz-form-label>
        <nz-input-number
          formControlName="price"
          style="width: 100%"
          [nzMin]="1"
          [nzStep]="1"
          [nzFormatter]="formatterDollar"
          [nzParser]="parserDollar"
        ></nz-input-number>
      </nz-form-item>
    </form>
  </form>
</ng-template>
