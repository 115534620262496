import { Component, Injector, OnInit, ViewEncapsulation } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { ToastService } from 'ng-zorro-antd-mobile';
import { delay, finalize } from 'rxjs';
import { absAnimations } from '../../animations';
import { AuthService } from '../../services/auth.service';
import { ShadchanService } from '../../user-pages/shadchan/services/shadchan.service';
import { AuthPage } from '../auth-page';

@Component({
  selector: 'shdchn-verify-page',
  templateUrl: './verify-page.component.html',
  styleUrls: ['../auth-common.scss', './verify-page.component.scss'],
  providers: [{ provide: AuthPage, useExisting: VerifyPageComponent }],
  encapsulation: ViewEncapsulation.None,
  animations: absAnimations
})
export class VerifyPageComponent extends AuthPage implements OnInit {
  username = '';
  errorMsg = '';
  childPages: string[];
  verificationForm: FormGroup;

  constructor(
    protected injector: Injector,
    private shdchnService: ShadchanService,
    private formBuilder: FormBuilder
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.username = history.state.username;
    if (!this.username) {
      this.router.navigate(['../sign-in'], {
        relativeTo: this.router.routerState.root
      });
    }

    this.verificationForm = this.formBuilder.group({
      verification_code: new FormControl('', Validators.required)
    });
  }

  sendNewVerficationCode() {
    this.isLoading = true;
    this.authService
      .reverify(this.username)
      .pipe(
        delay(1000),
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe((r) => {
        this.toastSvc.success('New Verification Code Sent');
      });
  }

  submit() {
    this.authService
      .verify(this.username, this.verificationForm.value.verification_code)
      .subscribe((result) => {
        if (!!result) {
          this.toastSvc.success('Your account has been activated');
          this.router.navigate(['../sign-in'], {
            relativeTo: this.router.routerState.root
          });
        } else {
          this.toastSvc.fail('Verification Code is not correct');
        }
      });
  }
}
