import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ModalComponent } from '../../../../common/modal-common/modal.component';
import { ProductCategoryService } from '../../services/product-category.service';

@Component({
  selector: 'app-create-new-product-category-modal',
  templateUrl: './create-new-product-category-modal.component.html',
  styleUrls: ['./create-new-product-category-modal.component.scss']
})
export class CreateNewProductCategoryModalComponent extends ModalComponent implements OnInit, AfterViewInit {
  title = 'Create New Product Category';
  productList = [];
  createNewProductCategoryForm: FormGroup = new FormGroup({});
  isLoadingCategories = false;

  @ViewChild('tpl') public content: TemplateRef<any>;
  @ViewChild('footer') public footerTemplateref: TemplateRef<any>;
  formatterDollar = (value: number) => `$ ${value}`;
  parserDollar = (value: string) => value.replace('$ ', '');

  constructor(
    protected nzMessageService: NzMessageService,
    private categoryService: ProductCategoryService,
    nzModal: NzModalService
  ) {
    super(nzModal);
  }

  ngAfterViewInit(): void {
    this.openModal({}, () => {
      this.createNewProductCategory().subscribe((result) => {
        this.nzMessageService.success(result.message);
        this.modal.close();
      });
    });
  }

  ngOnInit(): void {
    this.createNewProductCategoryForm = new FormGroup({
      productCategoryName: new FormControl()
    });
  }

  onSearch(event: any) {}

  createNewProductCategory() {
    return this.categoryService.createProductCategory(
      this.createNewProductCategoryForm.get('productCategoryName').value
    );
  }
}
