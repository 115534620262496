import { Component, Injector, OnInit, ViewEncapsulation } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { ToastService } from 'ng-zorro-antd-mobile';
import { catchError, finalize, throwError } from 'rxjs';
import { absAnimations } from '../../animations';
import { AuthService } from '../../services/auth.service';
import { ShadchanService } from '../../user-pages/shadchan/services/shadchan.service';
import { AuthPage } from '../auth-page';

@Component({
  selector: 'shdchn-register-page',
  templateUrl: './register-page.component.html',
  styleUrls: ['../auth-common.scss', './register-page.component.scss'],
  providers: [{ provide: AuthPage, useExisting: RegisterPageComponent }],
  encapsulation: ViewEncapsulation.None,
  animations: absAnimations
})
export class RegisterPageComponent extends AuthPage implements OnInit {
  childPages: string[];
  registrationForm: FormGroup;

  constructor(
    protected injector: Injector,
    private shdchnService: ShadchanService,
    private formBuilder: FormBuilder
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.registrationForm = this.formBuilder.group({
      gender: new FormControl('', Validators.required),
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: ['', Validators.required],
      phone: [''],
      username: [''],
      password: ['']
    });
  }

  // TEMPORARY override
  isMobileScreen(): boolean {
    return false;
  }

  submit() {
    this.isLoading = true;
    this.authService
      .register(this.registrationForm.value)
      .pipe(
        catchError((response) => {
          // this.toastSvc.fail(response.message);
          return throwError(response.message);
        }),
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe((result) => {
        this.isLoading = false;
        this.router.navigate(['../verify'], {
          relativeTo: this.router.routerState.root,
          state: { username: result.data.username }
        });
      });
  }
}
