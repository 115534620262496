import { Injectable } from '@angular/core';
import { AbstractApiService } from '../../../services/abstract-api.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { filter, map, of, tap } from 'rxjs';
import { Profile, ProfileFull } from '../../../../../types/profile';
import { formatProfile } from '../../../utils/shadchan.utils';
import { MatchPopulated } from '../../../../../types/match';
import { CACHE_TIME, CacheUtils } from '../../../utils/cache.utils';
import { AuthService } from '../../../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ShadchanService extends AbstractApiService {
  shadchanApiUrl = environment.baseUrl + environment.apiUrl + '/shadchan';

  private cacheProfile: CacheUtils<ProfileFull>;
  private cacheMatch: CacheUtils<MatchPopulated>;
  profilesCached: Profile[] = [];

  constructor(private authService: AuthService, private http: HttpClient) {
    super();

    const userLogsOut$ = this.authService
      .getUserState$()
      .pipe(filter((result) => result === null));

    this.cacheProfile = new CacheUtils<ProfileFull>(CACHE_TIME, userLogsOut$);
    this.cacheMatch = new CacheUtils<MatchPopulated>(CACHE_TIME, userLogsOut$);

    userLogsOut$.subscribe(() => {
      this.profilesCached = [];
    });
  }

  getProfiles(refresh = false) {
    if (refresh || this.profilesCached.length === 0) {
      return this.http.get(this.shadchanApiUrl + '/profiles').pipe(
        map((i: { data: Profile[] }) => i.data as Profile[]),
        tap((data) => (this.profilesCached = data))
      );
    } else {
      return of(this.profilesCached);
    }
  }

  getFullMatch(id: number, refresh = false) {
    if (!refresh) {
      const cacheMatch = this.cacheMatch.getFromCache(id);
      if (cacheMatch) {
        return of(cacheMatch);
      }
    }

    return this.http.get(this.shadchanApiUrl + `/matches/${id}`).pipe(
      map((i: { data: MatchPopulated }) => i.data as MatchPopulated),
      tap((match) => this.cacheMatch.setInCache(id, match))
    );
  }

  getFullProfile(id: number, refresh = false) {
    if (!refresh) {
      const cachedProfile = this.cacheProfile.getFromCache(id);
      if (cachedProfile) {
        return of(cachedProfile);
      }
    }

    return this.http.get(this.shadchanApiUrl + `/profile/${id}`).pipe(
      map((i: { data: ProfileFull }) => i.data as ProfileFull),
      tap((profile) => this.cacheProfile.setInCache(id, profile))
    );
  }

  advancedSearchProfiles() {}

  generalSearch(searchTerm: string) {
    return this.http
      .get(this.shadchanApiUrl + `/profile-search?searchTerm=${searchTerm}`)
      .pipe(map((i: { data: Profile[] }) => i.data as Profile[]));
  }

  createNewMatch(
    maleProfileId: number,
    femaleProfileId: number,
    description: string = 'Redt a Shidduch'
  ) {
    const payload = {
      male_profile_id: maleProfileId,
      female_profile_id: femaleProfileId,
      description: description
    };

    return this.http.put(this.shadchanApiUrl + `/matches`, payload);
  }

  createNewProfile(data: any) {
    return this.http
      .put(this.shadchanApiUrl + '/profile', data)
      .pipe(map((i: { message: string }) => i.message));
  }

  deleteMatchActivity(matchId: number, matchActivityId: number) {
    return this.http
      .delete(this.shadchanApiUrl + `/matches/${matchId}/${matchActivityId}`)
      .pipe(map((i: { message: string }) => i.message));
  }

  updateMatch(id: number, data: any) {
    return this.http
      .post(this.shadchanApiUrl + `/matches/${id}`, data)
      .pipe(map((i: { message: string }) => i.message));
  }

  updateProfile(id: number, data: any) {
    return this.http.post(this.shadchanApiUrl + `/profile/${id}`, data).pipe(
      map((i: { message: string }) => i.message),
      tap((i) => {
        this.cacheProfile.deleteFromCache(id);
      })
    );
  }

  generateProfileAsText(profile: Profile) {
    return formatProfile(profile);
  }
}
