import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NzUploadChangeParam, NzUploadFile } from 'ng-zorro-antd/upload';
import { ShadchanService } from '../services/shadchan.service';
import { environment } from '../../../../environments/environment';
import { ShadchanNlpService } from '../services/shdchn-nlp.service';
import { Profile } from '../../../../../types/profile';
import { ToastService } from 'ng-zorro-antd-mobile';

@Component({
  selector: 'shchn-new-profile-mobile',
  templateUrl: './new-profile-mobile.component.html',
  styleUrl: './new-profile-mobile.component.scss'
})
export class NewProfileMobileComponent implements OnInit {
  isLoading = false;
  entryModeIndex = 0;
  entryMode: 'form' | 'freetext' = 'form';
  createNewProfileForm: FormGroup;
  religiousCategories: string[] = [
    'Yeshivishe',
    'Modern',
    'Modern-Machmir',
    'Chasidishe'
  ]; // Add more religious categories as needed

  maritalStatus: string[] = ['single', 'widowed', 'divorced'];

  fileUploadUrl: string =
    environment.baseUrl + environment.apiUrl + '/shadchan/image-upload';
  fileList: NzUploadFile[] = [];
  previewImage: string | undefined = '';
  previewVisible = false;
  filePublicIds: string[] = [];

  isSubmittingNewProfileForm = false;

  selectedReligiousCategories = [...this.religiousCategories];

  freetext = '';
  profileData = null;

  constructor(
    private shdchnNlpService: ShadchanNlpService,
    private apiService: ShadchanService,
    private toastService: ToastService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.createNewProfileForm = this.formBuilder.group({
      gender: ['', Validators.required],
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      date_of_birth: [''],
      city: [''],
      country: [''],
      occupation: [''],
      is_college_educated: ['false'],
      description: [''],
      marital_status: ['']
    });
  }

  handlePreview = async (file: NzUploadFile): Promise<void> => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj!);
    }
    this.previewImage = file.url || file.preview;
    this.previewVisible = true;
  };

  onChangeEntryMode(event: { selectedIndex: number; value: string }) {
    if (event.value === 'By Form') {
      this.entryMode = 'form';
    } else if (event.value === 'By Text') {
      this.entryMode = 'freetext';
    }
  }

  populateCreateProfileForm(profileData: Partial<Profile>) {
    Object.keys(profileData).forEach((key) => {
      this.createNewProfileForm.get(key).setValue('pooooo');
      if (this.createNewProfileForm.controls[key]) {
        this.createNewProfileForm.get(key).setValue('yoooloooo');
      }
    });
  }

  submitProfileForm() {
    // Handle form submission here
    // Combine with file list
    const formData = {
      ...this.createNewProfileForm.value,
      is_college_educated:
        this.createNewProfileForm.value['is_college_educated'].toString(),
      religious_categories: this.selectedReligiousCategories,
      photos: this.filePublicIds
    };

    this.apiService.createNewProfile(formData).subscribe(
      () => {
        this.toastService.info(`Profile Created`);
        // TODO move this functionality (forcing refresh) to profiles.component.
        this.apiService.getProfiles(true).subscribe();
        this.router.navigate(['../home'], { relativeTo: this.activatedRoute });
      },
      (error: Error) => {
        console.error('Unable to create new profile');
        console.error(error);
      }
    );
  }

  submitTextProfile() {
    this.isLoading = true;
    console.log('Free TEXT:', this.freetext);
    this.shdchnNlpService.submitRawText(this.freetext).subscribe((result) => {
      console.log('result', result);
      // this.populateCreateProfileForm(result);
      this.profileData = result;
      this.entryMode = 'form';
      this.entryModeIndex = 0;
      this.isLoading = false;
    });
  }

  updateReligiousCategories(category: string, isChecked: boolean) {
    if (isChecked) {
      if (!this.selectedReligiousCategories.includes(category)) {
        this.selectedReligiousCategories.push(category);
      }
    } else {
      if (this.selectedReligiousCategories.includes(category)) {
        const index = this.selectedReligiousCategories.indexOf(category);
        if (index !== -1) {
          this.selectedReligiousCategories.splice(index, 1);
        }
      }
    }
  }

  handleImageUploadResponse(event: NzUploadChangeParam) {
    if (event.file.status === 'done') {
      const uploadResponse = event.file.response;
      const data = uploadResponse.data;
      for (const item of data) {
        this.filePublicIds.push(item);
      }
    }
  }
}

const getBase64 = (file: File): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
