import { NgModule } from '@angular/core';
import { OkaModalService } from '../../../../common/modal-common/oka-modals.service';
import { TurnOfflineModeOffModalComponent } from './turn-offline-mode-off-modal.component';

@NgModule({
  imports: [],
  declarations: [TurnOfflineModeOffModalComponent],
  exports: [TurnOfflineModeOffModalComponent],
})
export class TurnOfflineModeOffModalModule {
  constructor(private modalNavigationService: OkaModalService) {}
}
