<nz-row [nzGutter]="{ md: 24 }">
  <nz-col nzSm="24" nzMd="6">
    <nz-card>
      <nz-row>
        <nz-input-group [nzSuffix]="suffixIcon">
          <input
            placeholder="input here"
            nz-input
            [(ngModel)]="inputValue"
            (input)="onInput($event)"
            [nzAutocomplete]="auto"
          />
          <nz-autocomplete [nzDataSource]="options" nzBackfill #auto></nz-autocomplete>
        </nz-input-group>
      </nz-row>
    </nz-card>

    <nz-divider></nz-divider>
    <nz-list class="demo-loadmore-list" [nzLoading]="initLoading">
      <ng-container *ngFor="let customQuery of customQueries">
        <nz-list-item (click)="runQuery(customQuery)" [ngClass]="{ active: customQuery.id === activeCustomQuery.id }">
          <nz-list-item-meta>
            <nz-list-item-meta-avatar> <i nz-icon nzType="table" nzTheme="outline"></i></nz-list-item-meta-avatar>
            <nz-list-item-meta-title>
              {{ customQuery.name }}
            </nz-list-item-meta-title>
            <nz-list-item-meta-description>
              {{ customQuery.description }}
            </nz-list-item-meta-description>
          </nz-list-item-meta>
          <ul nz-list-item-actions>
            <nz-list-item-action><a (click)="edit(item)">Actions</a></nz-list-item-action>
          </ul>
        </nz-list-item>
      </ng-container>

      <div class="loadmore" nz-list-load-more>
        <button nz-button *ngIf="!loadingMore" (click)="onLoadMore()">loading more</button>
      </div>
    </nz-list>
  </nz-col>

  <!-- RESULTS -->
  <nz-col nzSm="24" nzMd="18">
    <nz-card [nzTitle]="!!activeCustomQuery ? activeCustomQuery.name : '(Select a query)'" [nzExtra]="runQueryButton">
      <nz-spin *ngIf="showSpinner" nzSimple></nz-spin>
      <oka-custom-query-results-table
        *ngIf="(queryResults | async).length"
        [data$]="queryResults"
        [columnHeaders]="queryResultsColumnHeaders"
      ></oka-custom-query-results-table>
    </nz-card>
  </nz-col>
</nz-row>

<ng-template #suffixIcon>
  <i nz-icon nzType="search"></i>
</ng-template>

<ng-template #runQueryButton>
  <button nz-button [nzSize]="size" nzShape="circle" (click)="runQuery()">
    <i nz-icon nzType="play-circle"></i>
  </button>
</ng-template>
