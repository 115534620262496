import { CommonModule, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import en from '@angular/common/locales/en';
import { NgModule, Provider } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IconDefinition } from '@ant-design/icons-angular';
import {
  LockOutline,
  LockTwoTone,
  LoginOutline,
  LogoutOutline,
  UserOutline
} from '@ant-design/icons-angular/icons';
import { HotTableModule } from '@handsontable/angular';
import { AgGridModule } from 'ag-grid-angular';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { environment } from '../environments/environment';
import { MockBackendInterceptor } from '../mock-backend/mock-backend.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SignInPageComponent } from './auth/sign-in-page/sign-in-page.component';
import { CacheService } from './common/cache.service';
import { DealItemComponent } from './common/deal-item/deal-item.component';
import { NavBarComponent } from './common/nav-bar/nav-bar.component';
import {
  NgzorroCommonModule,
  ngZorroIcons
} from './common/ngzorro-common.module';
import { OkaCommonModule } from './common/oka-common.module';
import { DemoPageComponent } from './demo-page/demo-page.component';
import { AuthInterceptor } from './services/auth-interceptor.service';
import { HttpErrorInterceptor } from './services/http-error-interceptor.service';
import { JWTInterceptor } from './services/jwt-interceptor.service';
import { OkaTablesModule } from './tables/oka-tables.module';
import { AccountantBaseComponent } from './user-pages/accountant/accountant-base.component';
import { AccountantModule } from './user-pages/accountant/accountant.module';
import { AccountingHomepageComponent } from './user-pages/accountant/accounting-home-page/accounting-homepage.component';
import { SetPriceModalComponent } from './user-pages/accountant/modals/set-price-modal/set-price-modal.component';
import { PurchaseOrderDrawerComponent } from './user-pages/accountant/purchase-order-drawer/purchase-order-drawer.component';
import { AdminBaseComponent } from './user-pages/admin/admin-base.component';
import { AdminHomePageComponent } from './user-pages/admin/admin-home-page/admin-home-page.component';
import { AdminModule } from './user-pages/admin/admin.module';
import { CreateOrEditActiveDealModalModule } from './user-pages/admin/modals/create-new-active-deal-modal/create-or-edit-active-deal-modal.module';
import { CreateNewProductCategoryModalModule } from './user-pages/admin/modals/create-new-product-category-modal/create-new-product-category-modal.module';
import { CreateNewProductModalModule } from './user-pages/admin/modals/create-new-product-modal/create-new-product-modal.module';
import { CreateNewUserModalModule } from './user-pages/admin/modals/create-new-user-modal/create-new-user-modal.module';
import { GenerateChequeModalModule } from './user-pages/admin/modals/generate-cheque-modal/generate-cheque-modal.module';
import { UpdateUserModalModule } from './user-pages/admin/modals/update-user-modal/update-user-modal.module';
import { ProductsPageComponent } from './user-pages/admin/products-page/products-page.component';
import { CustomQueryPageComponent } from './user-pages/admin/reports-page/custom-query-page/custom-query-page.component';
import { CustomQueryResultsTableComponent } from './user-pages/admin/reports-page/custom-query-page/custom-query-results-table/custom-query-results-table.component';
import { UserManagementPageComponent } from './user-pages/admin/user-management-page/user-management-page.component';
import { BuyerBaseComponent } from './user-pages/buyer/buyer-base.component';
import { BuyerDealsPageComponent } from './user-pages/buyer/buyer-deals-page/buyer-deals-page.component';
import { BuyerHomepageComponent } from './user-pages/buyer/buyer-home-page/buyer-homepage.component';
import { BuyerReportsPageComponent } from './user-pages/buyer/buyer-reports-page/buyer-reports-page.component';
import { BuyerModule } from './user-pages/buyer/buyer.module';
import { NonBuyerSharedModule } from './user-pages/non-buyer-shared/non-buyer-shared.module';
import { UserPagesComponent } from './user-pages/user-pages.component';
import { TurnOfflineModeOffModalModule } from './user-pages/warehouse/modals/turn-offline-mode-off-modal/turn-offline-mode-off-modal.module';
import { TurnOfflineModeOnModalModule } from './user-pages/warehouse/modals/turn-offline-mode-on-modal/turn-offline-mode-on-modal.module';
import { RecentScannedPackagesComponent } from './user-pages/warehouse/recent-scanned-packages/recent-scanned-packages.component';
import { ScanPackagesComponent } from './user-pages/warehouse/scan-packages/scan-packages.component';
import { ScannedUpcSerialComponent } from './user-pages/warehouse/scanned-upc-serial/scanned-upc-serial.component';
import { WarehouseBaseComponent } from './user-pages/warehouse/warehouse-base.component';
import { WarehouseHomepageComponent } from './user-pages/warehouse/warehouse-homepage/warehouse-homepage.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { ProfilesPageComponent } from './user-pages/shadchan/profiles-page/profiles-page.component';
import { ProfileCardComponent } from './common/profile-card/profile-card.component';
import { ProfilePageComponent } from './user-pages/shadchan/profile-page/profile-page.component';
import { NewProfileComponent } from './user-pages/shadchan/new-profile/new-profile.component';

import {
  LOCAL_PROVIDER_TOKEN,
  en_US as en_US_mobile,
  NgZorroAntdMobileModule
} from 'ng-zorro-antd-mobile';
import { ShadchanBaseMobileComponent } from './user-pages/shadchan/shadchan-base-mobile/shadchan-base-mobile.component';
import { NavBarMobileComponent } from './common/nav-bar-mobile/nav-bar-mobile.component';
import { ProfileCardMobileComponent } from './common/profile-card-mobile/profile-card-mobile.component';
import { ProfilesPageMobileComponent } from './user-pages/shadchan/profiles-page-mobile/profiles-page-mobile.component';
import { SearchBarMobileComponent } from './common/search-bar-mobile/search-bar-mobile.component';
import { AgePipe } from './common/age.pipe';
import { NewProfileMobileComponent } from './user-pages/shadchan/new-profile-mobile/new-profile-mobile.component';
import { ProfilePageMobileComponent } from './user-pages/shadchan/profile-page-mobile/profile-page-mobile.component';
import { JsonPropertyPipe } from './common/json-property.pipe';
import { ProfileFormMobileComponent } from './common/profile-form-mobile/profile-form-mobile.component';
import { SortPopoverComponent } from './common/sort-popover/sort-popover.component';
import { TehillimPageComponent } from './user-pages/shadchan/tehillim-page/tehillim-page.component';
import { GenderToggleMobileComponent } from './common/gender-toggle-mobile/gender-toggle-mobile.component';
import { ProfilePagePopoverComponent } from './common/action-popovers/profile-page-action-popover/profile-page-action-popover.component';
import { MatchCardComponent } from './common/match-card/match-card.component';
import { MatchPageMobileComponent } from './user-pages/shadchan/match-page-mobile/match-page-mobile.component';
import { MatchCardThumbComponent } from './common/match-card-thumb/match-card-thumb.component';
import { MatchPageActionPopoverComponent } from './common/action-popovers/match-page-action-popover/match-page-action-popover.component';
import { RegisterPageComponent } from './auth/register-page/register-page.component';
import { VerifyPageComponent } from './auth/verify-page/verify-page.component';
import { ForgotPasswordPageComponent } from './auth/forgot-password-page/forgot-password-page.component';
import { AuthCommonButtonComponent } from './auth/auth-common/auth-button/auth-button.component';
import { AuthCommonTemplateShellComponent } from './auth/auth-common/auth-template-shell/auth-template-shell.component';
import { ResetPasswordPageComponent } from './auth/reset-password-page/reset-password-page.component';

const icons: IconDefinition[] = [
  LockTwoTone,
  UserOutline,
  LoginOutline,
  LockOutline,
  LogoutOutline
];

registerLocaleData(en);

const PROVIDERS: Provider[] = [
  CacheService,
  { provide: NZ_I18N, useValue: en_US },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpErrorInterceptor,
    multi: true
  },
  { provide: LOCAL_PROVIDER_TOKEN, useValue: en_US_mobile }
];

if (environment.env_name === 'frontend-dev') {
  PROVIDERS.push({
    // use fake backend in place of Http service for backend-less development
    provide: HTTP_INTERCEPTORS,
    useClass: MockBackendInterceptor,
    multi: true
  });
} else {
  PROVIDERS.push({
    provide: HTTP_INTERCEPTORS,
    useClass: JWTInterceptor,
    multi: true
  });
}

@NgModule({
  declarations: [
    AppComponent,
    WelcomeComponent,
    AccountingHomepageComponent,
    BuyerHomepageComponent,
    WarehouseHomepageComponent,
    AdminHomePageComponent,
    UserPagesComponent,
    DemoPageComponent,
    NavBarComponent,
    NavBarMobileComponent,
    UserManagementPageComponent,
    AdminBaseComponent,
    BuyerBaseComponent,
    CustomQueryPageComponent,
    BuyerReportsPageComponent,
    AccountantBaseComponent,
    BuyerDealsPageComponent,
    DealItemComponent,
    PurchaseOrderDrawerComponent,
    SearchBarMobileComponent,
    ScanPackagesComponent,
    WarehouseBaseComponent,
    ScannedUpcSerialComponent,
    RecentScannedPackagesComponent,
    ProductsPageComponent,
    SetPriceModalComponent,
    CustomQueryResultsTableComponent,

    //Auth
    // TODO organize into Auth module
    AuthCommonButtonComponent,
    AuthCommonTemplateShellComponent,
    RegisterPageComponent,
    ResetPasswordPageComponent,
    SignInPageComponent,
    VerifyPageComponent,
    ForgotPasswordPageComponent,

    // Common
    MatchPageActionPopoverComponent,
    ProfilePagePopoverComponent,
    SortPopoverComponent,

    // Pipes
    AgePipe,
    JsonPropertyPipe,

    // Shadchan
    GenderToggleMobileComponent,
    MatchCardComponent,
    MatchCardThumbComponent,
    MatchPageMobileComponent,
    NewProfileComponent,
    NewProfileMobileComponent,
    ProfileCardComponent,
    ProfileCardMobileComponent,
    ProfileFormMobileComponent,
    ProfilesPageComponent,
    ProfilesPageMobileComponent,
    ProfilePageComponent,
    ProfilePageMobileComponent,
    ShadchanBaseMobileComponent,
    TehillimPageComponent
  ],
  imports: [
    AccountantModule,
    AdminModule,
    AgGridModule,
    AppRoutingModule,
    BrowserModule.withServerTransition({ appId: 'oka-main-app' }),
    BuyerModule,
    CommonModule,
    FormsModule,
    HotTableModule,
    HttpClientModule,
    NgZorroAntdMobileModule,
    OkaCommonModule,
    ReactiveFormsModule,
    // ServiceWorkerModule.register('/../ngsw-worker.js', { enabled: environment.production }),

    /* MODALS */
    CreateOrEditActiveDealModalModule,
    CreateNewProductModalModule,
    CreateNewProductCategoryModalModule,
    CreateNewUserModalModule,
    UpdateUserModalModule,
    GenerateChequeModalModule,
    TurnOfflineModeOffModalModule,
    TurnOfflineModeOnModalModule,
    BrowserAnimationsModule,

    // UI/UX Modules
    BrowserAnimationsModule,

    NzIconModule.forRoot(ngZorroIcons),
    NgzorroCommonModule,

    // Oka Modules
    OkaTablesModule,
    NonBuyerSharedModule
  ],
  providers: PROVIDERS,
  bootstrap: [AppComponent]
})
export class AppModule {}
