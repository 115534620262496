<ng-template #tpl>
  <form nz-form nzLayout="vertical" [formGroup]="createNewUserForm">
    <nz-form-item nzBlock>
      <nz-select nzPlaceHolder="Select User Type" nzAllowClear formControlName="userType">
        <ng-container *ngFor="let u of userTypes">
          <nz-option [nzValue]="u" [nzLabel]="u"></nz-option>
        </ng-container>
      </nz-select>
    </nz-form-item>

    <nz-form-item nzBlock>
      <nz-select nzPlaceHolder="Select Gender" nzAllowClear formControlName="gender">
        <ng-container *ngFor="let g of genderTypes">
          <nz-option [nzValue]="g" [nzLabel]="g"></nz-option>
        </ng-container>
      </nz-select>
    </nz-form-item>

    <nz-form-item nzBlock>
      <nz-form-label [nzSm]="8" [nzXs]="24" nzRequired>First Name</nz-form-label>
      <input nz-input placeholder="First Name" formControlName="firstName" />
    </nz-form-item>

    <nz-form-item nzBlock>
      <nz-form-label [nzSm]="8" [nzXs]="24" nzRequired>Last Name</nz-form-label>
      <input nz-input placeholder="Last Name" formControlName="lastName" />
    </nz-form-item>

    <nz-form-item nzBlock>
      <nz-form-label [nzSm]="8" [nzXs]="24">Email</nz-form-label>
      <input nz-input placeholder="Email" formControlName="email" />
    </nz-form-item>

    <nz-form-item nzBlock>
      <nz-form-label [nzSm]="8" [nzXs]="24">Phone Number</nz-form-label>
      <input nz-input placeholder="Phone Number" formControlName="phoneNumber" />
    </nz-form-item>

    <nz-form-item nzBlock>
      <nz-form-label [nzSm]="8" [nzXs]="24" nzRequired>Username</nz-form-label>
      <input nz-input placeholder="Username" formControlName="username" />
    </nz-form-item>

    <nz-form-item nzBlock>
      <nz-form-label [nzSm]="8" [nzXs]="24" nzRequired>Password</nz-form-label>
      <input nz-input type="password" placeholder="Password" formControlName="password" />
    </nz-form-item>

    <nz-form-item nzBlock>
      <nz-form-label [nzSm]="8" [nzXs]="24" nzRequired>Confirm Password</nz-form-label>
      <input nz-input type="password" placeholder="Confirm Password" formControlName="confirmPassword" />
    </nz-form-item>

  </form>
</ng-template>
