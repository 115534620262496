<nz-layout>
  <nz-content>
    <nz-row nzJustify="space-between">
      <nz-col><h2 class="mb-0">Purchase Orders</h2></nz-col>
      <nz-col>
        <!-- <button nz-button nzType="primary" (click)="exportAsCSV()">Export as CSV</button> -->
      </nz-col>
    </nz-row>

    <nz-spin *ngIf="showSpinner" nzSimple></nz-spin>

    <oka-purchase-orders-tables
      [data$]="purchaseOrdersData"
      [columnHeaders]="columnHeaders"
    ></oka-purchase-orders-tables>
  </nz-content>
</nz-layout>
