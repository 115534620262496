import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseDetailsPageComponent } from './base-details-page/base-details-page.component';
import { BaseDetailsCoreInfoComponent } from './base-details-core-info/base-details-core-info.component';
import { NgzorroCommonModule } from '../../../common/ngzorro-common.module';
import { ReactiveFormsModule } from '@angular/forms';
import { CamelCase2TitleCasePipe } from '../../../common/camel-to-title.pipe';
import { BaseDetailsQuickbooksSyncComponent } from './base-details-quickbooks-sync/base-details-quickbooks-sync.component';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';

@NgModule({
  declarations: [
    BaseDetailsPageComponent,
    BaseDetailsCoreInfoComponent,
    CamelCase2TitleCasePipe,
    BaseDetailsQuickbooksSyncComponent
  ],
  imports: [CommonModule, NgzorroCommonModule, NzDatePickerModule, ReactiveFormsModule],
  exports: [
    BaseDetailsPageComponent,
    BaseDetailsCoreInfoComponent,
    BaseDetailsQuickbooksSyncComponent,
    CamelCase2TitleCasePipe
  ]
})
export class BaseDetailsModule {}
