<ng-template #tpl>
  <form nz-form nzLayout="vertical">
    <form [formGroup]="createNewProfileForm">
      <nz-form-item *ngIf="inputs.mode === 'edit'" nzBlock>
        <nz-form-label [nzSm]="8" [nzXs]="24">Product ID</nz-form-label>
        <input
          nz-input
          disabled
          style="width: 100%"
          formControlName="productId"
        />
      </nz-form-item>

      <nz-form-item *ngIf="inputs.mode === 'new'" nzBlock>
        <nz-select
          nzPlaceHolder="Select Product"
          nzAllowClear
          nzShowSearch
          nzServerSearch
          (nzOnSearch)="onSearch($event)"
          formControlName="productId"
          nzRequired
        >
          <ng-container *ngFor="let o of matchingProductListItems">
            <nz-option
              *ngIf="!isLoading"
              [nzValue]="o.product_id"
              [nzLabel]="o.sku + ' / ' + o.product_description"
            ></nz-option>
          </ng-container>
          <nz-option *ngIf="isLoading" nzDisabled nzCustomContent>
            <i nz-icon nzType="loading" class="loading-icon"></i> Loading
            Data...
          </nz-option>
        </nz-select>
      </nz-form-item>

      <nz-form-item nzBlock>
        <nz-form-label [nzSm]="8" [nzXs]="24" nzRequired
          >Max Quantity</nz-form-label
        >
        <nz-input-number
          style="width: 100%"
          [nzMin]="1"
          [nzStep]="1"
          formControlName="maxQuantity"
        ></nz-input-number>
      </nz-form-item>

      <nz-form-item nzBlock>
        <nz-form-label [nzSm]="8" [nzXs]="24" nzRequired
          >Offer Price</nz-form-label
        >
        <nz-input-number
          formControlName="offerPrice"
          style="width: 100%"
          [nzMin]="1"
          [nzStep]="0.01"
          [nzFormatter]="formatterDollar"
          [nzParser]="parserDollar"
        ></nz-input-number>
      </nz-form-item>

      <nz-form-label [nzSm]="8" [nzXs]="24" nzRequired>Sources</nz-form-label>

      <ng-container formArrayName="dealSources">
        <nz-row
          [formGroupName]="i"
          *ngFor="let dealSourceForm of dealSources.controls; let i = index"
          [nzGutter]="[8, 2]"
          class="deal-sources-row"
        >
          <ng-container [formGroup]="dealSourceForm">
            <nz-col nzSpan="8">
              <nz-form-item>
                <nz-select
                  nzPlaceHolder="Select Deal Source"
                  nzAllowClear
                  nzShowSearch
                  nzServerSearch
                  (nzOnSearch)="onSearchForDealSource($event)"
                  formControlName="source_name"
                  nzRequired
                >
                  <ng-container *ngFor="let o of matchingDealSourceListItems">
                    <nz-option [nzValue]="o" [nzLabel]="o"></nz-option>
                  </ng-container>
                  <nz-option *ngIf="isLoading" nzDisabled nzCustomContent>
                    <i nz-icon nzType="loading" class="loading-icon"></i>
                    Loading Data...
                  </nz-option>
                </nz-select>
              </nz-form-item>
            </nz-col>
            <nz-col nzFlex="auto">
              <nz-form-item>
                <input
                  formControlName="source_link"
                  nz-input
                  placeholder="Source URL"
                  required
                />
              </nz-form-item>
            </nz-col>
            <nz-col>
              <button
                nz-button
                nzSize="small"
                nzShape="circle"
                (click)="addDealSource()"
                *ngIf="i === dealSources.controls.length - 1"
              >
                <i nz-icon nzType="plus-circle" nzTheme="outline"></i>
              </button>
              <button
                nz-button
                nzSize="small"
                nzShape="circle"
                (click)="removeDealSource(i)"
                *ngIf="dealSources.controls.length > 1"
              >
                <i nz-icon nzType="delete" nzTheme="outline"></i>
              </button>
            </nz-col>
          </ng-container>
        </nz-row>
      </ng-container>

      <nz-row>
        <nz-form-item>
          <nz-form-label [nzSm]="8" [nzXs]="24" nzRequired
            >Start At</nz-form-label
          >
          <nz-form-control [nzSm]="16" [nzXs]="24">
            <nz-date-picker
              nzShowTime
              formControlName="startTime"
            ></nz-date-picker>
            <h5 class="now-button" (click)="startNow()">Now</h5>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label [nzSm]="8" [nzXs]="24" nzRequired
            >Deliver By</nz-form-label
          >
          <nz-form-control [nzSm]="16" [nzXs]="24">
            <nz-date-picker
              nzShowTime
              formControlName="endTime"
            ></nz-date-picker>
          </nz-form-control>
        </nz-form-item>
      </nz-row>
    </form>
  </form>
</ng-template>
