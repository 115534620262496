<nz-input-group class="mb-1">
  <div nz-row [nzGutter]="2">
    <div nz-col nzFlex="auto">
      <input type="text" nz-input readonly [value]="item.upc" />
    </div>
    <div nz-col nzFlex="auto">
      <input type="text" nz-input readonly [value]="item.serial" />
    </div>
    <div nz-col nzSpan="1">
      <button nz-button nzShape="circle" (click)="deleteScannedItem()">X</button>
    </div>
  </div>
</nz-input-group>
