<nz-page-header [nzGhost]="false">
  <nz-page-header-title>Purchase Order #{{ purchaseOrderDetails?.details.purchase_order_id }}</nz-page-header-title>
  <nz-page-header-subtitle *ngIf="purchaseOrderDetails?.details?.created_at"
    >Created on {{ purchaseOrderDetails.details?.created_at | date }}</nz-page-header-subtitle
  >

  <nz-page-header-extra>
    <a (click)="openPDF(purchaseOrderDetails?.purchase_order_pdf?.pdf)" nz-button nzType="default">View PDF</a>
    <p *ngIf="purchaseOrderDetails?.details.approved_at">
      Approved on {{ purchaseOrderDetails?.details?.approved_at | date: 'MMM d y, h:mm a' }}
    </p>
  </nz-page-header-extra>

  <nz-page-header-content>
    <nz-descriptions nzSize="small" [nzColumn]="2">
      <nz-descriptions-item nzTitle="Purchase Order Total" [nzSpan]="1">{{
        purchaseOrderDetails?.details?.calculated_total | currency
      }}</nz-descriptions-item>
      <nz-descriptions-item nzTitle="Quantity" [nzSpan]="1"
        ><a>{{ purchaseOrderDetails?.items?.length }}</a></nz-descriptions-item
      >
    </nz-descriptions>
  </nz-page-header-content>
</nz-page-header>

<ng-container *ngFor="let poItem of purchaseOrderDetails?.items">
  <oka-purchase-order-product-item [purchaseOrderItem]="poItem"></oka-purchase-order-product-item>
</ng-container>
