<nz-card nzTitle="Quickbooks Information">
  <div *ngIf="quickbooksData?.quickbooksUrl">
    <p>
      <a [href]="quickbooksData.quickbooksUrl">Visit in Quickbooks</a>
    </p>
    <p *ngIf="quickbooksData?.quickbooksLastSync">
      Last synced:
      <i> {{ quickbooksData.quickbooksLastSync | date: 'EEEE MMM d y, h:mm a' }} </i>
    </p>
  </div>
  <nz-divider nzOrientation="left"></nz-divider>
  <div class="center-content">
    <nz-spin *ngIf="isSyncingWithQuickbooks" nzSimple></nz-spin>
  </div>
  <div class="center-content">
    <button nz-button nzType="default" class="btn btn-primary" (click)="synchronizeWithQuickbooks()">
      Sync with Quickbooks
    </button>
  </div>
</nz-card>
