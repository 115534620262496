<ng-container *ngIf="isLoading">
  <ActivityIndicator
    [animating]="isLoading"
    [size]="'large'"
    class="center-middle"
  ></ActivityIndicator>
</ng-container>

<nz-layout>
  <nz-content>
    <WingBlank></WingBlank>

    <div class="profiles-list">
      <ng-container *ngIf="profilesFiltered.length === 0">
        <div class="center-middle">No Profiles</div>
      </ng-container>

      <ng-container *ngFor="let profile of profilesFiltered">
        <shdchn-profile-card-mobile
          [id]="'profile-' + profile.id"
          #profileCard
          [profileData]="profile"
          [@fadeInOut]
          (onOpenProfileImageModal)="showImageModal($event)"
        >
        </shdchn-profile-card-mobile>
      </ng-container>

      <Modal
        [(ngModel)]="this.isImageModalOpen"
        [transparent]="true"
        [title]="imageModalTitle"
        [maskClosable]="true"
        (onClose)="hideImageModal()"
      >
        <div [ngStyle]="{ height: 100, overflow: 'scroll' }">
          <Carousel
            *ngIf="this.isImageModalOpen"
            [infinite]="true"
            [vertical]="false"
            [selectedIndex]="1"
          >
            <CarouselSlide *ngFor="let image of imageModalImages">
              <div style="display: inline-block; width: 100%">
                <img [src]="image" style="pointer-events: none; width: 100%" />
              </div>
            </CarouselSlide>
          </Carousel>
        </div>
      </Modal>
    </div>
  </nz-content>
</nz-layout>
