import { Component, EventEmitter, Injector, Output } from '@angular/core';
import { ActionPopoverBaseComponent } from '../action-popover/action-popover.component';
import { ActionSheetService, en_US } from 'ng-zorro-antd-mobile';

@Component({
  selector: 'shdchn-match-page-action-popover',
  templateUrl: './match-page-action-popover.component.html',
  styleUrl: './match-page-action-popover.component.scss'
})
export class MatchPageActionPopoverComponent extends ActionPopoverBaseComponent {
  @Output() onAddMatchActivityAction = new EventEmitter<string>();
  @Output() onEditMatchAction = new EventEmitter<string>();

  constructor(injector: Injector, private _actionSheet: ActionSheetService) {
    super(injector);
  }

  showProfileActions(message) {
    const BUTTONS = ['Add Activity', 'Edit'];

    this._actionSheet.showActionSheetWithOptions(
      {
        options: BUTTONS,
        // destructiveButtonIndex: BUTTONS.length - 1,
        // cancelButtonIndex: BUTTONS.length - 2,
        title: 'Shidduch Actions',
        locale: en_US,
        // message: message,
        maskClosable: true
      },
      (buttonIndex) => {
        if (buttonIndex >= 0) {
          if (BUTTONS[buttonIndex] === 'Add Activity') {
            this.onAddMatchActivityAction.emit();
          } else if (BUTTONS[buttonIndex] === 'Edit') {
            this.onEditMatchAction.emit();
          }
        }
      }
    );
  }
}
