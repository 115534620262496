<nz-card [nzTitle]="coreInputTitle" [class.collapsed]="mode === 'read'" [nzExtra]="editCoreInfoExtraButtons">
  <form nz-form nzLayout="vertical" [formGroup]="formGroup">
    <nz-form-item nzBlock *ngFor="let control of formGroupKeys">
      <nz-form-label *ngIf="mode === 'edit'" [nzSm]="8" [nzXs]="24">{{ control | camelToTitle }}</nz-form-label>

      <nz-date-picker
        *ngIf="isDate(control)"
        nzShowTime
        [nzFormat]="DATE_TIME_FORMAT"
        [formControlName]="control"
      ></nz-date-picker>
      <input *ngIf="!isDate(control)" nz-input [placeholder]="control" [formControlName]="control" />
    </nz-form-item>

    <nz-form-item *ngIf="mode === 'edit'">
      <nz-form-control [nzSpan]="24" class="form-buttons">
        <button nz-button (click)="resetCoreDataForm()">Reset</button>
        <button nz-button nzType="primary" (click)="updateCoreData()">Update</button>
      </nz-form-control>
    </nz-form-item>
  </form>
</nz-card>

<ng-template #editCoreInfoExtraButtons>
  <a *ngIf="mode === 'read'" (click)="switchCoreInformationMode()">Edit</a>
  <a *ngIf="mode === 'edit'" (click)="switchCoreInformationMode()">Cancel</a>
</ng-template>
