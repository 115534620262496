import { Component, Input, OnInit } from '@angular/core';
import { SearchService } from '../../services/search-service.service';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { ShadchanService } from '../../user-pages/shadchan/services/shadchan.service';

@Component({
  selector: 'shdchn-search-bar-mobile',
  templateUrl: './search-bar-mobile.component.html',
  styleUrl: './search-bar-mobile.component.scss'
})
export class SearchBarMobileComponent implements OnInit {
  @Input() showCancelButton = true;
  searchValue = '';

  private searchInputSubject = new Subject<string>();

  constructor(
    private searchService: SearchService,
    private shadchanService: ShadchanService
  ) {}

  ngOnInit(): void {
    this.searchInputSubject
      .pipe(debounceTime(1000), distinctUntilChanged())
      .subscribe(() => {
        this.searchService.emitSearchEvent({
          type: 'submit',
          data: this.searchValue
        });
      });
  }

  onSearchFocus() {}

  onSearchBlur() {}

  onSearchCancel() {
    this.searchService.emitSearchEvent({ type: 'cancel' });
  }

  onSearchChange(event: string): void {
    if (event.length >= 3) {
      this.searchInputSubject.next(this.searchValue);
    }
  }

  onSearchClear(val: string) {}

  onSearchSubmit(val: string) {}
}
