import { NgModule } from '@angular/core';
import { ModalBaseModule } from '../modals.module';
import { CreateNewUserModalComponent } from './create-new-user-modal.component';

@NgModule({
  imports: [ModalBaseModule],
  declarations: [CreateNewUserModalComponent],
  exports: [CreateNewUserModalComponent],
})
export class CreateNewUserModalModule {}
