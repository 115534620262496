import { ScrollingModule } from "@angular/cdk/scrolling";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { InfiniteListComponent } from "./infinite-list/infinite-list.component";
import { NgzorroCommonModule } from "./ngzorro-common.module";
import { PurchaseOrderProductItemComponent } from "./purchase-order-product-item/purchase-order-product-item.component";

@NgModule({
  declarations: [InfiniteListComponent, PurchaseOrderProductItemComponent],
  imports: [
    CommonModule,
    NgzorroCommonModule,
    // For CdkVirutalList
    ScrollingModule,
  ],
  exports: [InfiniteListComponent, PurchaseOrderProductItemComponent],
})
export class OkaCommonModule {}
