<Icon
  Popover
  [ngStyle]="{
    height: '100%',
    display: 'flex',
    marginRight: '16px',
    'align-items': 'center'
  }"
  [mask]="true"
  [showArrow]="true"
  [overlay]="sortMenuPopover"
  [src]="'assets/icons/sort-2.svg'"
  [placement]="'bottomRight'"
  (onSelect)="onSelect($event)"
  (onVisibleChange)="onVisibleChange($event)"
  *ngIf="show"
></Icon>

<ng-template #sortMenuPopover>
  <PopoverItem [icon]="iconAlphabet">Name</PopoverItem>
  <PopoverItem [icon]="iconAge" [ngStyle]="{ whiteSpace: 'nowrap' }"
    >Age</PopoverItem
  >
  <!-- <PopoverItem [icon]="help">
    <span [ngStyle]="{ marginRight: 5 }">Help</span>
  </PopoverItem> -->
</ng-template>

<ng-template #iconAlphabet>
  <Icon
    *ngIf="sortStateAlphabet === 'dsc'"
    [src]="'assets/icons/sort-az-asc.svg'"
    [size]="'xs'"
    ></Icon
  >
  <Icon
    *ngIf="sortStateAlphabet === 'asc'"
    [src]="'assets/icons/sort-az-dsc.svg'"
    [size]="'xs'"
    ></Icon
  >
</ng-template>

<ng-template #iconAge>
    <Icon
    *ngIf="sortStateAge === 'dsc'"
    [src]="'assets/icons/number-asc.svg'"
    [size]="'xs'"
    ></Icon
  >
  <Icon
    *ngIf="sortStateAge === 'asc'"
    [src]="'assets/icons/number-dsc.svg'"
    [size]="'xs'"
    ></Icon
  >
</ng-template>

<ng-template #help>
  <Icon
    [src]="
      'https://gw.alipayobjects.com/zos/rmsportal/uQIYTFeRrjPELImDRrPt.svg'
    "
    [size]="'xs'"
  ></Icon>
</ng-template>
