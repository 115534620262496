<nz-layout>
  <nz-content class="content-page">
    <div class="search-bar">
      <div class="gender-options" *ngIf="!showFreeTextSearch">
        <nz-radio-group
          [(ngModel)]="genderRadioButton"
          (ngModelChange)="refilterByGender($event)"
        >
          <label nz-radio-button nzValue="male">Boys</label>
          <label nz-radio-button nzValue="female">Girls</label>
          <label nz-radio-button nzValue="all">All</label>
        </nz-radio-group>
      </div>

      <div class="free-text-search" *ngIf="showFreeTextSearch" [@fadeInSlideIn]>
        <nz-input-group [nzSuffix]="suffixIconSearch">
          <input
            type="text"
            nz-input
            [(ngModel)]="searchTerm"
            (ngModelChange)="onSearchInputChange()"
            placeholder="input search text"
          />
        </nz-input-group>
        <ng-template #suffixIconSearch>
          <span nz-icon nzType="search"></span>
        </ng-template>
      </div>

      <div class="search-tools">
        <button
          nz-button
          (click)="toggleSearchInput()"
          nzType="default"
          nzShape="circle"
        >
          <span nz-icon nzType="search"></span>
        </button>

        <button
          nz-button
          (click)="toggleCollapseAllCards()"
          nzType="default"
          nzShape="circle"
        >
          <span
            *ngIf="!isCollapsed"
            nz-icon
            nzType="shrink"
            nzTheme="outline"
          ></span>
          <span
            *ngIf="isCollapsed"
            nz-icon
            nzType="fullscreen"
            nzTheme="outline"
          ></span>
        </button>
      </div>

      <!-- <div class="sort-tools">
        <button
          class="sorting-button"
          nz-button
          (click)="toggleSearchInput()"
          nzType="default"
        >
          <span nz-icon nzType="calendar" nzTheme="twotone"></span>
          <span nz-icon nzType="up" nzTheme="outline"></span>
        </button>
      </div> -->
    </div>

    <div class="profiles-list">
      <ng-container *ngFor="let profile of profilesFiltered">
        <shdchn-profile-card
          #profileCard
          [profileData]="profile"
          [@fadeInOut]
        ></shdchn-profile-card>
      </ng-container>
    </div>
  </nz-content>
</nz-layout>
