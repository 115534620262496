export type SortState = 'asc' | 'dsc';
export type SortByCategory = 'name' | 'age';

export type ApplicationPages =
  | ApplicationPagesEnum
  | ApplicationPagesShadchanEnum;

export enum ApplicationPagesEnum {}

export enum ApplicationPagesShadchanEnum {
  home = 'home',
  profiles = 'profiles',
  profile = 'profile',
  new = 'new',
  tehillim = 'tehillim'
}

export const ApplicationPages = Object.keys(ApplicationPagesShadchanEnum);

export const SHARING_APPS = [
  { name: 'whatsapp', title: 'Whatsapp' },
  { name: 'sms', title: 'SMS' },
  { name: 'email', title: 'Email' }
];
