import {
  Component,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren
} from '@angular/core';
import {
  trigger,
  state,
  style,
  transition,
  animate
} from '@angular/animations';

import { ShadchanService } from '../services/shadchan.service';
import { GenderEnum, Profile } from '../../../../../types/profile';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { ProfileCardComponent } from '../../../common/profile-card/profile-card.component';

@Component({
  selector: 'shadchan-profiles-page',
  templateUrl: './profiles-page.component.html',
  styleUrl: './profiles-page.component.scss',
  animations: [
    trigger('fadeInSlideIn', [
      state(
        'void',
        style({
          opacity: 0,
          transform: 'translateX(-20px)' // Slide in from slightly to the left
        })
      ),
      transition(':enter, :leave', [
        animate('300ms ease-out') // Fade in and slide in quickly
      ])
    ]),
    trigger('fadeInOut', [
      state(
        '*',
        style({
          opacity: 1
        })
      ),
      transition(':enter', [
        style({ opacity: 0 }),
        animate('200ms ease-out', style({ opacity: 1 }))
      ]),
      transition(':leave', [animate('200ms ease-out', style({ opacity: 0 }))])
    ])
  ]
})
export class ProfilesPageComponent implements OnInit, OnDestroy {
  profiles: Profile[] = [];
  profilesFiltered: Profile[] = [];
  @ViewChildren(ProfileCardComponent)
  profileCards: QueryList<ProfileCardComponent>;
  showFreeTextSearch = false;
  searchTerm = '';
  private searchInputSubject = new Subject<string>();

  genderRadioButton: GenderEnum | 'all' = 'all';

  isCollapsed = false;

  constructor(private shadchanService: ShadchanService) {}

  ngOnInit(): void {
    this.shadchanService.getProfiles().subscribe((p) => {
      this.profiles = p;
      this.profilesFiltered = p;
    });

    this.searchInputSubject
      .pipe(debounceTime(1000), distinctUntilChanged())
      .subscribe(() => {
        this.doSearch();
      });
  }

  ngOnDestroy(): void {
    this.searchInputSubject.complete(); // Complete the subject to avoid memory leaks
  }

  doSearch() {
    this.shadchanService
      .generalSearch(this.searchTerm)
      .subscribe((results) => (this.profilesFiltered = results));
  }

  toggleSearchInput() {
    this.showFreeTextSearch = !this.showFreeTextSearch;
  }

  onSearchInputChange(): void {
    this.searchInputSubject.next(this.searchTerm);
  }

  openSearchModal() {}

  refilterByGender(e) {
    console.log('Refilter by gender:', e);
    if (e === 'all') {
      this.profilesFiltered = this.profiles;
    } else {
      this.profilesFiltered = this.profiles.filter(
        (profile) => profile.gender === e || !profile.gender
      );
    }
  }

  toggleCollapseAllCards(): void {
    this.isCollapsed = !this.isCollapsed;

    this.profileCards.forEach((card) => card.toggleCardExpansion());
  }
}
