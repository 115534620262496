import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpResponseBody } from '../../../../../types/misc';
import { TrackingNumberSummary } from '../../../../../types/warehouse-agent';
import { environment } from '../../../../environments/environment';

const BASE_URL = environment.baseUrl + '' + environment.apiUrl + '/warehouse';

@Injectable({ providedIn: 'root' })
export class WarehouseApiService {
  private warehouseApiUrl = environment.baseUrl + environment.apiUrl + '/warehouse';

  constructor(private http: HttpClient) {}

  checkInPackage(data: any): Observable<any> {
    const url = this.warehouseApiUrl + '/check-in';
    const headers = {
      'Content-Type': 'application/json'
    };

    return this.http
      .put<any>(url, data, { headers: headers })
      .pipe(catchError(this.handleError));
  }

  bulkCheckInPackage(data: any): Observable<any> {
    const url = this.warehouseApiUrl + '/check-in-bulk';
    const headers = {
      'Content-Type': 'application/json'
    };

    return this.http
      .put<any>(url, data, { headers: headers })
      .pipe(catchError(this.handleError));
  }

  getTrackingNumber(trackingNumber: string) {
    return this.http
      .get<HttpResponseBody<TrackingNumberSummary>>(this.warehouseApiUrl + '/tracking-number/' + trackingNumber)
      .pipe(map((res) => res.data));
  }

  getProductUPC(upc: string) {
    return this.http.get<any>(this.warehouseApiUrl + '/product-upc/' + upc).pipe(map((res) => res.data));
  }

  getBuyerNames() {
    return this.http
      .get(BASE_URL + '/buyer-names')
      .pipe(map((p: { data: { user_id: number; first_name: string; last_name: string }[]; status: string }) => p.data));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }
    return throwError('Unable to process the request');
  }
}
