<nz-form-item class="mb-0">
  <nz-form-control>
    <button nzSize="large" nzBlock nz-button nzType="primary">
      {{ buttonText }}
    </button>
  </nz-form-control>
</nz-form-item>

<div class="sub-button mt-0 mb-0">
  <ng-container *ngFor="let link of links; let i = index; let last = last">
    <p *ngIf="link === 'register'" (click)="goTo('register')">Register</p>
    <p *ngIf="link === 'sign-in'" (click)="goTo('sign-in')">Sign In</p>
    <p *ngIf="link === 'forgot-password'" (click)="goTo('forgot-password')">
      Forgot Password
    </p>
    <p *ngIf="link === 'verify'" (click)="goTo('verify')">Register</p>
    <ng-container *ngIf="!last">
      <p>|</p>
    </ng-container>
  </ng-container>
</div>
