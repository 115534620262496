<div>
  <cdk-virtual-scroll-viewport itemSize="73" class="infinite-container">
    <nz-list>
      <nz-list-item *cdkVirtualFor="let item of dataSource">
        <ng-container [ngTemplateOutlet]="listItemTemplate" [ngTemplateOutletContext]="{ $implicit: item }">
        </ng-container>
      </nz-list-item>
    </nz-list>
  </cdk-virtual-scroll-viewport>
</div>
