import { Component, Injector, Input, OnInit } from '@angular/core';
import { AbstractOkaTable } from '../../../../../tables/abstract-oka-table';

@Component({
  selector: 'oka-custom-query-results-table',
  templateUrl: './custom-query-results-table.component.html',
  styleUrls: ['./custom-query-results-table.component.scss']
})
export class CustomQueryResultsTableComponent extends AbstractOkaTable<any> implements OnInit {
  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }
}
