import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AdvancedSettingName, AdvancedSettingWithValue } from './../../../../../types/advanced-settings';
import { HttpResponse } from '../../../../../types/misc';
import { environment } from '../../../../environments/environment';

const BASE_URL = environment.baseUrl + '' + environment.apiUrl + '/admin';

@Injectable()
export class AdvancedSettingsService {
  cached = {};

  constructor(private http: HttpClient) {}

  getAdvancedSettings(discardCache = false) {
    if (!discardCache) {
      return of(this.cached);
    }
    return this.http.get<HttpResponse>(BASE_URL + '/advanced-settings').pipe(
      map((r) => r.data),
      tap((data) => {
        this.cached = data;
      })
    );
  }

  setAdvancedSetting(settingName: AdvancedSettingName, settingValue: boolean | string | number) {
    return this.http
      .post(BASE_URL + '/advanced-settings', {
        settingName,
        settingValue
      })
      .pipe(
        tap((result: HttpResponse) => {
          if (result.status === 200) {
            this.cached[settingName] = settingValue;
          }
        })
      );
  }
}
