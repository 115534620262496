import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  EventEmitter,
  Host,
  Inject,
  Input,
  OnInit,
  Optional,
  Output,
  SkipSelf
} from '@angular/core';
import { AuthPage, AuthPageName } from '../../auth-page';

@Component({
  selector: 'auth-common-button',
  templateUrl: './auth-button.component.html',
  styleUrl: './auth-button.component.scss'
})
export class AuthCommonButtonComponent implements OnInit {
  @Input() buttonText = 'ERROR';
  @Input() links: AuthPageName[] = [];
  goTo: (authPageName: AuthPageName) => void;

  // constructor(@Host() @Optional() private authPage: AuthPage) {}
  constructor(@Inject(AuthPage) private authPage: AuthPage) {}

  ngOnInit(): void {
    if (this.authPage) {
      this.goTo = this.authPage.goTo;
    } else {
      console.error('Cannot find host authPage!');
    }
  }
}
