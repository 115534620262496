<h3><b>Buyer:</b> {{ buyer }}</h3>
<nz-divider></nz-divider>

<ng-container *ngFor="let item of items">
  <div class="purchase-order-item">
    <h4>{{ item.product_serial }}</h4>
    <nz-table nzSize="small" nzTemplateMode>
      <tbody>
        <tr>
          <td><b>Tracking Number</b></td>
          <td>{{ item.tracking_number }}</td>
        </tr>
        <tr>
          <td><b> Product</b></td>
          <td>{{ item.product_name }} - {{ item.upc }}</td>
        </tr>
        <tr>
          <td><b> Price </b></td>
          <td>{{ item.price }}</td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</ng-container>

<nz-divider></nz-divider>

<div>
  <nz-table nzSize="small" nzTemplateMode>
    <tbody>
      <tr>
        <td><b>Total</b></td>
        <td>{{ priceTotal | currency }}</td>
      </tr>
    </tbody>
  </nz-table>
</div>

<div>
  <!-- <input nz-input /> -->
  <nz-divider></nz-divider>
  <button nzType="primary" (click)="close()" nz-button>Create Purchase Order</button>
</div>
