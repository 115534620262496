<nz-layout>
  <nz-content>
    <nz-row nzJustify="space-between">
      <nz-col><h2 class="mb-0">Create Purchase Orders</h2></nz-col>
      <nz-col>
        <button
          nz-button
          nzType="primary"
          nz-popconfirm
          nzPopconfirmTitle="This will create purchase orders for all Buyers with outstanding items. Proceed?"
          (nzOnConfirm)="createPurchaseOrderForAll()"
          (nzOnCancel)="cancel()"
          nzPopconfirmPlacement="topLeft"
        >
          Create Purchase Orders for All Buyers
        </button>
      </nz-col>
    </nz-row>

    <nz-row nzJustify="space-between" [nzGutter]="16">
      <nz-col nzSpan="6">
        <nz-card class="extend-height">
          <nz-card-meta
            nzTitle="Buyers"
            nzDescription="Buyers who have products checked-in that have not yet been included in any purchase orders"
          ></nz-card-meta>

          <ul nz-list [nzDataSource]="buyerData" nzSize="large">
            <li
              class="accountant-homepage-list"
              [class.selected]="selectedBuyer && item.username === selectedBuyer.username"
              nz-list-item
              *ngFor="let item of buyerData; index as i"
              nzNoFlex
              (click)="selectBuyer(item)"
            >
              {{ item.first_name + ' ' + item.last_name }} ({{ item.count }})
              <ul nz-list-item-actions>
                <nz-list-item-action>
                  <a (click)="hide(i)">user page</a>
                </nz-list-item-action>
                <nz-list-item-action>
                  <a (click)="hide(i)">hide</a>
                </nz-list-item-action>
              </ul>
            </li>
          </ul>
        </nz-card>
      </nz-col>
      <nz-col nzSpan="12">
        <nz-card>
          <nz-card-meta
            [nzTitle]="'Checked-in Products' + (this.selectedBuyer ? ' from ' + this.selectedBuyer.username : '')"
            nzDescription="These products have not been included in a purchase order"
          ></nz-card-meta>
        </nz-card>

        <nz-spin *ngIf="loadingProducts" nzSimple [nzSize]="'large'"></nz-spin>
        <nz-table
          *ngIf="productData"
          class="products-table"
          #productDataTable
          nzShowPagination
          nzShowSizeChanger
          [nzData]="productData"
          nzPageSize="1000"
        >
          <thead>
            <tr>
              <th [nzChecked]="'false'" (nzCheckedChange)="onSelectAllProducts($event)"></th>
              <th>product_name</th>
              <th>upc</th>
              <th>serial</th>
              <th>check-in time</th>
              <th>price</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let product of productDataTable.data" (dblclick)="setProductPrice(product)">
              <td [nzChecked]="isSelectedProduct(product)" (nzCheckedChange)="onProductSelected(product, $event)"></td>
              <td>{{ product.product_name }}</td>
              <td>{{ product.upc }}</td>
              <td>{{ product.product_serial }}</td>
              <td>{{ product.check_in_time | date: 'MMM d y' }}</td>
              <td>{{ product.price || product.committed_deal_price | currency }}</td>
            </tr>
          </tbody>
        </nz-table>
      </nz-col>
      <nz-col nzSpan="6">
        <nz-card class="extend-height" nzTitle="New Purchase Order">
          <ng-container *ngFor="let product of selectedProducts">
            <div class="purchase-order-item">
              <!-- <h4>{{ product.product_serial }}</h4> -->
              <nz-table nzSize="small" nzTemplateMode>
                <tbody>
                  <tr>
                    <td><b>Tracking Number</b></td>
                    <td>{{ product.tracking_number }}</td>
                  </tr>
                  <tr>
                    <td><b> Product</b></td>
                    <td>{{ product.product_name }} - {{ product.upc }}</td>
                  </tr>
                  <tr>
                    <td><b> Price </b></td>
                    <td>{{ product.price || product.committed_deal_price | currency }}</td>
                  </tr>
                </tbody>
              </nz-table>
            </div>
          </ng-container>

          <nz-divider *ngIf="selectedProducts.length > 0"></nz-divider>

          <div *ngIf="selectedProducts.length > 0">
            <nz-table nzSize="small" nzTemplateMode>
              <tbody>
                <tr>
                  <td><b>Total</b></td>
                  <td>{{ purchaseOrderPriceTotal | currency }}</td>
                </tr>
              </tbody>
            </nz-table>
          </div>

          <button
            [@animate]="{ value: '*', params: { y: '100%' } }"
            *ngIf="selectedProducts.length > 0"
            nz-button
            nzType="primary"
            nzBlock
            nzAlign="bottom"
            (click)="createPurchaseOrder()"
          >
            Create Purchase Order
          </button>
        </nz-card>
      </nz-col>
    </nz-row>
  </nz-content>
</nz-layout>
