import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerInvoiceFull } from '../../../../../types/invoice';
import { CustomerPackagedProductForAccountant } from '../../../../../types/packaged-product';
import { AccountantApiService } from '../services/accountant.service';

@Component({
  selector: 'oka-accountant-invoice-details-page',
  templateUrl: './accountant-invoice-details-page.component.html',
  styleUrls: ['./accountant-invoice-details-page.component.scss']
})
export class AccountantInvoiceDetailsPageComponent implements OnInit {
  invoiceId: string;
  invoice: CustomerInvoiceFull;

  invoiceItems: CustomerPackagedProductForAccountant[] = [];

  constructor(
    private accountantService: AccountantApiService,
    private activeRoute: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.invoiceId = this.activeRoute.snapshot.params.invoiceId;
    this.loadInvoice();
  }

  loadInvoice() {
    this.accountantService.getCustomerInvoice(this.invoiceId).subscribe(
      (result) => {
        this.invoice = result;
        this.invoiceItems = result.items;
      },
      (error) => {
        this.router.navigate(['../'], { relativeTo: this.activeRoute });
      }
    );
  }
}
