import { Component, Input, OnInit } from '@angular/core';
import { PackageForFrontend } from '../../../../types-frontend/package';

@Component({
  selector: 'app-recent-scanned-packages',
  templateUrl: './recent-scanned-packages.component.html',
  styleUrls: ['./recent-scanned-packages.component.scss']
})
export class RecentScannedPackagesComponent implements OnInit {
  @Input() scannedPackage: PackageForFrontend;

  tabIndex = 0;

  constructor() {}

  ngOnInit(): void {}
}
