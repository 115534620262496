import { Component, Input } from '@angular/core';

@Component({
  selector: 'shdchn-match-card-thumb',
  templateUrl: './match-card-thumb.component.html',
  styleUrl: './match-card-thumb.component.scss'
})
export class MatchCardThumbComponent {
  @Input() focusedProfileImage: string;
  @Input() matchedProfileImage: string;
}
