<nz-layout>
  <nz-content>
    <nz-row class="page-header" nzJustify="space-between">
      <nz-col><h2 class="mb-0">Products</h2> </nz-col>
      <nz-col>
        <nz-button-group class="mr-1">
          <button nz-button (click)="dataView = 'table'" nzType="">
            <i nz-icon nzType="ordered-list" nzTheme="outline"></i>
          </button>
          <button nz-button (click)="dataView = 'grid'">
            <i nz-icon nzType="table" nzTheme="outline"></i>
          </button>
        </nz-button-group>
        <button nz-button nzType="primary" class="margin-left" (click)="createProductCategory()">
          Create Product Category
        </button>
        <button nz-button nzType="primary" class="margin-left" (click)="createProduct()">Create New Product</button>
        <nz-button-group class="margin-left">
          <button nz-button (click)="refreshCache()">
            <i nz-icon nzType="reload" nzTheme="outline"></i>
          </button>
        </nz-button-group>
      </nz-col>
    </nz-row>

    <nz-spin *ngIf="showSpinner" nzSimple></nz-spin>

    <nz-table
      *ngIf="!showSpinner"
      #rowSelectionTable
      nzShowPagination
      nzShowSizeChanger
      [nzData]="productsDataForDisplay"
      nzTableLayout="auto"
      [nzPageSize]="1000"
      [nzScroll]="{ x: '2000px' }"
    >
      <thead>
        <tr>
          <th
            *ngFor="let columnHeaderDef of columnDefs"
            [nzCustomFilter]="columnHeaderDef.headerName"
            [nzSortDirections]="['ascend', 'descend']"
            [nzSortFn]="columnHeaderDef.sortFn"
          >
            {{ columnHeaderDef.headerName }}
            <nz-filter-trigger
              [nzVisible]="searchValues[columnHeaderDef.headerName].visible"
              (nzVisibleChange)="searchValuesOpened($event, columnHeaderDef.headerName)"
              [nzActive]="searchValues[columnHeaderDef.headerName].value.length > 0"
              [nzDropdownMenu]="menu"
            >
              <i nz-icon nzType="search"></i>
            </nz-filter-trigger>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of rowSelectionTable.data" (click)="onRowClick(data.upc)">
          <td>{{ data.product_id }}</td>
          <td>{{ data.product_name }}</td>
          <td>{{ data.product_category }}</td>
          <td nzEllipsis>{{ data.product_primary_photo }}</td>
          <td nzEllipsis>{{ data.product_secondary_photo }}</td>
          <td>{{ data.upc }}</td>
          <td>{{ data.sku }}</td>
          <td nzEllipsis>{{ data.product_description }}</td>
          <td>{{ data.created_at }}</td>
        </tr>
      </tbody>
    </nz-table>

    <div class="grid-view" *ngIf="dataView === 'grid'">
      <ng-container *ngFor="let product of products">
        <app-admin-product-item [product]="product"> </app-admin-product-item>
      </ng-container>
    </div>
  </nz-content>
</nz-layout>

<nz-dropdown-menu #menu="nzDropdownMenu">
  <div class="ant-table-filter-dropdown">
    <div class="search-box">
      <input type="text" nz-input placeholder="Search name" [(ngModel)]="searchValues[currentSearchColumn].value" />
      <button nz-button nzSize="small" nzType="primary" (click)="searchColumn()" class="search-button">Search</button>
      <button nz-button nzSize="small" (click)="resetSearchColumn()">Reset</button>
    </div>
  </div>
</nz-dropdown-menu>
