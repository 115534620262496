import { Component, Injector, OnInit } from '@angular/core';
import { User } from '../../../../types/user';
import { AbstractOkaTable } from '../abstract-oka-table';

@Component({
  selector: 'oka-users-table',
  templateUrl: './users-table.component.html',
  styleUrls: ['../oka-tables-styles.scss']
})
export class UsersTableComponent extends AbstractOkaTable<User> implements OnInit {
  currentSearchColumn: keyof User = 'username';

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
