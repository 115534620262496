import { NgModule } from '@angular/core';
import { ModalBaseModule } from '../modals.module';
import { CreateNewProductCategoryModalComponent } from './create-new-product-category-modal.component';

@NgModule({
  imports: [ModalBaseModule],
  declarations: [CreateNewProductCategoryModalComponent],
  exports: [CreateNewProductCategoryModalComponent],
})
export class CreateNewProductCategoryModalModule {}
