<nz-page-header>
  <nz-page-header-title>Payments</nz-page-header-title>
  <nz-page-header-subtitle></nz-page-header-subtitle>
  <nz-page-header-extra>
    <!-- <button nz-button (click)="exportData('csv')">Export as CSV</button> -->
    <!-- <button nz-button (click)="exportData('xls')">Export as XLS</button> -->
  </nz-page-header-extra>
</nz-page-header>

<nz-row nzJustify="space-between" [nzGutter]="16">
  <nz-col nzSpan="12">
    <nz-card>
      <p>Balance: {{ paymentsMetadata.outstandingAmount | currency }}</p>
      <p>Total Withdrawals: {{ paymentsMetadata.totalCompletedPayments | currency }}</p>
    </nz-card>

    <nz-card nzTitle="Requested Payments" [nzExtra]="extraButtons">
      <oka-requested-payments-table
        [data$]="buyerRequestedPaymentsData"
        [columnHeaders]="requestedPaymentsColumnHeaders"
      ></oka-requested-payments-table>
    </nz-card>
  </nz-col>

  <nz-col nzSpan="12">
    <nz-card nzTitle="Completed Payments">
      <oka-processed-payments-table
        [data$]="buyerProcessedPaymentsData"
        [columnHeaders]="processedPaymentsColumnHeaders"
      ></oka-processed-payments-table>
    </nz-card>
  </nz-col>
</nz-row>

<!-- ------ -->
<!-- EXTRAS -->
<!-- ------ -->

<ng-template #extraButtons>
  <button nz-button nzType="default" (click)="requestPayment()">Request Payment</button>
</ng-template>
