import { EventEmitter, Injectable, OnInit } from '@angular/core';
import {
  ApplicationPages,
  ApplicationPagesShadchanEnum,
  SortByCategory,
  SortState
} from '../../../common/constants';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, filter } from 'rxjs';

export interface PageNavigationEvent {
  category: 'new-page';
  data: ApplicationPages;
}

@Injectable({
  providedIn: 'root'
})
export class PageNavigationService implements OnInit {
  pageNavigationEvent = new BehaviorSubject<PageNavigationEvent>({
    category: 'new-page',
    data: null
  });
  pageNavigationEvent$ = this.pageNavigationEvent.asObservable();
  activePage: ApplicationPages;

  constructor(private router: Router) {
    this.determineCurrentShadchanPage(this.router.url);
    this.emitPageNavigationEvent({
      category: 'new-page',
      data: this.activePage
    });

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.determineCurrentShadchanPage(event.url);
        this.emitPageNavigationEvent({
          category: 'new-page',
          data: this.activePage
        });
      });
  }

  ngOnInit(): void {}

  emitPageNavigationEvent(pageNavigationEvent: PageNavigationEvent) {
    this.pageNavigationEvent.next(pageNavigationEvent);
  }

  determineCurrentShadchanPage(url: string) {
    if (this.isProfilesPage(url)) {
      this.activePage = ApplicationPagesShadchanEnum.profiles;
    } else if (this.isProfilePage(url)) {
      this.activePage = ApplicationPagesShadchanEnum.profile;
    } else if (this.isTehillimPage(url)) {
      this.activePage = ApplicationPagesShadchanEnum.tehillim;
    } else {
      this.activePage = null;
    }

    return this.activePage;
  }

  private isProfilePage(url: string) {
    return /\/user\/shadchan\/profile\/\d+$/.test(url);
  }

  private isProfilesPage(url: string) {
    return (
      url.endsWith('shadchan') ||
      url.endsWith('shadchan/') ||
      url.endsWith('/user/shadchan') ||
      url.endsWith('/user/shadchan/') ||
      url.endsWith('/user/shadchan/home') ||
      url.endsWith('/user/shadchan/home/')
    );
  }

  private isTehillimPage(url: string) {
    return url.endsWith('tehillim') || url.endsWith('tehillim/');
  }
}
