import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, tap, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Buyer } from '../../../../../types/buyer';
import { Customer, CustomerInvoiceFull } from '../../../../../types/invoice';
import { DBEntityName, HttpResponseBody, HttpResponse } from '../../../../../types/misc';
import {
  GroupedPackagedProductForAccountant,
  PackagedProductForAccountant
} from '../../../../../types/packaged-product';
import { PurchaseOrderFull } from '../../../../../types/purchase-order';
import { environment } from '../../../../environments/environment';
import { AbstractApiService } from '../../../services/abstract-api.service';

@Injectable({
  providedIn: 'root'
})
export class AccountantApiService extends AbstractApiService {
  accountantApiUrl = environment.baseUrl + environment.apiUrl + '/accountant';

  constructor(private http: HttpClient) {
    super();
  }

  getBuyersWithOutstandingItems() {
    return this.http
      .get<Buyer[]>(this.accountantApiUrl + '/buyers', {
        params: new HttpParams().set('withOutstandingItems', 'true')
      })
      .pipe();
  }

  getPackagedProducts(buyerUsername?: string, groupBy?: string, onlyNonProcessed = false) {
    const ENTITY: DBEntityName = 'PackedProduct';
    const lastId = this.getCachedLastId(ENTITY) || 0;
    const params = { last_id: String(lastId) };
    if (buyerUsername) {
      params['buyer'] = buyerUsername;
    }
    if (onlyNonProcessed) {
      params['onlyNonProcessed'] = true;
    }

    if (groupBy) {
      params['groupBy'] = groupBy;
    }

    return this.http
      .get<GroupedPackagedProductForAccountant[]>(this.accountantApiUrl + '/packaged-products', {
        params
      })
      .pipe(
        tap((data) => {
          this.cacheLastId(data, ENTITY, 'sku');
        })
      );
  }

  getPurchaseOrders() {
    const ENTITY: DBEntityName = 'PurchaseOrder';
    const lastId = this.getCachedLastId(ENTITY) || 0;
    return this.http
      .get<any[]>(this.accountantApiUrl + '/purchase-orders', { params: { last_id: String(lastId) } })
      .pipe(
        tap((data) => {
          this.cacheLastId(data, ENTITY, 'id');
        })
      );
  }

  getPurchaseOrder(poId: string) {
    return this.http
      .get(this.accountantApiUrl + '/purchase-order/' + poId)
      .pipe(map((i: any) => i.data as PurchaseOrderFull));
  }

  createPurchaseOrder(newPO: {
    buyerUsername: string;
    name: string;
    notes: string | null;
    calculatedTotal: number;
    serialNumbers: string[];
  }) {
    return this.http.put(this.accountantApiUrl + '/purchase-order', newPO, {});
  }

  createAllPurchaseOrders(buyerUsernames: string[]) {
    return this.http.put(this.accountantApiUrl + '/all-purchase-orders', { buyerUsernames }, {});
  }

  generatePurchaseOrder(poId: string) {
    return this.http.post(
      this.accountantApiUrl + '/generate-quickbooks-purchase-order/' + poId,
      {},
      { responseType: 'blob' }
    );
  }

  emailPDF(poId: string) {
    const url = this.accountantApiUrl + '/send-purchase-order-email/' + poId;
    return this.http
      .post<HttpResponseBody<void>>(url, {}, { headers: this.headers })
      .pipe(catchError((err: { error: HttpResponse }) => throwError(err.error.message)));
  }

  createQuickbooksPurchaseOrder(purchaseOrderId: string) {
    return this.http.post(this.accountantApiUrl + '/quickbooks', {
      purchase_order_id: purchaseOrderId
    });
  }

  setPrice(productSerial: string, productPrice: number) {
    return this.http.post(this.accountantApiUrl + '/price', {
      productSerial,
      productPrice
    });
  }

  getCustomers() {
    const ENTITY: DBEntityName = 'Customer';
    const lastId = this.getCachedLastId(ENTITY) || 0;
    return this.http
      .get<HttpResponseBody<Customer[]>>(this.accountantApiUrl + '/customers', {
        params: { last_id: String(lastId) }
      })
      .pipe(
        tap((data) => {
          this.cacheLastId(data.data, ENTITY, 'user_id');
        }),
        map((data) => data.data)
      );
  }

  getCustomerInvoices() {
    const ENTITY: DBEntityName = 'CustomerInvoice';
    const lastId = this.getCachedLastId(ENTITY) || 0;
    return this.http
      .get<HttpResponseBody<CustomerInvoiceFull[]>>(this.accountantApiUrl + '/invoices', {
        params: { last_id: String(lastId) }
      })
      .pipe(
        tap((data) => {
          this.cacheLastId(data.data, ENTITY, 'id');
        }),
        map((data) => data.data)
      );
  }

  getPackagedProductsSku() {
    const ENTITY: DBEntityName = 'PackagedProductsSku';
    const lastId = this.getCachedLastId(ENTITY) || 0;
    return this.http
      .get<HttpResponseBody<{ sku: string; max_qty: number; }[]>>(this.accountantApiUrl + '/packaged-products-sku', {
        params: { last_id: String(lastId) }
      })
      .pipe(
        tap((data) => {
          this.cacheLastId(data.data, ENTITY, 'sku');
        }),
        map((data) => data.data)
      );
  }

  getCustomerInvoice(invoiceId: string) {
    return this.http
      .get<HttpResponseBody<CustomerInvoiceFull>>(this.accountantApiUrl + '/invoice/' + invoiceId)
      .pipe(map((i) => i.data));
  }

  createCustomerInvoice(newInvoice: { customer: number; items: PackagedProductForAccountant[] }) {
    return this.http.put(this.accountantApiUrl + '/invoice', newInvoice, {});
  }
}
