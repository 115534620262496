import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AgGridModule } from 'ag-grid-angular';
import { NgzorroCommonModule } from '../../common/ngzorro-common.module';
import { OkaCommonModule } from '../../common/oka-common.module';
import { OkaTablesModule } from '../../tables/oka-tables.module';
import { AccountantDealCommitReportsPageComponent } from './accountant-deal-commit-reports-page/accountant-deal-commit-reports-page.component';
import { AccountantInventoryPageComponent } from './accountant-inventory-page/accountant-inventory-page.component';
import { AccountantInvoiceDetailsPageComponent } from './accountant-invoice-details-page/accountant-invoice-details-page.component';
import { AccountantInvoiceReportsPageComponent } from './accountant-invoice-reports-page/accountant-invoice-reports-page.component';
import { AccountantPurchaseOrderDetailsPageComponent } from './accountant-purchase-order-details-page/accountant-purchase-order-details-page.component';
import { AccountantPurchaseOrderReportsPageComponent } from './accountant-purchase-order-reports-page/accountant-purchase-order-reports-page.component';
import { InvoiceCreationDrawerComponent } from './invoice-creation-drawer/invoice-creation-drawer.component';

@NgModule({
  declarations: [
    AccountantPurchaseOrderDetailsPageComponent,
    AccountantPurchaseOrderReportsPageComponent,
    AccountantDealCommitReportsPageComponent,
    AccountantInventoryPageComponent,
    InvoiceCreationDrawerComponent,
    AccountantInvoiceReportsPageComponent,
    AccountantInvoiceDetailsPageComponent
  ],
  imports: [
    AgGridModule,
    RouterModule,
    CommonModule,
    FormsModule,
    NgzorroCommonModule,
    ReactiveFormsModule,

    // Oka Modules
    OkaCommonModule,
    OkaTablesModule
  ],
  exports: []
})
export class AccountantModule {}
