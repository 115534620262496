<nz-layout>
  <nz-content>
    <nz-row nzJustify="space-between">
      <nz-col><h2 class="mb-0">Purchase Orders</h2></nz-col>
    </nz-row>

    <p *ngIf="rowData">
      <ag-grid-angular
        style="height: 80vh"
        class="ag-theme-alpine w-100"
        [rowData]="rowData"
        [columnDefs]="columnDefs"
        [rowSelection]="'multiple'"
        (rowDoubleClicked)="rowDoubleClicked($event)"
        (selectionChanged)="onSelectionChanged($event)"
        (firstDataRendered)="onFirstDataRendered($event)"
      >
      </ag-grid-angular>
    </p>

    <!-- <button class="btn btn-info" (click)="load()">Load More</button> -->
  </nz-content>
</nz-layout>
