import { NgModule } from '@angular/core';
import { ModalBaseModule } from '../modals.module';
import { CreateOrEditActiveDealModalComponent } from './create-or-edit-active-deal-modal.component';

@NgModule({
  imports: [ModalBaseModule],
  declarations: [CreateOrEditActiveDealModalComponent],
  exports: [CreateOrEditActiveDealModalComponent],
})
export class CreateOrEditActiveDealModalModule {}
