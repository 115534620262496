<nz-table
  #rowSelectionTable
  nzShowPagination
  nzShowSizeChanger
  [nzData]="dataForDisplay"
  nzTableLayout="auto"
  [nzPageSize]="1000"
>
  <thead>
    <tr>
      <th
        *ngFor="let columnHeaderDef of columnDefs"
        [nzCustomFilter]="columnHeaderDef.headerName"
        [nzSortDirections]="['ascend', 'descend']"
        [nzSortFn]="columnHeaderDef.sortFn"
      >
        {{ columnHeaderDef.headerName }}
        <nz-filter-trigger
          [nzVisible]="searchValues[columnHeaderDef.headerName].visible"
          (nzVisibleChange)="searchValuesOpened($event, columnHeaderDef.headerName)"
          [nzActive]="searchValues[columnHeaderDef.headerName].value.length > 0"
        >
          <i nz-icon nzType="search"></i>
        </nz-filter-trigger>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of rowSelectionTable.data" (dblclick)="onRowClick('users/' + data.username)">
      <td *ngFor="let columnHeader of columnHeaders">
        {{ data[columnHeader] }}
      </td>
    </tr>
  </tbody>
</nz-table>
