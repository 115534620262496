import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CustomerInvoiceFull } from '../../../../../types/invoice';
import { HttpResponse, HttpResponseBody } from '../../../../../types/misc';
import { PurchaseOrderForAdminReports, PurchaseOrderFull } from '../../../../../types/purchase-order';
import { environment } from '../../../../environments/environment';
import { AbstractApiService } from '../../../services/abstract-api.service';

@Injectable({
  providedIn: 'root'
})
export class AdminPurchaseOrdersService extends AbstractApiService {
  adminApiUrl = environment.baseUrl + environment.apiUrl + '/admin';

  constructor(private http: HttpClient) {
    super();
  }

  getInvoice(invoiceId: string) {
    return this.http.get(this.adminApiUrl + '/invoice/' + invoiceId).pipe(map((i: any) => i.data as CustomerInvoiceFull));
  }

  getPurchaseOrder(poId: string) {
    return this.http.get(this.adminApiUrl + '/purchase-order/' + poId).pipe(map((i: any) => i.data as PurchaseOrderFull));
  }

  getPurchaseOrdersReport(isUnpaid?: boolean) {
    let url = this.adminApiUrl + '/purchase-orders';
    if (isUnpaid) {
      url += '?isUnpaid=true';
    }

    return this.http.get<PurchaseOrderForAdminReports[]>(url, {
      headers: this.headers
    });
  }

  emailPDF(poId: string) {
    const url = this.adminApiUrl + '/send-purchase-order-email/' + poId;
    return this.http
      .post<HttpResponseBody<void>>(url, {}, { headers: this.headers })
      .pipe(catchError((err: { error: HttpResponse }) => throwError(err.error.message)));
  }
}
