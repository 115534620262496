<auth-common-template-shell [errorMsg]="errorMsg" [isLoading]="isLoading">
  <form
    nz-form
    [formGroup]="registrationForm"
    (submit)="submit()"
    [@animateStagger]="{ value: '100' }"
  >
    <!-- gender -->
    <nz-form-item [@animate]="{ value: '*', params: { y: '100%' } }">
      <nz-form-control nzErrorTip="Please select a gender!">
        <nz-input-group [nzPrefix]="gender" nzSize="large">
          <nz-select formControlName="gender" nzBorderless>
            <nz-option nzValue="male" nzLabel="Male"></nz-option>
            <nz-option nzValue="female" nzLabel="Female"></nz-option>
          </nz-select>
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>

    <!-- first_name -->
    <nz-form-item [@animate]="{ value: '*', params: { y: '100%' } }">
      <nz-form-control nzErrorTip="Please enter a proper first name">
        <nz-input-group nzSize="large">
          <input
            id="input-first-name"
            formControlName="first_name"
            nz-input
            placeholder="First Name"
          />
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>

    <!-- last_name -->
    <nz-form-item [@animate]="{ value: '*', params: { y: '100%' } }">
      <nz-form-control nzErrorTip="Please enter a proper last name">
        <nz-input-group nzSize="large">
          <input
            id="input-last-name"
            formControlName="last_name"
            nz-input
            placeholder="Last Name"
          />
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item [@animate]="{ value: '*', params: { y: '100%' } }">
      <nz-form-control nzHasFeedback nzErrorTip="Email is not valid">
        <nz-input-group [nzPrefix]="email" nzSize="large">
          <input nz-input formControlName="email" email />
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item [@animate]="{ value: '*', params: { y: '100%' } }">
      <!-- <nz-form-label [nzSpan]="5">Phone</nz-form-label> -->
      <nz-form-control nzErrorTip="Please enter a ten digit phone number">
        <nz-input-group nzPrefixIcon="phone" nzSize="large">
          <input
            id="input-phone"
            type="phone"
            formControlName="phone"
            nz-input
            placeholder="718 123 4567"
          />
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>

    <!-- username -->
    <nz-form-item [@animate]="{ value: '*', params: { y: '100%' } }">
      <nz-form-control nzErrorTip="Please Enter A Valid Username!">
        <nz-input-group nzPrefixIcon="user" nzSize="large">
          <input
            formControlName="username"
            name="username"
            nz-input
            placeholder="Username"
          />
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>

    <!-- password -->
    <nz-form-item [@animate]="{ value: '*', params: { y: '100%' } }">
      <nz-form-control nzErrorTip="Please input your correct password">
        <nz-input-group nzPrefixIcon="lock" nzSize="large">
          <input
            id="password"
            formControlName="password"
            nz-input
            type="password"
            placeholder="Password"
          />
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
    <auth-common-button
      [@animate]="{ value: '*', params: { y: '100%' } }"
      [buttonText]="'Register'"
      [links]="['sign-in']"
    ></auth-common-button>
  </form>
</auth-common-template-shell>

<ng-template #gender>
  <Icon [src]="'assets/icons/gender.svg'" [size]="'xs'"></Icon>
</ng-template>

<ng-template #email>
  <Icon [src]="'assets/icons/email-2.svg'" [size]="'xs'"></Icon>
</ng-template>
