import { NgModule } from '@angular/core';
import { ModalBaseModule } from '../modals.module';
import { GenerateChequeModalComponent } from './generate-cheque-modal.component';

@NgModule({
  imports: [ModalBaseModule],
  declarations: [GenerateChequeModalComponent],
  exports: [GenerateChequeModalComponent],
})
export class GenerateChequeModalModule {}
