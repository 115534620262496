import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-pages',
  template: '<router-outlet></router-outlet>'
})
export class UserPagesComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
