import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { omit } from '@common/third-party/micro-dash';
import {
  DealAvailable,
  DealCancelled,
  DealCommitted,
  DealCommittedAggregated,
  DealCompleted
} from '../../../../../types/deal';
import { BuyerApiService } from '../services/buyer.api.service';
import { BaseUserPage } from '../../user-page';

type TABS = 'available-deals' | 'committed-deals' | 'completed-deals' | 'cancelled-deals';

@Component({
  selector: 'app-buyer-deals-page',
  templateUrl: './buyer-deals-page.component.html',
  styleUrls: ['./buyer-deals-page.component.scss']
})
export class BuyerDealsPageComponent extends BaseUserPage implements OnInit {
  dealsActive: DealAvailable[] = [];
  dealsCommitted: DealCommittedAggregated[] = [];
  dealsCompleted: DealCompleted[] = [];
  dealsCancelled: DealCancelled[] = [];
  isLoading = false;

  constructor(activatedRoute: ActivatedRoute, private buyerApiService: BuyerApiService) {
    super(activatedRoute);
  }

  ngOnInit() {
    this.loadAvailableDeals();
  }

  tabChange(tab: TABS) {
    this.isLoading = true;
    switch (tab) {
      case 'available-deals':
        this.loadAvailableDeals();
        return;
      case 'committed-deals':
        this.loadCommittedDeals();
        return;
      case 'completed-deals':
        this.loadCompletedDeals();
        return;
      case 'cancelled-deals':
        this.loadCancelledDeals();
        return;
      default:
        return;
    }
  }

  private loadAvailableDeals() {
    this.buyerApiService.getAvailableDeals().subscribe(
      (deals) => {
        this.dealsActive = deals.data;
        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;
      }
    );
  }

  private loadCommittedDeals() {
    this.buyerApiService.getCommittedDeals().subscribe(
      (deals) => {
        this.dealsCommitted = deals.data.sort(sortByDateDescending);
        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;
      }
    );
  }

  private loadCompletedDeals() {
    this.buyerApiService.getCompletedDeals().subscribe(
      (deals) => {
        this.dealsCompleted = deals.data.sort(sortByDateDescending);
        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;
      }
    );
  }

  private loadCancelledDeals() {
    this.buyerApiService.getCancelledDeals().subscribe(
      (deals) => {
        this.dealsCancelled = deals.data.sort(sortByDateDescending);
        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;
      }
    );
  }

  private _groupCommittedDeals(deals: DealCommitted[]) {
    const uniqueDealIds = deals.map((deal) => deal.deal_id).filter((value, i, self) => self.indexOf(value) === i);
    const quantityPerDealId = {};
    uniqueDealIds.forEach((dealId) => {
      const dealsWithCurrentId = deals.filter((d) => d.deal_id === dealId);
      const totalQuantity = dealsWithCurrentId.reduce((a, deal) => a + deal.quantity, 0);
      quantityPerDealId[dealId] = totalQuantity;
    });

    let groupedDeals: DealCommittedAggregated[] = [];
    uniqueDealIds.forEach((dealId) => {
      let firstMatchingDeal = deals.find((deal) => deal.deal_id === dealId);
      firstMatchingDeal.quantity = quantityPerDealId[dealId];
      groupedDeals.push(omit(firstMatchingDeal, 'buyer_deal_id') as DealCommittedAggregated);
    });

    return groupedDeals;
  }
}

const sortByDateDescending = (d1: DealCommittedAggregated, d2: DealCommittedAggregated) =>
  // Most recent first
  new Date(d1.timestamp).getTime() <= new Date(d2.timestamp).getTime() ? 1 : -1;
