import { DealCreation } from '../../../../../types/deal';

export const dealCreationColumnHeaders: (keyof DealCreation)[] = [
  'product_id',
  'start_time',
  'end_time',
  'price',
  'max_quantity',
  'is_public'
];
