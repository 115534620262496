<oka-admin-base-details-page *ngIf="dealData" [title]="'Deal ' + dealId" [subtitle]="dealData?.product_name">
  <div class="top-row" nz-row [nzGutter]="{ xs: 8, sm: 8, md: 16, lg: 24 }">
    <div nz-col nzSpan="10">
      <base-details-core-info
        coreInputTitle="Deal Information"
        [formGroup]="updateDealForm"
        (onResetFormData)="resetUpdateDealDataForm()"
        (onSubmitFormData)="updateDealData()"
      ></base-details-core-info>

      <nz-card
        nzTitle="Deal Sources"
        [class.collapsed]="dealSourcesMode === 'read'"
        [nzExtra]="editDealSourcesExtraButtons"
        *ngIf="dealData?.dealSources"
      >
        <ul>
          <li *ngFor="let dealSource of dealData?.dealSources">
            <a [href]="dealSource.source_link" target="_blank">{{ dealSource.source_name }}</a>
          </li>
        </ul>
      </nz-card>
    </div>

    <div nz-col nzSpan="8">
      <nz-card>
        <div class="deal-status">
          Deal Status: <span [ngClass]="dealStatus">{{ dealStatus }}</span>
        </div>
      </nz-card>
      <nz-card class="status-actions mt-4">
        <div>
          <button nz-button nzType="primary" nzSize="small" (click)="generateTextMessage()">
            Generate Text Message
          </button>
        </div>
        <div *ngIf="dealStatus === 'active'">
          <button nz-button nzType="primary" nzSize="small" (click)="endNow()">End Deal Now</button>
        </div>
        <div *ngIf="!dealData.deleted_at">
          <button
            nz-button
            nzType="primary"
            nzSize="small"
            nz-popconfirm
            [nzPopconfirmTitle]="
              'Are you sure you want to delete this deal? Buyers who have committed to it will no longer see their committment'
            "
            nzPopconfirmPlacement="topLeft"
            (nzOnConfirm)="deleteDeal()"
          >
            Delete Deal
          </button>
        </div>
      </nz-card>
    </div>

    <div nz-col nzSpan="6">
      <nz-card nzTitle="Product Information">
        <p>
          <b>Product</b>:<a [routerLink]="['/user/admin/products', dealData.upc]"> {{ dealData.product_name }}</a>
        </p>
        <p><b>Product UPC</b>: {{ dealData.upc }}</p>
        <p><b>Product Description</b>: {{ dealData.product_description }}</p>
        <div *ngIf="dealData && dealData.product_primary_photo">
          <img class="product-image" [src]="dealData.product_primary_photo" alt="" />
        </div>
      </nz-card>
    </div>
  </div>
</oka-admin-base-details-page>

<nz-modal
  [(nzVisible)]="isTextMessageModalVisible"
  nzTitle="Text Message"
  [nzContent]="modalContent"
  [nzFooter]="modalFooter"
  (nzOnCancel)="handleCancelTextMessage()"
>
  <ng-template #modalTitle>Custom Modal Title</ng-template>

  <ng-template #modalContent>
    <p>HSO Purchasing Network ({{ currentDate | date: 'MM/dd/yy' }})</p>
    <br />

    <p>{{ dealData?.product_name }}</p>
    <p>{{ dealData?.product_description }}</p>

    <ng-container *ngFor="let dealSource of dealData?.dealSources">
      <p>
        {{ dealSource.source_name }} -
        <a [href]="dealSource?.source_link_short || dealSource?.source_link || ''" target="_blank">{{
          dealSource?.source_link_short || dealSource?.source_link || ''
        }}</a>
      </p>
    </ng-container>
    <p>Paying {{ dealData?.price | currency }}</p>

    <br />

    <p><strong>Must</strong> Fill Out Form:</p>
    <p><a href="https://forms.gle/1kVP" target="_blank">https://forms.gle/1kVP</a></p>

    <br />
    <p>PM for NY Shipping address</p>
    <br />

    <p>DE Address:</p>
    <p>First Name: HSO Technologies</p>
    <p>Last Name: Buyer Name</p>
    <p>24A Trolley Square</p>
    <p>Wilmington, DE 19806</p>

    <br />

    <p>
      Upload trackings:
      <a href="https://oka-main.tk" target="_blank">HSO-TECH.COM</a>
    </p>
  </ng-template>

  <ng-template #modalFooter>
    <button nz-button nzType="default" (click)="handleCancelTextMessage()">Close</button>
    <button nz-button nzType="default" (click)="handleCopyTextMessage()">Copy</button>
  </ng-template>
</nz-modal>

<ng-template #editDealSourcesExtraButtons>
  <a *ngIf="mode === 'read'" (click)="switchDealSourcesInformationMode()">Edit</a>
  <a *ngIf="mode === 'edit'" (click)="switchDealSourcesInformationMode()">Cancel</a>
</ng-template>
