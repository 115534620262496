<ng-container *ngIf="isPageDataLoaded">
  <div class="page-header pb-3">
    <shdchn-match-card-thumb
      [focusedProfileImage]="match.male.profile_photo_url"
      [matchedProfileImage]="match.female.profile_photo_url"
    ></shdchn-match-card-thumb>
    <h1 class="mb-0 ml-3">
      <a [routerLink]="'/user/shadchan/profile/' + match.male.id">{{
        match.male.last_name
      }}</a>
      &#8621;
      <a [routerLink]="'/user/shadchan/profile/' + match.female.id">{{
        match.female.last_name
      }}</a>
      <Badge
        *ngIf="isEditMode"
        [text]="'Edit Mode'"
        [setStyle]="{'color': 'black', 'background-color': '#faf999',}"
      ></Badge>
    </h1>
  </div>

  <Tabs
    [page]="2"
    [useOnPan]="true"
    [swipeable]="true"
    [activeTab]="activeTabIndex"
    [tabBarActiveTextColor]="'#1890ff'"
    (onChange)="onTabChange($event)"
    (onTabClick)="onTabClick($event)"
  >
    <TabPane [title]="basicInfoTabPane">
      <ng-template #basicInfoTabPane>
        <div>Basic Info</div>
      </ng-template>

      <div
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #fff;
        "
      >
        <Accordion
          [defaultActiveKey]=""
          [activeKey]="activeKey"
          (onChange)="onAccordionChange($event)"
          style="width: 100%"
        >
          <AccordionPanel header="Created On" key="created_on">
            <WingBlank>
              <p class="created-on my-4">
                <i>{{ match.created_on | date : 'short' }}</i>
              </p>
            </WingBlank>
          </AccordionPanel>
        </Accordion>
      </div>
    </TabPane>

    <TabPane [title]="matchActivityTabPane">
      <ng-template #matchActivityTabPane>
        <div>Activity</div>
      </ng-template>

      <List>
        <ng-container *ngFor="let activity of match.match_activities">
          <ListItem
            [thumb]="deleteListItemTemplate"
            [extra]="activity.created_on | date : 'short'"
            [align]="'top'"
            multipleLine
          >
            {{ activity.match_action | jsonProp }}
            <Brief *ngIf="activity.additional_info">{{
              activity.additional_info
            }}</Brief>

            <ng-template #deleteListItemTemplate>
              <Icon
                class="trash"
                [src]="'assets/icons/trash.svg'"
                [size]="'xxs'"
                *ngIf="isEditMode"
                (click)="deleteMatchActivity(activity.id)"
              ></Icon>
            </ng-template>
          </ListItem>
        </ng-container>
      </List>
    </TabPane>
  </Tabs>

  <Modal
    [(ngModel)]="isShowingMatchActivityModal"
    [transparent]="true"
    [title]="'Add Activity'"
  >
    <div [ngStyle]="{ height: 100, overflow: 'scroll' }">
      <List
        [className]="'eligible-matches-list'"
        *ngIf="!isUpdatingMatchActivity"
      >
        <ListItem
          multipleLine
          (onClick)="selectMatchActivity(activityOption)"
          *ngFor="let activityOption of matchActivityOptions"
        >
          {{ activityOption | jsonProp }}
        </ListItem>
      </List>
      <ng-container *ngIf="isUpdatingMatchActivity">
        <ActivityIndicator
          [animating]="isUpdatingMatchActivity"
        ></ActivityIndicator>
      </ng-container>
    </div>
  </Modal>
</ng-container>

<ng-template #actionPopover>
  <shdchn-match-page-action-popover
    (onAddMatchActivityAction)="showMatchActivityModal()"
    (onEditMatchAction)="toggleEditMode()"
  ></shdchn-match-page-action-popover>
</ng-template>
