<nz-page-header [nzGhost]="false">
  <nz-page-header-title *ngIf="profileFull">{{
    profileFull.last_name + ', ' + profileFull.first_name
  }}</nz-page-header-title>
  <nz-page-header-subtitle *ngIf="profileFull.date_of_birth">{{
    profileFull.date_of_birth | date
  }}</nz-page-header-subtitle>
</nz-page-header>

<span nz-icon nzType="team" nzTheme="outline"></span>
