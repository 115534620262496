import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { HttpResponse } from '../../../../types/misc';
import { environment } from '../../../environments/environment';

const BASE_URL = environment.baseUrl + '' + environment.apiUrl + '/accountant';

export class DataSourceCommon<T> extends DataSource<T> {
  private pageSize = 10;
  private cachedData: T[] = [];
  private fetchedPages = new Set<number>();
  private dataStream = new BehaviorSubject<T[]>(this.cachedData);
  private complete$ = new Subject<void>();
  private disconnect$ = new Subject<void>();
  private dataSourceUrl;

  constructor(privateUrl: string, private http: HttpClient) {
    super();

    this.dataSourceUrl = environment.baseUrl + '' + environment.apiUrl + '/' + privateUrl;
  }

  completed(): Observable<void> {
    return this.complete$.asObservable();
  }

  connect(collectionViewer: CollectionViewer): Observable<T[]> {
    this.setup(collectionViewer);
    return this.dataStream;
  }

  disconnect(): void {
    this.disconnect$.next();
    this.disconnect$.complete();
  }

  private setup(collectionViewer: CollectionViewer): void {
    this.fetchPage(0);
    collectionViewer.viewChange.pipe(takeUntil(this.complete$), takeUntil(this.disconnect$)).subscribe((range) => {
      if (this.cachedData.length >= 50) {
        this.complete$.next();
        this.complete$.complete();
      } else {
        const endPage = this.getPageForIndex(range.end);
        this.fetchPage(endPage + 1);
      }
    });
  }

  private getPageForIndex(index: number): number {
    return Math.floor(index / this.pageSize);
  }

  private fetchPage(page: number): void {
    if (this.fetchedPages.has(page)) {
      return;
    }
    this.fetchedPages.add(page);

    const headers = {
      'Content-Type': 'application/json'
    };

    this.http
      .get<HttpResponse<T[]>>(this.dataSourceUrl)
      .pipe(
        catchError(() => of({ data: [] })),
        map((res: HttpResponse<T[]>) => res.data)
      )
      .subscribe((res: T[]) => {
        this.cachedData.splice((page - 1) * this.pageSize, this.pageSize, ...res);
        this.dataStream.next(this.cachedData);
      });
  }
}
