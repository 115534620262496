<nz-layout>
  <nz-content>
    <nz-row nzJustify="space-between"> </nz-row>

    <nz-row class="page-header header" nzJustify="space-between">
      <nz-col><h2 class="mb-0">Payments Center</h2></nz-col>
      <nz-col>
        <nz-col class="ml-2">
          <p class="mb-0" style="display: inline">Status &nbsp;</p>
          <nz-switch
            [ngModel]="paymentStatus"
            (ngModelChange)="onPaymentStatusToggle($event)"
            nzCheckedChildren="Complete"
            nzUnCheckedChildren="Incomplete"
          ></nz-switch>
        </nz-col>
        <nz-col class="ml-2">
          <button (click)="generateChequeCSV()" nz-button>Generate Cheques CSV</button>
        </nz-col>
        <nz-col class="ml-2">
          <button nz-button (click)="refreshData()">
            <i nz-icon nzType="reload" nzTheme="outline"></i>
          </button>
        </nz-col>
      </nz-col>
    </nz-row>

    <oka-admin-payments-table
      [data$]="paymentsData"
      [columnHeaders]="paymentsColumnHeaders"
      (selectionUpdated)="updateSelectedPayments($event)"
      (paymentOperation)="paymentOperation($event)"
    ></oka-admin-payments-table>
  </nz-content>
</nz-layout>
