<!-- <nz-card [nzTitle]="'#' + purchaseOrder.purchase_order_id"> -->
<nz-card [nzTitle]="purchaseOrderTitleTemplate">
  <nz-table nzSize="small" nzSimple nzTemplateMode>
    <tbody>
      <tr>
        <td>Created At</td>
        <td>{{ purchaseOrder.created_at | date: 'MM/d/y hh:mm' }}</td>
      </tr>
      <tr>
        <td>Calculated Total</td>
        <td>{{ purchaseOrder.calculated_total | currency }}</td>
      </tr>
      <tr *ngIf="purchaseOrder.products_count">
        <td>Products Count</td>
        <td>{{ purchaseOrder.products_count }}</td>
      </tr>
      <tr *ngIf="purchaseOrder.notes">
        <td>Notes</td>
        <td>
          <i> {{ purchaseOrder.notes }}</i>
        </td>
      </tr>
    </tbody>
  </nz-table>
</nz-card>

<ng-template #purchaseOrderTitleTemplate>
  <a [routerLink]="[purchaseOrder.purchase_order_id]">PO #{{ purchaseOrder.purchase_order_id }} </a>
</ng-template>
