import { Pipe, PipeTransform } from '@angular/core';

import { Type, ɵstringify as stringify } from '@angular/core';

export function invalidPipeArgumentError(type: Type<any>, value: Object) {
  return Error(`InvalidPipeArgument: '${value}' for pipe '${stringify(type)}'`);
}

@Pipe({ name: 'eventLogActionFormatted' })
export class EventLogActionPipe implements PipeTransform {
  transform(value: string): string;
  transform(value: null | undefined): null;
  transform(value: string | null | undefined): string | null;
  transform(value: string | null | undefined): string | null {
    if (value == null) return null;
    if (typeof value !== 'string') {
      throw invalidPipeArgumentError(EventLogActionPipe, value);
    }

    let result = value
      .replace(/_/g, ' ')
      .replace(/^(\w)/, (x) => x.toUpperCase()) // first char of first word to uppercase
      .replace(/\s(\w)/g, (x) => x.toUpperCase()); // first char of subsequent words to uppercase
    return result.charAt(0).toUpperCase() + result.slice(1);
  }
}
