<oka-infinite-list [dataSource]="dataSource">
  <ng-template let-item>
    <ng-container *ngIf="item">
      <nz-col nzSpan="4" style="padding-left: 26px"
        ><span
          ><a>{{ item.buyerdeal_id }}</a></span
        ></nz-col
      >
      <!-- <nz-col nzSpan="6"
        ><span class="did">{{ item.did | eventLogActionFormatted }}</span></nz-col
      >
      <nz-col nzSpan="4">{{ item.what }}</nz-col>
      <nz-col nzSpan="5"
        ><span *ngIf="item.value" class="log-item-value">{{ item.value }}</span></nz-col
      >
      <nz-col nzSpan="5">{{ item.timestamp | date: 'MM/d/y hh:mm' }}</nz-col> -->
      <p>{{ item | json }}</p>
    </ng-container>
  </ng-template>
</oka-infinite-list>
