import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { map } from 'rxjs/operators';
import { DealAvailable } from '../../../../../types/deal';
import { HttpResponse } from '../../../../../types/misc';
import { BuyerApiService } from '../services/buyer.api.service';
import { PackageSummary } from './../../../../../types/package.d';
import { BaseUserPage } from './../../user-page';

@Component({
  selector: 'app-buyer-home-page',
  templateUrl: './buyer-homepage.component.html',
  styleUrls: ['./buyer-homepage.component.scss']
})
export class BuyerHomepageComponent extends BaseUserPage implements OnInit {
  showNewTrackingNumberForm = false;
  showNonPending = true;

  deals: DealAvailable[] = [];
  trackingNumbers: PackageSummary[] = [];

  constructor(
    activatedRoute: ActivatedRoute,
    private messagingService: NzMessageService,
    private buyerApiService: BuyerApiService
  ) {
    super(activatedRoute);
  }

  ngOnInit() {
    this.buyerApiService
      .getAvailableDeals()
      .pipe(map((result) => result.data))
      .subscribe((deals: DealAvailable[]) => {
        this.deals = deals;
      });

    this.getTrackingNumbersSummary();
  }

  showNewTrackingNumberInputArea(): void {
    console.log('Show New Tracking Number Input Area');
  }

  deleteTrackingNumber(trackingNumber: PackageSummary): void {
    if (trackingNumber?.check_in_time) {
      this.messagingService.error('Tracking number ' + trackingNumber.tracking_number + ' cannot be deleted');
      return;
    }

    if (confirm(`Are you sure you want to delete tracking number ${trackingNumber.tracking_number}?`)) {
      this.buyerApiService.deleteTrackingNumber(trackingNumber.tracking_number).subscribe(() => {
        this.messagingService.success('Tracking number ' + trackingNumber.tracking_number + ' deleted');
        this.trackingNumbers = this.trackingNumbers.filter((item) => item !== trackingNumber);
      });
    }
  }

  editTrackingNumber(event, index): void {
    event.preventDefault();
    console.log('Edit Tracking Number ' + index.toString());
  }

  purchaseOrder(event, index): void {
    event.preventDefault();
    console.log('Purchase Order ' + index.toString());
  }

  getTrackingNumbersSummary() {
    this.buyerApiService.getTrackingNumbersSummary().subscribe((trackingNumbers) => {
      this.trackingNumbers = trackingNumbers.data;
    });
  }

  addTrackingNumbers(trackingNumbersStr) {
    let trackingNumbers: string[] = trackingNumbersStr.split('\n');

    // Remove duplicates from tracking numbers
    trackingNumbers = trackingNumbers.filter((item, index) => {
      return trackingNumbers.indexOf(item) === index;
    });

    this.buyerApiService.insertTrackingNumbers(trackingNumbers).subscribe(
      () => {
        this.getTrackingNumbersSummary();
        this.messagingService.success('Tracking Number Added');
      },
      (error: string) => {
        this.messagingService.error(error);
      }
    );
  }
}
