<ng-container *ngIf="!isProfileLoaded">
  <ActivityIndicator [animating]="!isProfileLoaded"></ActivityIndicator>
</ng-container>

<ng-container *ngIf="isProfileLoaded">
  <div class="profile-page-header pb-3">
    <h1>
      {{ profile.first_name }} {{ profile.last_name }}
      <span *ngIf="isEditMode" class="edit-mode-badge">Edit Mode</span>
    </h1>
    <div class="right-side"></div>
  </div>

  <ng-container *ngIf="isEditMode">
    <shdchn-profile-form-mobile
      mode="edit"
      [profile]="profile"
      #profileForm
    ></shdchn-profile-form-mobile>
  </ng-container>

  <Tabs
    *ngIf="!isEditMode"
    [page]="2"
    [useOnPan]="true"
    [swipeable]="true"
    [activeTab]="activeTabIndex"
    [tabBarActiveTextColor]="'#1890ff'"
    (onChange)="onTabChange($event)"
    (onTabClick)="onTabClick($event)"
  >
    <TabPane [title]="identityTabPane">
      <ng-template #identityTabPane>
        <div>Personal Info</div>
      </ng-template>
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #fff;
        "
      >
        <Accordion
          [defaultActiveKey]=""
          [activeKey]="activeKey"
          (onChange)="onAccordionChange($event)"
          style="width: 100%"
        >
          <AccordionPanel header="Description" key="description">
            <WingBlank>
              <p class="profile-description my-4">
                <i>{{ profile['description'] }}</i>
              </p>
            </WingBlank>
          </AccordionPanel>
          <AccordionPanel header="Photos" key="photos">
            <List className="profile-accordion-item">
              <ListItem>
                <div [ngStyle]="{ height: 100, overflow: 'scroll' }">
                  <Carousel
                    [infinite]="true"
                    [vertical]="false"
                    [selectedIndex]="0"
                    *ngIf="showPhotoCarousel && images.length"
                  >
                    <CarouselSlide
                      *ngFor="let image of images"
                      style="overflow: unset"
                    >
                      <div style="width: 100%">
                        <img
                          [src]="image"
                          style="pointer-events: none; width: 100%"
                        />
                      </div>
                    </CarouselSlide>
                  </Carousel>
                </div>
              </ListItem>
            </List>
          </AccordionPanel>
          <AccordionPanel header="Identity" key="identity">
            <WingBlank>
              <List className="identity-accordion-item">
                <ListItem
                  *ngFor="let prop of ProfilePropertiesForIdentitySection"
                  [extra]="
                    isDateField(prop)
                      ? (profile[prop] | date : 'yyyy-MM-dd')
                      : profile[prop]
                  "
                >
                  {{ prop | jsonProp }}
                </ListItem>
              </List>
            </WingBlank>
          </AccordionPanel>
        </Accordion>
      </div>
    </TabPane>

    <TabPane [title]="datingHistoryTabPane">
      <ng-template #datingHistoryTabPane>
        <div>Dating History</div>
      </ng-template>

      <List>
        <ng-container *ngFor="let match of profile.matches">
          <shdchn-match-card
            [match]="match"
            [focusedProfile]="match.male"
            [matchedProfile]="match.female"
            [activities]="match.match_activities"
          ></shdchn-match-card>
        </ng-container>
      </List>
    </TabPane>
  </Tabs>

  <Modal
    [(ngModel)]="isShowingMatchmakingModal"
    [transparent]="true"
    [title]="'Make a Match'"
  >
    <div [ngStyle]="{ height: 100, overflow: 'scroll' }">
      <shdchn-search-bar-mobile
        [showCancelButton]="false"
        *ngIf="!isLoadingNewMatchCreation"
      ></shdchn-search-bar-mobile>
      <List
        [className]="'eligible-matches-list'"
        *ngIf="!isLoadingNewMatchCreation"
      >
        <ListItem
          multipleLine
          [extra]="eligibleMatch.city"
          [thumb]="eligibleMatch.profile_photo_url"
          (onClick)="selectEligibleMatch(eligibleMatch)"
          *ngFor="let eligibleMatch of eligibleMatches"
        >
          {{ eligibleMatch.first_name }} {{ eligibleMatch.last_name }}
          <Brief>{{ eligibleMatch.date_of_birth | age }}</Brief>
        </ListItem>
      </List>
      <ng-container *ngIf="isLoadingNewMatchCreation">
        <ActivityIndicator
          [animating]="isLoadingNewMatchCreation"
        ></ActivityIndicator>
      </ng-container>
    </div>
  </Modal>
</ng-container>

<ng-template #message>
  <div class="am-action-sheet-message">Profile Options</div>
</ng-template>

<ng-template #actionPopover>
  <action-popover-profile-page
    (onShareAction)="onShareAction($event)"
    (onEditProfileAction)="editProfile()"
    (onSaveProfileEditsAction)="saveProfileEdits()"
    (onCancelProfileEditsAction)="cancelProfileEdits()"
    (onShowMatchmakingModalAction)="showMatchmakingModal()"
  ></action-popover-profile-page>
</ng-template>
