import { Injectable } from '@angular/core';
import { LocalStorageKey } from '../../types-frontend/misc';

@Injectable({ providedIn: 'root' })
export class LocalStorageService {
  constructor() {}

  storeInLocalStorage(lsKey: LocalStorageKey, state: any) {
    localStorage.setItem(lsKey, JSON.stringify(state));
  }

  getFromLocalStorage<P>(lsKey: LocalStorageKey): P | null {
    const state = localStorage.getItem(lsKey);
    return JSON.parse(state) as P;
  }

  deleteFromLocalStorage(lsKey: LocalStorageKey) {
    localStorage.removeItem(lsKey);
  }
}
