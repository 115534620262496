<ng-template #tpl>
  <form nz-form nzLayout="vertical" [formGroup]="requestPaymentForm">
    <div class="modal-body">
      <nz-form-item nzBlock>
        <nz-form-label [nzXs]="24" nzRequired>Select Purchase Orders</nz-form-label>

        <nz-select
          [nzMaxTagCount]="3"
          [nzMaxTagPlaceholder]="tagPlaceHolder"
          nzMode="multiple"
          nzPlaceHolder="Please select"
          formControlName="purchaseOrders"
          (ngModelChange)="calculateTotalWithdrawalAmount($event)"
        >
          <nz-option
            *ngFor="let item of listOfPurchaseOrders"
            [nzLabel]="item.purchase_order_id.toString() + ' ($' + item.calculated_total.toFixed(2) + ')'"
            [nzValue]="item.purchase_order_id"
          ></nz-option>
        </nz-select>
      </nz-form-item>

      <nz-form-item nzBlock>
        <nz-form-label [nzXs]="24" nzRequired>Total Amount to be Withdrawn</nz-form-label>
        <nz-input-number
          readonly
          nz-input
          formControlName="withdrawalAmount"
          nzDisabled
          [nzFormatter]="formatterDollar"
        ></nz-input-number>
      </nz-form-item>

      <nz-form-item nzBlock>
        <nz-form-label [nzXs]="24">Payment Request Notes</nz-form-label>
        <textarea rows="4" nz-input formControlName="requestNotes"></textarea>
      </nz-form-item>
    </div>
  </form>
</ng-template>

<ng-template #tagPlaceHolder let-selectedList>and {{ selectedList.length }} more selected</ng-template>
