<SearchBar
  [(ngModel)]="searchValue"
  [placeholder]="'Search'"
  [showCancelButton]="showCancelButton"
  (onBlur)="onSearchBlur()"
  (onFocus)="onSearchFocus()"
  (onCancel)="onSearchCancel()"
  (onClear)="onSearchClear(searchValue)"
  (onSubmit)="onSearchSubmit(searchValue)"
  (onChange)="onSearchChange($event)"
></SearchBar>
