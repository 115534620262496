import { NgModule } from '@angular/core';
import { ModalBaseModule } from '../modals.module';
import { CreateNewProductModalComponent } from './create-new-product-modal.component';

@NgModule({
  imports: [ModalBaseModule],
  declarations: [CreateNewProductModalComponent],
  exports: [CreateNewProductModalComponent],
})
export class CreateNewProductModalModule {}
