import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface QuickbooksCardData {
  quickbooksUrl: string;
  quickbooksLastSync: Date;
}

@Component({
  selector: 'base-details-quickbooks-sync',
  templateUrl: './base-details-quickbooks-sync.component.html',
  styleUrls: ['../base-details-common.scss', './base-details-quickbooks-sync.component.scss']
})
export class BaseDetailsQuickbooksSyncComponent implements OnInit {
  @Input() quickbooksData: QuickbooksCardData;
  @Input() isSyncingWithQuickbooks = false;

  @Output() onSynchronizeWithQuickbooks = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  synchronizeWithQuickbooks() {
    this.onSynchronizeWithQuickbooks.emit();
  }
}
