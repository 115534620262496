import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AuthService } from "../../services/auth.service";

@Component({
  selector: "app-nav-bar",
  templateUrl: "./nav-bar.component.html",
  styleUrls: ["./nav-bar.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class NavBarComponent implements OnInit {
  @Input() navBarMenu: string[] = [];
  username: string;

  constructor(
    private activeRoute: ActivatedRoute,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.authService.getUserState$().subscribe((userState) => {
      if (userState) {
        this.username = userState.username;
      }
    });
  }

  isMobileScreen() {
    return window.innerWidth < 600;
  }

  formatForMenu(menuItem: string) {
    return menuItem
      .split("-")
      .map((i) => i[0].toUpperCase() + i.substring(1))
      .join(" ");
  }

  signout() {
    this.authService.signOut();
  }
}
