// Vendor
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { HotTableModule } from '@handsontable/angular';
import { AgGridModule } from 'ag-grid-angular';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NgzorroCommonModule } from '../../common/ngzorro-common.module';
import { OkaCommonModule } from '../../common/oka-common.module';
import { OkaTablesModule } from '../../tables/oka-tables.module';
import { NonBuyerSharedModule } from '../non-buyer-shared/non-buyer-shared.module';
import { ActiveDealsComponent } from './active-deals-page/active-deals.component';
import { EventLogActionPipe } from './activity-feed-page/activity-feed-item.pipe';
import { ActivityFeedPageComponent } from './activity-feed-page/activity-feed-page.component';
import { AdminAdvancedSettingsPageComponent } from './admin-advanced-settings-page/admin-advanced-settings-page.component';
import { AdminPaymentsPageComponent } from './admin-payments-page/admin-payments-page.component';
import { BaseDetailsModule } from './base-details/base-details.module';
import { DealDetailsPageComponent } from './deal-details-page/deal-details-page.component';
import { ImportDataPageComponent } from './import-data-page/import-data-page.component';
import { ProductDetailsPageComponent } from './product-details-page/product-details-page.component';
import { AdminProductItemComponent } from './products-page/admin-product-item/admin-product-item.component';
import { AdminBuyerReportsPageComponent } from './reports-page/admin-buyer-reports-page/admin-buyer-reports-page.component';
import { AdminInventoryReportsPageComponent } from './reports-page/admin-inventory-reports-page/admin-inventory-reports-page.component';
import { CustomerInvoicesReportsPageComponent } from './reports-page/customer-invoices-reports-page/customer-invoices-reports-page.component';
import { PurchaseOrdersReportsPageComponent } from './reports-page/purchase-orders-reports-page/purchase-orders-reports-page.component';
import { ReportsPageComponent } from './reports-page/reports-page.component';
import { TrackingNumbersPageComponent } from './reports-page/tracking-numbers-page/tracking-numbers-page.component';
import { AdvancedSettingsService } from './services/advanced-settings.service';
import { UserDetailsPageComponent } from './user-details-page/user-details-page.component';

@NgModule({
  declarations: [
    // Pages
    AdminAdvancedSettingsPageComponent,
    AdminPaymentsPageComponent,
    AdminBuyerReportsPageComponent,
    CustomerInvoicesReportsPageComponent,
    DealDetailsPageComponent,
    ImportDataPageComponent,
    ProductDetailsPageComponent,
    PurchaseOrdersReportsPageComponent,
    AdminInventoryReportsPageComponent,
    ReportsPageComponent,
    TrackingNumbersPageComponent,
    UserDetailsPageComponent,

    // Other
    AdminProductItemComponent,
    ActiveDealsComponent,
    ActivityFeedPageComponent,

    // Pipes
    EventLogActionPipe
  ],
  imports: [
    FormsModule,
    BrowserModule.withServerTransition({ appId: 'oka-main-app' }),
    CommonModule,
    HttpClientModule,
    AgGridModule,
    HotTableModule,
    ReactiveFormsModule,
    RouterModule,
    NgzorroCommonModule,
    NzSwitchModule,
    NonBuyerSharedModule,

    // Oka Modules
    OkaCommonModule,
    OkaTablesModule,
    BaseDetailsModule
  ],
  exports: [
    ActivityFeedPageComponent,
    AdminPaymentsPageComponent,
    TrackingNumbersPageComponent,
    ActiveDealsComponent,
    AdminProductItemComponent,
    UserDetailsPageComponent
  ],
  providers: [AdvancedSettingsService]
})
export class AdminModule {}
