// Define TypeScript types

import { MatchPopulated } from './match';

export interface Profile {
  id: number;
  shadchan_id: number;
  gender: GenderEnum;
  first_name?: string;
  last_name?: string;
  date_of_birth?: Date | string;
  city?: string;
  country?: string;
  occupation?: string;
  is_college_educated?: boolean;
  description?: string;
  profile_photo_url?: string; // URL of the profile photo
  photo_urls?: string[]; // additional photos
  marital_status?: MartialStatusEnum;
  religious_identity?: ReligiousCategoryEnum | string;
  religious_seeking?: (ReligiousCategoryEnum | string)[];
  tehillim_name?: string;
  created_on?: Date | string; // TODO make this field required
}

export type ProfileSummary = Pick<
  Profile,
  'first_name' | 'last_name' | 'gender' | 'profile_photo_url'
>;

export interface ProfileFull extends Profile {
  matches: MatchPopulated[];
}
export interface ProfilePopulated extends Profile {
  matches: MatchPopulated[];
}

export interface Photo {
  id: number;
  profile_id: number;
  photo_url: string; // URL of the photo
}

export enum ReligiousCategoryEnum {
  chassidish = 'chassidish',
  litvish = 'litvish',
  modern = 'modern',
  modern_machmir = 'modern_machmir',
  sephardi = 'sephardi',
  yeshivish = 'yeshivish',
  baal_teshuva = 'baal_teshuva',
  other = 'other'
}

export enum GenderEnum {
  male = 'male',
  female = 'female'
}

export enum MartialStatusEnum {
  single = 'single',
  widowed = 'widowed',
  divorced = 'divorced'
}
