<nz-layout>
  <nz-content>
    <nz-row nzJustify="space-between">
      <nz-col><h2 class="mb-0">Tracking Numbers</h2></nz-col>
      <nz-col>
        <button nz-button nzType="primary" (click)="exportAsCSV()">Export as CSV</button>
      </nz-col>
    </nz-row>

    <oka-tracking-numbers-table
      [data$]="trackingNumberData$"
      [columnHeaders]="columnHeaders"
    ></oka-tracking-numbers-table>
  </nz-content>
</nz-layout>
