import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AccountantApiService } from '../../services/accountant.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ModalComponent } from '../../../../common/modal-common/modal.component';

@Component({
  selector: 'app-set-price-modal',
  templateUrl: './set-price-modal.component.html',
  styleUrls: ['./set-price-modal.component.scss']
})
export class SetPriceModalComponent extends ModalComponent implements OnInit, AfterViewInit {
  title = 'Set Product Instance Price';
  setPriceForm: FormGroup = new FormGroup({});
  inputs: { productSerial: string };

  @ViewChild('tpl') public templateref: TemplateRef<any>;
  formatterDollar = (value: string) => `$ ${value}`;
  parserDollar = (value: string) => value.replace('$ ', '');

  constructor(
    nzModal: NzModalService,
    private nzMessage: NzMessageService,
    private accountantService: AccountantApiService
  ) {
    super(nzModal);
  }

  ngAfterViewInit(): void {
    this.openModal({}, () => {
      if (this.setPriceForm.valid) {
        const productSerial = this.inputs?.productSerial;
        this.accountantService.setPrice(productSerial, this.setPriceForm.get('price').value).subscribe(() => {
          this.nzMessage.success('Product Price Set');
          this.closeModal();
        });
      } else {
        if (this.setPriceForm.errors) {
          this.nzMessage.error('Invalid Form');
          return;
        }

        for (const controlName in this.setPriceForm.controls) {
          const control = this.setPriceForm.controls[controlName];
          if (control.errors) {
            this.nzMessage.error(`${controlName} is invalid`);
            break;
          }
        }
      }
    });
  }

  ngOnInit(): void {
    this.setPriceForm = new FormGroup(
      {
        price: new FormControl(0, [Validators.required, Validators.pattern('^[1-9][0-9]*(:?\\.[0-9]{1,2})?$')])
      },
      { validators: [] }
    );
  }
}
