<nz-layout>
  <nz-content>
    <List [className]="'tehillim-list'" [renderHeader]="'Tehillim'">
      <ng-container *ngFor="let profile of profilesFiltered">
        <ListItem
          *ngIf="profile.tehillim_name"
          (click)="goToProfile(profile.id)"
          >{{ profile.tehillim_name }}
        </ListItem>
      </ng-container>
    </List>
  </nz-content>
</nz-layout>
