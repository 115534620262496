import { Component, Input, ViewEncapsulation } from '@angular/core';
import { absAnimations } from '../../../animations';
import { AuthPage } from '../../auth-page';

@Component({
  selector: 'auth-common-template-shell',
  templateUrl: './auth-template-shell.component.html',
  styleUrl: './auth-template-shell.component.scss',
  encapsulation: ViewEncapsulation.None,
  animations: absAnimations
})
export class AuthCommonTemplateShellComponent {
  @Input() errorMsg = '';
  @Input() isLoading = false;
}
