import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { BehaviorSubject } from 'rxjs';
import { PaymentsMetadata, ProcessedPayment, RequestedPayment } from '../../../../../types/payment';
import { OkaModalService } from '../../../common/modal-common/oka-modals.service';
import { RequestPaymentModalComponent } from '../../admin/modals/request-payment-modal/request-payment-modal.component';
import { BuyerApiService } from '../services/buyer.api.service';

@Component({
  selector: 'oka-buyer-payments-page',
  templateUrl: './buyer-payments-page.component.html',
  styleUrls: ['./buyer-payments-page.component.scss']
})
export class BuyerPaymentsPageComponent implements OnInit {
  requestedPaymentsColumnHeaders = ['amount', 'requested_at', 'purchase_orders'];
  buyerRequestedPaymentsData = new BehaviorSubject<RequestedPayment[]>([]);

  processedPaymentsColumnHeaders = ['amount', 'requested_at', 'cheque_generated_at', 'purchase_orders'];
  buyerProcessedPaymentsData = new BehaviorSubject<ProcessedPayment[]>([]);

  paymentsMetadata: PaymentsMetadata = {
    totalCompletedPayments: 0,
    outstandingAmount: 0
  };

  constructor(
    private modalService: OkaModalService,
    private buyerApiService: BuyerApiService,
    private nzMessageService: NzMessageService
  ) {}

  ngOnInit(): void {
    this.loadBuyerPayments();
    this.loadPaymentMetadata();
  }

  loadPaymentMetadata() {
    this.buyerApiService.getPaymentsMetadata().subscribe((paymentMetadata) => {
      this.paymentsMetadata = paymentMetadata;
    });
  }

  loadBuyerPayments() {
    this.buyerApiService.getBuyerPayments().subscribe(
      (responseJson: { requestPayments: RequestedPayment[]; processedPayments: ProcessedPayment[] }) => {
        this.buyerRequestedPaymentsData.next(responseJson.requestPayments);
        this.buyerProcessedPaymentsData.next(responseJson.processedPayments);
      },
      (error: any) => {
        console.log(error);
      }
    );
  }

  requestPayment() {
    this.modalService
      .openModal(RequestPaymentModalComponent, { maxWithdrawalAmount: this.paymentsMetadata.outstandingAmount })
      .subscribe((modalRef) => {
        modalRef.afterClose.subscribe(
          (result: { purchaseOrders: string[]; withdrawalAmount: number; requestNotes: string | null }) => {
            if (result) {
              this.buyerApiService.requestPayment(result.purchaseOrders, result.requestNotes).subscribe(
                (_responseJson: any) => {
                  this.paymentsMetadata.outstandingAmount -= result?.withdrawalAmount || 0;
                  this.nzMessageService.success(`Withdrawal request for $${result?.withdrawalAmount} submitted`);
                  this.loadBuyerPayments();
                },
                (error: any) => {
                  this.nzMessageService.error('Unable to request payment');
                  console.log(error);
                }
              );
            }
          }
        );
      });
  }
}
