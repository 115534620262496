<nz-layout>
  <nz-content>
    <nz-row class="header" nzJustify="space-between">
      <nz-col><h2 class="mb-0">User Management</h2></nz-col>
      <nz-col>
        <button nz-button nzType="primary" (click)="createNewUser()">Create New User</button>
      </nz-col>
    </nz-row>

    <oka-users-table [data$]="usersData" [columnHeaders]="columnHeaders"></oka-users-table>
  </nz-content>
</nz-layout>
