<nz-page-header [nzGhost]="false">
  <nz-page-header-title>Invoice #{{ invoiceId }}</nz-page-header-title>
  <nz-page-header-subtitle *ngIf="invoice?.created_at"
    >Created on {{ invoice?.created_at | date }}</nz-page-header-subtitle
  >
</nz-page-header>

<div class="po-info-row">
  <nz-card nzTitle="Buyer Info">
    <div *ngIf="invoice?.customer_name">
      <p><b>Customer</b>: {{ invoice.customer_name }}</p>
    </div>
  </nz-card>

  <nz-card nzTitle="PDF" [nzExtra]="pdfExtraButtons">
    <div *ngIf="invoice?.pdf && invoice?.pdf.pdf">
      <a (click)="openPDF(invoice?.pdf.pdf)">View PDF<i nz-icon nzType="expand-alt" nzTheme="outline"></i></a>
    </div>
    <div *ngIf="invoice?.pdf && invoice?.pdf.emailed_at">
      <p>Emailed at {{ pdf.emailed_at | date }}</p>
    </div>
  </nz-card>
</div>

<nz-page-header [nzGhost]="false" style="margin-top: 1rem">
  <nz-page-header-title>Products</nz-page-header-title>
  <nz-page-header-subtitle>({{ invoiceItems.length }} total)</nz-page-header-subtitle>
</nz-page-header>

<ng-container *ngFor="let item of invoiceItems">
  <oka-purchase-order-product-item [purchaseOrderItem]="item"></oka-purchase-order-product-item>
</ng-container>

<!-- ------ -->
<!-- EXTRAS -->
<!-- ------ -->

<ng-template #pdfExtraButtons>
  <span *ngIf="invoice?.pdf && invoice?.pdf.pdf">
    <button nz-button nzType="default" (click)="generatePDF(invoice?.pdf.pdf)">Generate PDF</button>
  </span>
  <button
    *ngIf="invoice?.customer_email"
    nz-button
    nzType="default"
    nz-popconfirm
    [nzPopconfirmTitle]="'This will email the purchase order to ' + invoice?.customer_email"
    nzPopconfirmPlacement="bottomLeft"
    (nzOnConfirm)="emailPDF()"
  >
    Email PDF
  </button>
</ng-template>
