<nz-table
  #nestedTable
  [nzData]="dataForDisplay"
  [nzPageSize]="10"
  nzSize="default"
  [nzScroll]="{ x: '2000px' }"
>
  <thead>
    <tr>
      <th nzWidth="36px"></th>
      <th nzWidth="400px">SKU</th>
      <th>product_description</th>
      <th>quantity</th>
    </tr>
  </thead>

  <tbody>
    <ng-template #step2 ngFor let-data [ngForOf]="nestedTable.data">
      <tr>
        <td [(nzExpand)]="data.expanded"></td>
        <td>{{ data.sku }}</td>
        <td>
          {{ data.product_description }}
        </td>
        <td>
          {{ data.items.length }}
        </td>
      </tr>
      <tr [nzExpand]="data.expanded">
        <nz-table
          #innerTable
          [nzData]="data.items"
          nzSize="default"
          [nzScroll]="{ x: '3000px' }"
          [nzShowPagination]="false"
        >
          <thead>
            <tr>
              <th nzWidth="220px">buyer</th>
              <th nzWidth="220px">check_in_time</th>
              <th nzWidth="200px">committed_deal_id</th>
              <th nzWidth="220px">committed_deal_price</th>
              <th nzWidth="220px">created_at</th>
              <th>deal_id</th>
              <th>invoice</th>
              <th nzWidth="220px">packaged_product_id</th>
              <th>price</th>
              <th>product_name</th>
              <th>product_serial</th>
              <th>purchase-order</th>
              <th nzWidth="220px">sku</th>
              <th>tracking_number</th>
              <th>upc</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of innerTable.data">
              <th>{{ data.buyer }}</th>
              <th>{{ data.check_in_time | date: 'MMM d y, h:mm a' }}</th>
              <th>{{ data.committed_deal_id }}</th>
              <th>{{ data.committed_deal_price }}</th>
              <th>{{ data.created_at | date: 'MMM d y, h:mm a' }}</th>
              <th>{{ data.deal_id }}</th>
              <th>{{ data.invoice }}</th>
              <th>{{ data.packaged_product_id }}</th>
              <th>{{ data.price }}</th>
              <th>{{ data.product_name }}</th>
              <th>{{ data.product_serial }}</th>
              <th>{{ data.purchase_order }}</th>
              <th>{{ data.sku }}</th>
              <th>{{ data.tracking_number }}</th>
              <th>{{ data.upc }}</th>
            </tr>
          </tbody>
        </nz-table>
      </tr>
    </ng-template>
  </tbody>
</nz-table>
