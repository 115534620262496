import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

const BASE_URL = environment.baseUrl + '' + environment.apiUrl + '/admin';

@Injectable({
  providedIn: 'root'
})
export class AdminImportDataService {
  constructor(private http: HttpClient) {}

  importData(importDataType: 'products' | 'users' | 'packages', importData: string): Observable<any> {
    const url = BASE_URL + '/import-data';
    const headers = {
      'Content-Type': 'application/json'
    };

    const data = {
      importDataType,
      importData
    };

    return this.http.put<any>(url, data, { headers });
  }
}
