import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { AdminPurchaseOrdersService } from '../../admin/services/purchase-orders.service';

@Component({
  selector: 'oka-invoice-details-page',
  templateUrl: './invoice-details-page.component.html',
  styleUrls: ['./invoice-details-page.component.scss']
})
export class InvoiceDetailsPageComponent implements OnInit {
  // apiService: AdminPurchaseOrdersService;

  invoiceId: string;

  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router,
    private apiService: AdminPurchaseOrdersService,
    private nzMessageService: NzMessageService
  ) {
    // this.apiService = this.injector.get(AbstractApiService) as AdminPurchaseOrdersService;
  }

  ngOnInit(): void {
    this.invoiceId = this.activeRoute.snapshot.params.invoiceId;
    this.loadInvoice();
  }

  loadInvoice() {
    this.apiService.getInvoice(this.invoiceId).subscribe(
      (result) => {},
      (error) => {
        console.error('Error', error);
        // this.router.navigate(['../'], { relativeTo: this.activeRoute });
      }
    );
  }
}
