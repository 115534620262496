import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'jsonProp'
})
export class JsonPropertyPipe implements PipeTransform {
  transform(value: string): string {
    if (typeof value !== 'string') {
      console.warn('The pipe jsonProp expects a string. You gave: ', value);
      return value;
    }

    const words = value.split('_');
    const transformedName = words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');

    return transformedName;
  }
}
