<nz-header id="baseNav">
  <div class="logo"><a routerLink="/user/shadchan">Shadchan</a></div>
  <ul nz-menu nzTheme="dark" nzMode="horizontal">
    <ng-container *ngFor="let navBarItem of navBarMenu">
      <li
        nz-menu-item
        [routerLink]="['' + navBarItem]"
        routerLinkActive="active-link"
        *ngIf="!isMobileScreen()"
      >
        {{ formatForMenu(navBarItem) }}
      </li>
    </ng-container>
    <li
      style="float: right"
      nz-menu-item
      nzTooltipTitle="Logout"
      nzTooltipPlacement="bottom"
      nz-tooltip
    >
      <a (click)="signout()">
        <i
          style="margin: 0; font-size: 1.3rem"
          nz-icon
          nzType="logout"
          nzTheme="outline"
        ></i>
      </a>
    </li>
    <li style="float: right" nz-menu-item *ngIf="!isMobileScreen()">
      Hello, Shadchan
      <!-- <span class="username">{{ username }}</span> -->
    </li>
  </ul>
</nz-header>
