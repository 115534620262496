import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActivityFeedItem } from '../../../../../types/activity-feed';
import { DataSourceCommon } from '../../../common/infinite-list/data-source';
import { eventsCatalogue } from './activity-feed-catalogue';

interface ItemData {
  gender: string;
  name: Name;
  email: string;
}

interface Name {
  title: string;
  first: string;
  last: string;
}

/** @title Virtual scroll with a custom data source */
@Component({
  selector: 'app-events-report',
  templateUrl: './activity-feed-page.component.html',
  styleUrls: ['./activity-feed-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActivityFeedPageComponent {
  dataSource = new DataSourceCommon<ActivityFeedItem>('admin/activity-feed', this.http);

  appEventsCatalogue = eventsCatalogue;

  private destroy$ = new Subject();

  constructor(private http: HttpClient, private nzMessage: NzMessageService) {}

  ngOnInit(): void {
    this.dataSource
      .completed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.nzMessage.warning('Infinite List loaded all');
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
