import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { PurchaseOrder } from '../../../../../types/purchase-order';
import { BuyerApiService } from '../services/buyer.api.service';
import { BaseUserPage } from '../../user-page';

@Component({
  selector: 'app-buyer-purchase-orders',
  templateUrl: './buyer-purchase-orders-page.component.html',
  styleUrls: ['./buyer-purchase-orders-page.component.scss']
})
export class BuyerPurchaseOrdersPageComponent extends BaseUserPage implements OnInit {
  constructor(activatedRoute: ActivatedRoute, private buyerApiService: BuyerApiService) {
    super(activatedRoute);
  }

  purchaseOrders: PurchaseOrder[] = [];

  ngOnInit() {
    this.buyerApiService
      .getPurchaseOrders()
      .pipe(map((result) => result.data))
      .subscribe((purchaseOrders: PurchaseOrder[]) => {
        this.purchaseOrders = purchaseOrders;
      });
  }
}
