<SegmentedControl
  [selectedIndex]="entryModeIndex"
  [values]="['By Form', 'By Text']"
  (onChange)="onChangeEntryMode($event)"
></SegmentedControl>

<nz-page-header-title>New Profile</nz-page-header-title>

<ng-container *ngIf="isLoading">
  <ActivityIndicator
    [animating]="isLoading"
    [size]="'large'"
    class="activity-indicator"
  ></ActivityIndicator>
</ng-container>

<shdchn-profile-form-mobile
  *ngIf="!isLoading && entryMode === 'form'"
  [mode]="profileData ? 'edit' : 'new'"
  [profile]="profileData"
></shdchn-profile-form-mobile>

<ng-container *ngIf="!isLoading && entryMode === 'freetext'">
  <List [renderHeader]="'Enter a description of the person in natural English'">
    <div class="am-list-body">
      <div class="am-list-body">
        <TextareaItem
          [rows]="5"
          [count]="1000"
          [defaultValue]="'...'"
          [autoHeight]="true"
          [(ngModel)]="freetext"
        ></TextareaItem>
      </div>
    </div>
  </List>

  <WhiteSpace></WhiteSpace>

  <div Button [type]="'primary'" (onClick)="submitTextProfile()">Enter</div>
</ng-container>
