<nz-page-header>
  <nz-page-header-title>Purchase Orders</nz-page-header-title>
  <nz-page-header-subtitle></nz-page-header-subtitle>
  <nz-page-header-extra>
    <!-- <button nz-button (click)="exportData('csv')">Export as CSV</button> -->
    <!-- <button nz-button (click)="exportData('xls')">Export as XLS</button> -->
  </nz-page-header-extra>
</nz-page-header>

<nz-layout id="buyer-purchase-orders-page">
  <nz-content *ngIf="!purchaseOrders.length">
    <nz-row [nzGutter]="8">
      <nz-col>
        <div>
          <p>No purchase orders yet</p>
        </div>
      </nz-col>
    </nz-row>
  </nz-content>

  <ng-container *ngFor="let purchaseOrder of purchaseOrders">
    <oka-buyer-purchase-order-item [purchaseOrder]="purchaseOrder" nz-col [nzSpan]="8"></oka-buyer-purchase-order-item>
  </ng-container>
</nz-layout>
