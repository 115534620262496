<nz-layout id="buyer-page">
  <nz-content>
    <nz-card id="top-card" class="mb-5">
      <button
        *ngIf="!showNewTrackingNumberForm"
        nz-button
        nzType="primary"
        nzSize="default"
        [disabled]="showNewTrackingNumberForm"
        (click)="showNewTrackingNumberForm = true"
        (click)="showNewTrackingNumberInputArea()"
      >
        Add a New Tracking Number
      </button>
      <div *ngIf="showNewTrackingNumberForm">
        <form>
          <textarea
            rows="4"
            nz-input
            class="form-control mb-1"
            placeholder="One tracking number per line"
            #trackingNumbers
          ></textarea>

          <div nz-row [nzGutter]="24">
            <div nz-col [nzSpan]="24">
              <nz-space>
                <nz-space-item>
                  <button nz-button nzType="default" (click)="showNewTrackingNumberForm = false">Close</button>
                </nz-space-item>
                <nz-space-item>
                  <button
                    nz-button
                    nzType="primary"
                    (click)="addTrackingNumbers(trackingNumbers.value); trackingNumbers.value = ''"
                  >
                    Submit
                  </button>
                </nz-space-item>
              </nz-space>
            </div>
          </div>
        </form>
      </div>
    </nz-card>

    <nz-card nzTitle="Recent Tracking Numbers" [nzExtra]="extraTemplate">
      <!-- TODO update table -->
      <nz-table #trackingNumber [nzData]="trackingNumbers">
        <thead class="thead-light">
          <tr>
            <th scope="col">Tracking Number</th>
            <th scope="col">Submitted</th>
            <th scope="col">Checked In</th>
            <th scope="col" class="tn_action"></th>
            <!-- <th scope="col">Actions</th> -->
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let trackingNumber of trackingNumbers; let i = index">
            <tr>
              <td>{{ trackingNumber.tracking_number }}</td>
              <td>{{ trackingNumber.created_at | date: 'shortDate' }}</td>
              <td>{{ trackingNumber.check_in_time ? '✓' : '' }}</td>
              <!-- <td>{{ trackingNumber.check_in_time ? '✓' : '' }}</td> -->
              <td class="tn-actions">
                <i
                  (click)="deleteTrackingNumber(trackingNumber)"
                  nz-icon
                  nzType="delete"
                  nzTheme="outline"
                  [ngClass]="{ disabled: trackingNumber.check_in_time }"
                ></i>
              </td>
              <!-- <td nzRight>
              <a nz-button nzType="link" href="#" (click)="editTrackingNumber($event, i)" nz-tooltip nzTooltipTitle="Edit">
                <i class="fa fa-pencil" aria-hidden="true"></i>
              </a>

              <a nz-button nzType="text" nzDanger href="#" (click)="deleteTrackingNumber($event, i)" nz-tooltip nzTooltipTitle="Delete">
                <i class="fa fa-trash" aria-hidden="true"></i>
              </a>
            </td> -->
            </tr>
          </ng-container>
        </tbody>
      </nz-table>
    </nz-card>
    <ng-template #extraTemplate>
      <!-- <label nz-checkbox [(ngModel)]="showNonPending">Show Checked-In</label> -->
    </ng-template>
  </nz-content>
  <nz-sider id="deals-sider" class="ml-3" nzWidth="40%" nzTheme="light" nzBreakpoint="lg" [nzCollapsedWidth]="0">
    <ng-container *ngFor="let dealItem of deals; let i = index">
      <app-buyer-deal-item [dealItem]="dealItem" mode="available"></app-buyer-deal-item>
    </ng-container>
  </nz-sider>
</nz-layout>
