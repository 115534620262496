<ng-container *ngIf="focusedProfile && matchedProfile">
  <ListItem
    [thumb]="matchThumb"
    multipleLine
    [arrow]="'horizontal'"
    [platform]="'android'"
    (onClick)="onMatchClick()"
    [extra]="matchActionsExtra"
  >
    <p class="mb-0">
      {{ matchedProfile.first_name + ' ' + matchedProfile.last_name }}
    </p>
  </ListItem>
</ng-container>

<ng-template #matchThumb>
  <shdchn-match-card-thumb
    [focusedProfileImage]="matchedProfile.profile_photo_url"
    [matchedProfileImage]="focusedProfile.profile_photo_url"
  ></shdchn-match-card-thumb>
</ng-template>

<ng-template #matchActionsExtra>
  <ng-container *ngIf="activities && activities.length > 0">
    <Badge
      [text]="activities[0].match_action | jsonProp"
      [hot]="true"
      style="margin-left: 12px"
    ></Badge>
  </ng-container>
</ng-template>
