<ng-template #tpl>
  <form nz-form nzLayout="vertical" [formGroup]="updateUserForm">
    <nz-form-item nzBlock>
      <nz-form-label [nzSm]="8" [nzXs]="24" nzRequired>First Name</nz-form-label>
      <input nz-input placeholder="First Name" formControlName="firstName" />
    </nz-form-item>

    <nz-form-item nzBlock>
      <nz-form-label [nzSm]="8" [nzXs]="24" nzRequired>Last Name</nz-form-label>
      <input nz-input placeholder="Last Name" formControlName="lastName" />
    </nz-form-item>

    <nz-form-item nzBlock>
      <nz-form-label [nzSm]="8" [nzXs]="24" nzRequired>Password</nz-form-label>
      <input nz-input type="password" placeholder="Password" formControlName="password" />
    </nz-form-item>

    <nz-form-item nzBlock>
      <nz-form-label [nzSm]="8" [nzXs]="24" nzRequired>Confirm Password</nz-form-label>
      <input nz-input type="password" placeholder="Confirm Password" formControlName="confirmPassword" />
    </nz-form-item>
  </form>
</ng-template>
