import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AdminTrackingNumberReport } from '../../../../../types-frontend/admin-types';
import { AdminTrackingNumbersService } from '../../services/tracking-numbers.service';

@Component({
  selector: 'app-tracking-numbers',
  templateUrl: './tracking-numbers-page.component.html'
})
export class TrackingNumbersPageComponent implements OnInit {
  trackingNumberData$: BehaviorSubject<AdminTrackingNumberReport[]> = new BehaviorSubject([]);

  columnHeaders = [
    'tracking_number',
    'buyer',
    'created_at',
    'check_in_time',
    'number_of_products',
    'purchase_order',
    'quickbooks_po_url'
  ];

  constructor(private adminApiService: AdminTrackingNumbersService) {}

  ngOnInit() {
    this.adminApiService.getTrackingNumbersReport().subscribe((reportData) => {
      this.trackingNumberData$.next(reportData);
    });
  }

  exportAsCSV() {}
}
