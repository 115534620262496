<oka-infinite-list [dataSource]="dataSource">
  <ng-template let-item>
    <ng-container *ngIf="item">
      <div class="left-tab" [style.background-color]="appEventsCatalogue.get(item.did).color"></div>
      <nz-col nzSpan="4" style="padding-left: 26px"
        ><span
          ><a [routerLink]="['/user/admin/user-management/users/', item.who]">{{ item.who }}</a></span
        ></nz-col
      >
      <nz-col nzSpan="6"
        ><span class="did">{{ item.did | eventLogActionFormatted }}</span></nz-col
      >
      <nz-col nzSpan="4">{{ item.what }}</nz-col>
      <nz-col nzSpan="5"
        ><span *ngIf="item.value" class="log-item-value">{{ item.value }}</span></nz-col
      >
      <nz-col nzSpan="5">{{ item.timestamp | date: 'MM/d/y hh:mm' }}</nz-col>
    </ng-container>
  </ng-template>
</oka-infinite-list>
