import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'base-details-core-info',
  templateUrl: './base-details-core-info.component.html',
  styleUrls: ['../base-details-common.scss', './base-details-core-info.component.scss']
})
export class BaseDetailsCoreInfoComponent implements OnInit {
  @Input() coreInputTitle = '';
  @Input() formGroup!: FormGroup;
  @Input() nzSpan = 10;

  @Output() onResetFormData = new EventEmitter();
  @Output() onSubmitFormData = new EventEmitter();

  DATE_TIME_FORMAT = 'yyyy/MM/dd hh:mm';
  mode: 'read' | 'edit' = 'read';

  formGroupKeys: string[] = [];

  constructor() {}

  ngOnInit(): void {
    this.formGroupKeys = Object.keys(this.formGroup.controls);
    this.formGroup.disable();
  }

  isDate(controlName: string) {
    return controlName === 'start_time' || controlName === 'end_time';
    // const dateRegex1 = /(\d{4})([\/-])(\d{1,2})\2(\d{1,2})/;
    // const dateRegex2 = /^(Sun|Mon|Tue|Wed|Thu|Fri|Sat)\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{2}\s\d{4}/;
    // console.log('this.formGroup.controls', this.formGroup.controls);
    // const formValue = this.formGroup.controls[controlName].value;
    // return dateRegex1.test(formValue) || dateRegex2.test(formValue);
  }

  switchCoreInformationMode() {
    if (this.mode === 'read') {
      this.mode = 'edit';
      this.formGroup.enable();
    } else {
      this.mode = 'read';
      this.formGroup.disable();
    }
  }

  resetCoreDataForm() {
    this.onResetFormData.emit();
  }

  updateCoreData() {
    this.onSubmitFormData.emit();
  }
}
