import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './services/auth.service';

@Injectable()
export class CanActivateUserRoute implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const userState = this.authService.getUserState$().value;

    if (!userState) {
      this.router.navigateByUrl('');
      return false;
    }

    const userType = userState.userType;

    if (
      userType &&
      route.routeConfig.path !== userType

      // route.routeConfig.path !== userType &&
      // route.routeConfig.path !== userType + '-desktop'
    ) {
      this.router.navigateByUrl('user/' + userType);
    } else {
      return true;
    }
  }
}
