export type AdvancedSettingName = 'quickbooks_optional';

export interface AdvancedSetting {
  name: AdvancedSettingName;
  displayName: string;
  description: string;
  type: 'boolean' | 'string' | 'number';
}

export interface AdvancedSettingWithValue extends AdvancedSetting {
  value: boolean | string | number;
}
