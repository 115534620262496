<nz-card>
  <div class="top-card">
    <nz-select
      nzPlaceHolder="Select Buyer"
      nzAllowClear
      nzShowSearch
      nzServerSearch
      [(ngModel)]="buyerLookupValue"
      (nzOnSearch)="onSearch($event)"
      (ngModelChange)="loadBuyerReports()"
      style="width: 100%"
    >
      <ng-container *ngFor="let o of matchingBuyerListItems">
        <nz-option
          *ngIf="buyerList?.length > 0"
          [nzValue]="o.user_id"
          [nzLabel]="o.first_name + ' ' + o.last_name + ' / ' + o.user_id"
        ></nz-option>
      </ng-container>
      <nz-option *ngIf="buyerList?.length === 0" nzDisabled nzCustomContent>
        <i nz-icon nzType="loading" class="loading-icon"></i> Loading Data...
      </nz-option>
    </nz-select>
    <button nz-button (click)="exportData('csv')">Export as CSV</button>
  </div>
</nz-card>

<nz-divider></nz-divider>

<!-- Put buyer report table here -->
<ng-container *ngIf="rowData?.length > 0">
  <nz-table #rowSelectionTable nzShowPagination nzShowSizeChanger [nzData]="rowData" [nzScroll]="{ x: '2000px' }">
    <thead>
      <tr>
        <th nzLeft nzWidth="200px" [(nzSortOrder)]="columnDefs[0].sortOrder" [nzSortFn]="columnDefs[0].sortFn">
          Product Serial
        </th>
        <th
          *ngFor="let header of columnDefs | slice: 1; trackBy: trackByName"
          [(nzSortOrder)]="header.sortOrder"
          [nzSortFn]="header.sortFn"
          [nzSortDirections]="['ascend', 'descend']"
          [nzWidth]="header.width || '200px'"
        >
          {{ header.preferred_name || header.name }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of rowSelectionTable.data">
        <td nzLeft>{{ data.product_serial }}</td>
        <td>{{ data.upc }}</td>
        <td>{{ data.sku }}</td>
        <td>{{ data.tracking_number }}</td>
        <td>{{ data.created_at | date: 'EEEE MMM d y, h:mm a' }}</td>
        <td>{{ data.check_in_time | date: 'EEEE MMM d y, h:mm a' }}</td>
        <td>{{ data.purchase_order_id }}</td>
        <td>{{ data.check_number }}</td>
        <td>{{ data.product_name }}</td>
        <!-- <td>{{ data.product_description }}</td> -->
        <td>{{ data.price }}</td>
      </tr>
    </tbody>
  </nz-table>
</ng-container>

<nz-card *ngIf="rowData?.length === 0" style="margin-top: 1rem"> No checked-in items </nz-card>
