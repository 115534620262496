import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { of } from 'rxjs';
import { User } from '../../../../../../types/user';
import { ModalComponent } from '../../../../common/modal-common/modal.component';
import { confirmPasswordValidator } from '../../../../common/utils';
import { UserManagementService } from '../../services/user-management.service';

@Component({
  selector: 'app-update-user-modal',
  templateUrl: './update-user-modal.component.html',
  styleUrls: ['./update-user-modal.component.scss']
})
export class UpdateUserModalComponent extends ModalComponent implements OnInit, AfterViewInit {
  title = 'Update User';
  updateUserForm: FormGroup = this._formBuilder.group({});
  inputs: { user: User };

  constructor(
    nzModal: NzModalService,
    private nzMessageService: NzMessageService,
    private _formBuilder: FormBuilder,
    private userManagementService: UserManagementService
  ) {
    super(nzModal);
  }

  ngAfterViewInit(): void {
    this.openModal({}, () => {
      this.onUpdate(this.inputs?.user).subscribe(() => {
        this.closeModal();
      });
    });
  }

  ngOnInit(): void {
    this.updateUserForm = this._formBuilder.group({
      firstName: [this.inputs?.user.first_name, Validators.required],
      lastName: [this.inputs?.user.last_name, Validators.required],
      password: [undefined],
      confirmPassword: [undefined, [confirmPasswordValidator]]
    });
  }

  onUpdate(user: User) {
    if (this.updateUserForm.valid) {
      let password: string;
      let firstName: string;
      let lastName: string;

      if (this.updateUserForm.value.password) {
        password = this.updateUserForm.value.password;
      }

      if (user.first_name !== this.updateUserForm.value.firstName) {
        firstName = this.updateUserForm.value.firstName;
      }

      if (user.last_name !== this.updateUserForm.value.lastName) {
        lastName = this.updateUserForm.value.lastName;
      }

      const allInputFields = [password, firstName, lastName];
      if (allInputFields.every((element) => element === undefined)) {
        // No data has changed. Do not make any backend invokation
        return of({});
      }

      this.nzMessageService.success(`User ${user.username} Updated`);

      return this.userManagementService.updateUser(user.user_id, { password, firstName, lastName });
    } else {
      if (this.updateUserForm.errors) {
        this.nzMessageService.error('Invalid Form');
        return;
      }

      for (const controlName in this.updateUserForm.controls) {
        const control = this.updateUserForm.controls[controlName];
        if (control.errors) {
          this.nzMessageService.error(`${controlName} is invalid`);
          break;
        }
      }
    }
  }
}
