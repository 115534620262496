<nz-layout class="layout-outer">
  <!-- <app-nav-bar
    *ngIf="!isMobileScreen()"
    [navBarMenu]="childPages"
  ></app-nav-bar> -->
  <shdchn-nav-bar-mobile
    class="nav-bar"
    style="line-height: 0px"
    [navBarMenu]="childPages"
    *ngIf="isMobileScreen()"
  ></shdchn-nav-bar-mobile>

  <Drawer
    class="menu-drawer"
    [ngStyle]="{ minHeight: height + 'px' }"
    [contentStyle]="{
      color: '#A6A6A6',
    }"
    [sidebarStyle]="{ border: '1px solid #ddd' }"
    [sidebar]="navMenu"
    [enableDragHandle]="false"
    [docked]="isMenuOpen"
  >
    <nz-layout class="inner-layout">
      <nz-content>
        <div class="inner-content">
          <router-outlet></router-outlet>
        </div>
      </nz-content>
    </nz-layout>
  </Drawer>
  <nz-footer>Shadchan ©2024</nz-footer>
</nz-layout>

<ng-template #navMenu>
  <List>
    <ListItem [thumb]="'assets/icons/profiles.svg'" (click)="closeMenu()">
      <a routerLink="/user/admin/user-management"> User Management </a>
    </ListItem>
    <ListItem [thumb]="'assets/icons/sign-out.svg'" (click)="signOut()">
      <a> Sign Out </a>
    </ListItem>
  </List>
</ng-template>
