<nz-layout>
  <nz-content>
    <nz-card id="top-card" [ngClass]="{ offline: offlineMode }">
      OFFLINE MODE &nbsp; &nbsp;
      <nz-switch [ngModel]="offlineMode" (click)="offlineModeToggle($event)" [nzControl]="true"></nz-switch>
    </nz-card>

    <nz-layout class="mt-5">
      <nz-content>
        <nz-card nzTitle="Scan Packages" [nzExtra]="scanPackagesExtraButtons">
          <div nz-row [nzGutter]="2">
            <div nz-col nzSpan="24">
              <nz-input-group [nzSuffix]="trackingNumberInputSuffixTemplate">
                <input
                  nz-input
                  placeholder="Scan Tracking Number"
                  (ngModelChange)="trackingNumberAdded($event)"
                  [(ngModel)]="currentTrackingNumber"
                  (keydown.enter)="retrieveTrackingNumber(currentTrackingNumber)"
                />
              </nz-input-group>
            </div>
            <div nz-col nzSpan="24" *ngIf="showBuyerLookup" class="buyer-lookup-form">
              <nz-select
                nzPlaceHolder="Select Buyer"
                nzAllowClear
                nzShowSearch
                nzServerSearch
                [(ngModel)]="buyerLookupValue"
                (nzOnSearch)="onSearch($event)"
              >
                <ng-container *ngFor="let o of matchingBuyerListItems">
                  <nz-option
                    *ngIf="buyerList?.length > 0"
                    [nzValue]="o.user_id"
                    [nzLabel]="o.first_name + ' ' + o.last_name + ' / ' + o.user_id"
                  ></nz-option>
                </ng-container>
                <nz-option *ngIf="buyerList?.length === 0" nzDisabled nzCustomContent>
                  <i nz-icon nzType="loading" class="loading-icon"></i> Loading Data...
                </nz-option>
              </nz-select>
            </div>
          </div>

          <nz-input-group [hidden]="trackingNumberStatus === 'invalid' && !showBuyerLookup" class="upc-serial-form">
            <form nz-row [nzGutter]="2">
              <div nz-col nzSpan="10">
                <nz-input-group [nzSuffix]="currentProductUPCInputSuffixTemplate">
                  <input
                    #upcFormElement
                    type="text"
                    nz-input
                    [(ngModel)]="currentProductUPC"
                    name="currentProductUPC"
                    placeholder="Product UPC"
                    (change)="currentProductUPCIsValid = false"
                    (keydown.enter)="retrieveUPC(currentProductUPC)"
                    (keydown.tab)="retrieveUPC(currentProductUPC)"
                  />
                </nz-input-group>
              </div>
              <div nz-col nzSpan="10">
                <input
                  type="text"
                  nz-input
                  [(ngModel)]="currentProductSerial"
                  [disabled]="!currentProductUPCIsValid"
                  name="currentProductSerial"
                  #serialFormElement
                  placeholder="Product Serial"
                />
                <a
                  class="create-serial"
                  nz-popconfirm
                  nzPopconfirmTitle="This will print a new, unique serial. Print?"
                  (nzOnConfirm)="createCustomSerial()"
                  nzPopconfirmPlacement="top"
                >
                  <span> Create Serial Number </span>
                </a>
              </div>
              <div nz-col nzSpan="4">
                <button
                  type="submit"
                  nz-button
                  nzType="default"
                  nzBlock
                  (click)="addItem()"
                  [disabled]="!currentProductUPCIsValid"
                >
                  Add Item
                </button>
              </div>
            </form>
          </nz-input-group>

          <nz-divider></nz-divider>
          <ng-container *ngFor="let scannedItem of scannedItems; index as i">
            <app-scanned-upc-serial [item]="scannedItem" [index]="i"></app-scanned-upc-serial>
          </ng-container>

          <button
            class="complete-package"
            [ngClass]="{ active: scannedItems.length > 0 }"
            [disabled]="scannedItems.length === 0"
            nz-button
            nzType="primary"
            nzBlock
            (click)="completePackage()"
          >
            Complete Package
          </button>
        </nz-card>
      </nz-content>

      <nz-sider nzWidth="40%" nzTheme="light">
        <nz-card nzTitle="Recent Packages">
          <ng-container *ngFor="let recentPackage of recentPackages">
            <app-recent-scanned-packages [scannedPackage]="recentPackage" class="mt-3"></app-recent-scanned-packages>
          </ng-container>
        </nz-card>
      </nz-sider>
    </nz-layout>
  </nz-content>
</nz-layout>

<!-- ---------------- -->
<!-- EXTRAS -->
<!-- ---------------- -->

<ng-template #trackingNumberInputSuffixTemplate>
  <i
    *ngIf="trackingNumberStatus === 'never-checked-in'"
    nz-icon
    [nzType]="'check-circle'"
    [nzTheme]="'twotone'"
    [nzTwotoneColor]="'#52c41a'"
    nzTooltipTitle="Tracking Number is valid and has not been checked in"
  ></i>
  <i
    *ngIf="trackingNumberStatus === 'appending'"
    nz-icon
    [nzType]="'appstore-add'"
    [nzTheme]="'outline'"
    [nzTwotoneColor]="'#52c41a'"
    nzTooltipTitle="Appening to an already checked-in tracking number"
  ></i>
</ng-template>

<ng-template #currentProductUPCInputSuffixTemplate>
  <i
    *ngIf="currentProductUPCIsValid"
    nz-icon
    [nzType]="'check-circle'"
    [nzTheme]="'twotone'"
    [nzTwotoneColor]="'#52c41a'"
    nzTooltipTitle="Product UPC is valid"
  ></i>
</ng-template>

<nz-modal
  [nzFooter]="modalTrackingNumberErrorFooter"
  [(nzVisible)]="showTrackingNumberErrorModal"
  (nzOnCancel)="showTrackingNumberErrorModal = false"
  nzTitle="Non-Existent Tracking Number"
  nzCentered
>
  <ng-container *nzModalContent>
    <div class="big-x"><i nz-icon nzType="close" nzTheme="outline"></i></div>
    <div>
      <p>That tracking number could not be located in the system. It has never been submitted by a buyer.</p>
      <p>
        If you are certain that it has, you can try hard-refreshing this page and giving it another try. Otherwise
        contact support
      </p>
    </div>
  </ng-container>

  <ng-template #modalTrackingNumberErrorFooter>
    <button nz-button nzType="default" (click)="showTrackingNumberErrorModal = false">Ok</button>
  </ng-template>
</nz-modal>

<nz-modal
  [nzFooter]="modalTrackingNumberAlreadyCheckedInErrorFooter"
  [(nzVisible)]="showTrackingNumberAlreadyCheckedInErrorModal"
  (nzOnCancel)="showTrackingNumberAlreadyCheckedInErrorModal = false"
  nzTitle="Tracking Number Already Checked In"
  nzCentered
>
  <ng-container *nzModalContent><p>That tracking number was already checked in</p></ng-container>

  <ng-template #modalTrackingNumberAlreadyCheckedInErrorFooter>
    <button nz-button nzType="default" (click)="addMoreItemsToExistingTrackingNumber()">
      Add More Items To Package
    </button>
    <button nz-button nzType="primary" (click)="closeAlreadyCheckedInModal()">Ok</button>
  </ng-template>
</nz-modal>

<ng-template #scanPackagesExtraButtons>
  <a *ngIf="currentTrackingNumber" (click)="resetPage()">Clear</a>
</ng-template>

<nz-modal
  [nzFooter]="modalCurrentProductUPCErrorFooter"
  [(nzVisible)]="showCurrentProductUPCErrorModal"
  (nzOnCancel)="showCurrentProductUPCErrorModal = false"
  nzTitle="Non-Existent Product UPC"
  nzCentered
>
  <ng-container *nzModalContent>
    <div class="big-x"><i nz-icon nzType="close" nzTheme="outline"></i></div>
    <div>
      <p>That product upc could not be located in the system. It has never been created by the admin.</p>
      <p>
        If you are certain that it has, you can try hard-refreshing this page and giving it another try. Otherwise
        contact support
      </p>
    </div>
  </ng-container>

  <ng-template #modalCurrentProductUPCErrorFooter>
    <button nz-button nzType="default" (click)="showCurrentProductUPCErrorModal = false">Ok</button>
  </ng-template>
</nz-modal>

<!-- Used by JSBarCode -->
<div style="visibility: hidden">
  <div #barcode>
    <svg id="barcode"></svg>
  </div>
</div>

<div [hidden]="true">
  <audio #audioPlayer src="assets/error.mp3"></audio>
</div>
