import { Component, OnInit } from '@angular/core';
import { BuyerReportAll, BuyerItemReportFlat } from '../../../../../types-frontend/buyer';
import { NgZorroColumnHeaderInfo } from '../../../../../types-frontend/ngzorro-types';
import { numberCompare, stringCompare, dateCompare } from '../../../../common/utils';
import { UserManagementService } from '../../services/user-management.service';
import { AdminBuyerReportService } from '../../services/buyer-report.service';

@Component({
  selector: 'app-admin-buyer-reports-page',
  templateUrl: './admin-buyer-reports-page.component.html',
  styleUrls: ['./admin-buyer-reports-page.component.scss']
})
export class AdminBuyerReportsPageComponent implements OnInit {
  buyerLookupValue = '';
  buyerList: { user_id: number; first_name: string; last_name: string }[] = [];
  matchingBuyerListItems: { user_id: number; first_name: string; last_name: string }[] = [];

  columnDefs: NgZorroColumnHeaderInfo<BuyerReportAll>[] = [];

  rowData: BuyerItemReportFlat[] = [];

  constructor(
    private buyerReportApiService: AdminBuyerReportService,
    private userManagementService: UserManagementService
  ) {}

  ngOnInit(): void {
    this.generateColumnDefs();

    this.userManagementService.getBuyers().subscribe((bl) => {
      this.buyerList = bl;
    });
  }

  onSearch(value: string) {
    const valueLowercase = value.toLocaleLowerCase();
    this.matchingBuyerListItems = this.buyerList.filter(
      (i) =>
        i.user_id.toString().toLocaleLowerCase().indexOf(valueLowercase) >= 0 ||
        i.first_name.toLocaleLowerCase().indexOf(valueLowercase) >= 0 ||
        i.last_name.toLocaleLowerCase().indexOf(valueLowercase) >= 0
    );
  }

  loadBuyerReports() {
    if (this.buyerLookupValue) {
      this.buyerReportApiService.getPackagedProductsReport(this.buyerLookupValue).subscribe(
        (data: BuyerReportAll[]) => {
          this.rowData = data;
        },
        (error: any) => {
          console.log(error);
        }
      );
    } else {
      this.rowData = [];
    }
  }

  exportData(format: 'csv' | 'xls') {
    const rowHeaders = this.columnDefs.map((cd) => cd.name); //.join(',');

    const rowDataWithBlankFields: Omit<BuyerReportAll, 'description'>[] = this.rowData.map((data) => {
      return {
        product_serial: (data as any).product_serial || '',
        upc: (data as any).upc || '',
        sku: (data as any).sku || '',
        tracking_number: (data as any).tracking_number || '',
        created_at: (data as any).created_at || '',
        check_in_time: (data as any).check_in_time || '',
        purchase_order_id: (data as any).purchase_order_id || '',
        check_number: (data as any).check_number || '',
        product_name: (data as any).product_name || '',
        // description: (data as any).description || '',
        price: (data as any).price || ''
      };
    });

    const rows = [rowHeaders, ...rowDataWithBlankFields.map((rd) => Object.values(rd))];
    let csvContent = 'data:text/csv;charset=utf-8,' + rows.map((e) => e.join(',')).join('\n');
    var encodedUri = encodeURI(csvContent);
    window.open(encodedUri);
  }

  generateColumnDefs() {
    this.columnDefs = [
      { name: 'product_serial', sortFn: stringCompare<BuyerReportAll>('product_serial') },
      { name: 'upc', preferred_name: 'UPC', ...sortDetails('upc') },
      { name: 'sku', preferred_name: 'SKU', ...sortDetails('sku') },
      { name: 'tracking_number', ...sortDetails('tracking_number') },
      { name: 'created_at', width: '280px', preferred_name: 'Created At', ...sortDetails('created_at', 'date') },
      {
        name: 'check_in_time',
        width: '280px',
        preferred_name: 'Checked In At',
        ...sortDetails('check_in_time', 'date')
      },
      { name: 'purchase_order_id' },
      { name: 'check_number' },
      { name: 'product_name' },
      // { name: 'description', preferred_name: 'product_description', width: '460px' },
      // { name: 'unit_cost', sortFn: numberCompare<BuyerReportAll>('unit_cost') }
      { name: 'price', sortFn: numberCompare<BuyerReportAll>('price') }
    ];
  }

  getSortOrder(column: any) {
    if (column && column.sortOrder) {
      return column.sortOrder;
    } else {
      return null;
    }
  }
}

function sortDetails(
  propName: keyof BuyerReportAll,
  propType: 'string' | 'number' | 'date' = 'string',
  sortOrder = 'ascend'
) {
  return {
    sortFn:
      propType === 'string'
        ? stringCompare<BuyerReportAll>(propName)
        : propType === 'number'
        ? numberCompare<BuyerReportAll>(propName)
        : dateCompare<BuyerReportAll>(propName),
    sortOrder
  };
}
