import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { ApplicationPagesShadchanEnum } from '../../common/constants';
import { RegisterPageComponent } from './auth/register-page/register-page.component';
import { SignInPageComponent } from './auth/sign-in-page/sign-in-page.component';
import { VerifyPageComponent } from './auth/verify-page/verify-page.component';
import { CanActivateUserRoute } from './can-activate-route.service';
import { AdminBaseComponent } from './user-pages/admin/admin-base.component';
import { AdminHomePageComponent } from './user-pages/admin/admin-home-page/admin-home-page.component';
import { UserDetailsPageComponent } from './user-pages/admin/user-details-page/user-details-page.component';
import { UserManagementPageComponent } from './user-pages/admin/user-management-page/user-management-page.component';
import { MatchPageMobileComponent } from './user-pages/shadchan/match-page-mobile/match-page-mobile.component';
import { NewProfileMobileComponent } from './user-pages/shadchan/new-profile-mobile/new-profile-mobile.component';
import { ProfilePageMobileComponent } from './user-pages/shadchan/profile-page-mobile/profile-page-mobile.component';
import { ProfilesPageMobileComponent } from './user-pages/shadchan/profiles-page-mobile/profiles-page-mobile.component';
import { ShadchanBaseMobileComponent } from './user-pages/shadchan/shadchan-base-mobile/shadchan-base-mobile.component';
import { TehillimPageComponent } from './user-pages/shadchan/tehillim-page/tehillim-page.component';
import { UserPagesComponent } from './user-pages/user-pages.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { ForgotPasswordPageComponent } from './auth/forgot-password-page/forgot-password-page.component';

export interface RouteCustom extends Route {
  hideFromNavBar?: boolean;
  children?: RoutesCustom;
}
export type RoutesCustom = RouteCustom[];

const routes: RoutesCustom = [
  {
    path: 'sign-in',
    component: SignInPageComponent
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordPageComponent
  },
  {
    path: 'register',
    component: RegisterPageComponent
  },
  {
    path: 'verify',
    component: VerifyPageComponent
  },

  // {
  //   path: "demo",
  //   component: DemoPageComponent,
  // },
  {
    path: 'user',
    component: UserPagesComponent,
    children: [
      {
        path: 'shadchan',
        component: ShadchanBaseMobileComponent,
        canActivate: [CanActivateUserRoute],
        children: [
          {
            // "home"
            path: ApplicationPagesShadchanEnum.home,
            component: ProfilesPageMobileComponent
          },
          {
            // "profile/:profileId",
            path: ApplicationPagesShadchanEnum.profile + '/:profileId',
            component: ProfilePageMobileComponent
          },
          {
            // "match/:matchId",
            path: 'match/:matchId',
            component: MatchPageMobileComponent
          },
          {
            // "new"
            path: ApplicationPagesShadchanEnum.new,
            component: NewProfileMobileComponent
          },
          {
            // "tehillim"
            path: ApplicationPagesShadchanEnum.tehillim,
            component: TehillimPageComponent
          },
          {
            path: '**',
            redirectTo: 'home',
            pathMatch: 'full',
            hideFromNavBar: true
          }
        ]
      },
      {
        path: 'admin',
        component: AdminBaseComponent,
        canActivate: [CanActivateUserRoute],
        children: [
          {
            path: 'home',
            component: AdminHomePageComponent
          },
          {
            path: 'user-management',
            component: UserManagementPageComponent
          },
          {
            path: 'user-management/users/:username',
            component: UserDetailsPageComponent
          }
        ]
      },
      {
        path: '',
        component: WelcomeComponent,
        pathMatch: 'full'
      }
    ]
  },
  {
    path: '',
    component: SignInPageComponent
  },
  {
    path: '**',
    redirectTo: '/'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [CanActivateUserRoute]
})
export class AppRoutingModule {}
