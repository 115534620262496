<nz-page-header [nzGhost]="false">
  <nz-page-header-title>Purchase Order #{{ purchaseOrderId }}</nz-page-header-title>
  <nz-page-header-subtitle *ngIf="poDetails?.created_at"
    >Created on {{ poDetails?.created_at | date }}</nz-page-header-subtitle
  >
</nz-page-header>

<div class="po-info-row">
  <nz-card nzTitle="Buyer Info">
    <div *ngIf="buyerDetails">
      <p><b>Username</b>: {{ buyerDetails.username }}</p>
      <p><b>Name: </b>{{ buyerDetails.first_name + ' ' + buyerDetails.last_name }}</p>
      <p><b>Email:</b> {{ buyerDetails.email || '(none)' }}</p>
      <p><b>Phone Number:</b> {{ buyerDetails.phone_number || '(none)' }}</p>
    </div>
  </nz-card>

  <nz-card nzTitle="Tracking Number(s)">
    <ng-container *ngFor="let trackingNumber of trackingNumbers">
      <p>
        {{ trackingNumber[0] }} <em *ngIf="trackingNumber[1] > 1">({{ trackingNumber[1] }})</em>
      </p>
    </ng-container>
  </nz-card>

  <nz-card nzTitle="Quickbooks" [nzExtra]="quickbooksExtraButtons">
    <p>Name: {{ poDetails?.name }}</p>
    <p>Notes: {{ poDetails?.notes }}</p>
    <p>Total: {{ poDetails?.calculated_total | currency }}</p>
    <p *ngIf="poDetails?.quickbooks_po_id && poDetails?.quickbooks_po_url">
      Quickbooks PO ID:
      <a href="{{ poDetails?.quickbooks_po_url }}" target="_blank">
        {{ poDetails?.quickbooks_po_id }}
      </a>
    </p>
    <p *ngIf="poDetails?.quickbooks_po_create_at">
      Quickbooks PO Generation Date: {{ poDetails?.quickbooks_po_create_at | date }}
    </p>
  </nz-card>

  <nz-card nzTitle="PDF" [nzExtra]="pdfExtraButtons">
    <div *ngIf="pdf && pdf.pdf">
      <a (click)="openPDF(pdf.pdf)">View PDF<i nz-icon nzType="expand-alt" nzTheme="outline"></i></a>
    </div>
    <div *ngIf="pdf && pdf.emailed_at">
      <p>Emailed at {{ pdf.emailed_at | date }}</p>
    </div>
  </nz-card>
</div>

<nz-page-header [nzGhost]="false" style="margin-top: 1rem">
  <nz-page-header-title>Products</nz-page-header-title>
  <nz-page-header-subtitle>({{ poItems.length }} total)</nz-page-header-subtitle>
</nz-page-header>

<ng-container *ngFor="let poItem of poItems">
  <oka-purchase-order-product-item [purchaseOrderItem]="poItem"></oka-purchase-order-product-item>
</ng-container>

<!-- ------ -->
<!-- EXTRAS -->
<!-- ------ -->
<ng-template #quickbooksExtraButtons>
  <button
    nz-button
    nzType="default"
    (click)="createQuickbooksPO()"
    [disabled]="poDetails?.quickbooks_po_id || poItems.length === 0"
    (click)="resetPage()"
  >
    Create In Quickbooks
  </button>
</ng-template>

<ng-template #pdfExtraButtons>
  <button nz-button nzType="default" (click)="viewPDF()">View PDF</button>
  <button
    *ngIf="buyerDetails"
    nz-button
    nzType="default"
    nz-popconfirm
    [nzPopconfirmTitle]="'This will email the purchase order to ' + buyerDetails.email"
    nzPopconfirmPlacement="bottomLeft"
    (nzOnConfirm)="emailPDF()"
  >
    Email PDF
  </button>
</ng-template>
