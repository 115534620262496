<nz-card nzTitle="{{ purchaseOrderItem.product_name }}" class="product-card">
  <div *ngIf="purchaseOrderItem.product_primary_photo" style="float: left; margin-right: 25px">
    <img width="272" alt="product-image" [src]="purchaseOrderItem.product_primary_photo" />
  </div>
  <div class="price">
    <p>{{ purchaseOrderItem?.price | currency }}</p>
  </div>
  <div>
    <p>UPC: {{ purchaseOrderItem?.upc }}</p>
    <p>SKU: {{ purchaseOrderItem?.sku }}</p>
    <p>Serial Number: {{ purchaseOrderItem?.product_serial }}</p>
    <p>Description: {{ purchaseOrderItem?.product_description }}</p>
    <p>Tracking Number: {{ purchaseOrderItem?.tracking_number }}</p>
    <p>Check In Time: {{ purchaseOrderItem?.check_in_time | date: 'MMM d y, h:mm a' }}</p>
  </div>
</nz-card>
