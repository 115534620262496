import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { AdminTrackingNumberReport } from '../../../../types-frontend/admin-types';
import { AbstractApiService } from '../../../services/abstract-api.service';

@Injectable({
  providedIn: 'root'
})
export class AdminTrackingNumbersService extends AbstractApiService {
  adminApiUrl = environment.baseUrl + environment.apiUrl + '/admin';

  constructor(private http: HttpClient) {
    super();
  }

  getTrackingNumbersReport() {
    return this.http.get<AdminTrackingNumberReport[]>(this.adminApiUrl + '/tracking-numbers', {
      headers: this.headers
    });
  }
}
