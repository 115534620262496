import { Component, Injector, OnInit } from '@angular/core';
import { AdminTrackingNumberReport } from '../../../types-frontend/admin-types';
import { AbstractOkaTable } from '../abstract-oka-table';

@Component({
  selector: 'oka-tracking-numbers-table',
  templateUrl: './tracking-numbers-table.component.html',
  styleUrls: ['../oka-tables-styles.scss']
})
export class TrackingNumbersTableComponent extends AbstractOkaTable<AdminTrackingNumberReport> implements OnInit {
  currentSearchColumn: keyof AdminTrackingNumberReport = 'tracking_number';

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
