<nz-layout class="layout-outer" *ngIf="!isMobileScreen()">
  <app-nav-bar
    *ngIf="!isMobileScreen()"
    [navBarMenu]="childPages"
  ></app-nav-bar>
  <nz-sider
    nzCollapsible
    nzBreakpoint="lg"
    [nzCollapsedWidth]="0"
    *ngIf="isMobileScreen()"
  >
    <div class="logo">
      <a routerLink="/user/shadchan">ShadchanApp</a>
    </div>
    <ul nz-menu nzTheme="dark" nzMode="inline">
      <li nz-menu-item (click)="newProfile()">
        <span nz-icon nzType="user"></span>
        <span>New Profile</span>
      </li>
    </ul>
  </nz-sider>

  <nz-layout>
    <nz-content>
      <div class="inner-content">
        <router-outlet></router-outlet>
      </div>
    </nz-content>
    <nz-footer>Shadchan ©2024</nz-footer>
  </nz-layout>
</nz-layout>

<nz-layout class="layout-outer" *ngIf="isMobileScreen()">
  <Drawer
    class="menu-drawer"
    [ngStyle]="{ minHeight: height + 'px' }"
    [contentStyle]="{
      color: '#A6A6A6',
    }"
    [sidebarStyle]="{ border: '1px solid #ddd' }"
    [sidebar]="navMenuMobile"
    [enableDragHandle]="false"
    [docked]="isMenuOpen"
  >
    <shdchn-nav-bar-mobile
      class="nav-bar"
      style="line-height: 0px"
      *ngIf="isMobileScreen()"
      [actionPopover]="actionPopover"
    ></shdchn-nav-bar-mobile>

    <div
      style="border-bottom: 1px solid #ddd"
      *ngIf="showSearchBar || showGenderToggleBar"
    >
      <shdchn-search-bar-mobile
        *ngIf="showSearchBar"
      ></shdchn-search-bar-mobile>
      <shdchn-gender-toggle-mobile
        *ngIf="showGenderToggleBar"
      ></shdchn-gender-toggle-mobile>
    </div>

    <nz-layout class="inner-layout">
      <nz-content class="inner-content">
        <router-outlet></router-outlet>
      </nz-content>

      <nz-footer>Shadchan ©2024</nz-footer>
    </nz-layout>
  </Drawer>
</nz-layout>

<ng-template #navMenuMobile>
  <div class="welcome-user">Welcome {{ username }}</div>
  <List>
    <ListItem
      [thumb]="'assets/icons/new-user.svg'"
      routerLink="/user/shadchan/new"
      (click)="closeMenu()"
    >
      <a> New Profile </a>
    </ListItem>
    <ListItem
      [thumb]="'assets/icons/profiles.svg'"
      routerLink="/user/shadchan"
      (click)="closeMenu()"
    >
      <a> Profiles </a>
    </ListItem>
    <ListItem
      [thumb]="'assets/icons/tehillim.svg'"
      routerLink="/user/shadchan/tehillim"
      [disabled]="disabled"
      (click)="closeMenu()"
    >
      <a> Tehillim List </a>
    </ListItem>
    <ListItem [thumb]="'assets/icons/sign-out.svg'" (click)="signOut()">
      <a>Sign Out</a>
    </ListItem>
  </List>
  <p class="version-info">Version {{ version }}</p>
</ng-template>
