import { ActivatedRoute } from '@angular/router';
import { RouteCustom, RoutesCustom } from '../app-routing.module';

export class BaseUserPage {
  activatedRoute: ActivatedRoute;
  routeConfig: RouteCustom;
  childPages: string[];

  constructor(activatedRoute: ActivatedRoute) {
    this.activatedRoute = activatedRoute;
  }

  setChildPages() {
    if (!this.activatedRoute.snapshot.routeConfig.children) {
      this.activatedRoute.snapshot.routeConfig.children = [];
    }

    this.routeConfig = this.activatedRoute.snapshot.routeConfig;
    this.childPages = this.routeConfig.children
      .filter((i) => !i.hideFromNavBar)
      .filter((i) => i.path.length > 0)
      .filter((i) => i.path.indexOf('/') < 0)
      .map((i) => i.path);
  }
}
