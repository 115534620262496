import { User } from '../../types/user';

export const usersMock: User[] = [
  {
    first_name: 'John',
    last_name: 'Smith',
    username: '',
    user_id: 1,
    user_type: 'buyer'
  }
];
