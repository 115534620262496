import { ActivatedRoute, Router } from '@angular/router';
import { RouteCustom, RoutesCustom } from '../app-routing.module';
import { Injector } from '@angular/core';
import { BasePage } from '../user-pages/page';
import { FormGroup } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { ToastService } from 'ng-zorro-antd-mobile';

export type AuthPageName =
  | 'sign-in'
  | 'register'
  | 'verify'
  | 'forgot-password'
  | 'forgot-username';

export abstract class AuthPage extends BasePage {
  errorMsg = '';
  authForm: FormGroup;

  authService: AuthService;
  router: Router;
  toastSvc: ToastService;

  constructor(protected injector: Injector) {
    super(injector);
    this.router = injector.get(Router);
    this.authService = injector.get(AuthService);
    this.toastSvc = injector.get(ToastService);
  }

  goToRegistrationPage() {
    this.router.navigate(['../register'], {
      relativeTo: this.router.routerState.root
    });
  }

  goToSignInPage() {
    this.router.navigate(['../sign-in'], {
      relativeTo: this.router.routerState.root
    });
  }

  goTo = (authPageName: AuthPageName) => {
    this.router.navigate([`../${authPageName}`], {
      relativeTo: this.router.routerState.root
    });
  };

  abstract submit();
}
