<nz-layout>
  <nz-content class="content-page">
    <nz-page-header>
      <nz-page-header-title>New Profile</nz-page-header-title>
      <nz-page-header-subtitle></nz-page-header-subtitle>
      <nz-page-header-extra> </nz-page-header-extra>
    </nz-page-header>

    <form (ngSubmit)="submitProfileForm()" [formGroup]="createNewProfileForm">
      <nz-form-item nzBlock>
        <nz-form-label [nzSm]="8" [nzXs]="24" nzRequired>Gender</nz-form-label>
        <nz-radio-group formControlName="gender">
          <label nz-radio nzValue="male">Man</label>
          <label nz-radio nzValue="female">Woman</label>
        </nz-radio-group>
      </nz-form-item>

      <nz-form-item nzBlock>
        <nz-form-label [nzSm]="8" [nzXs]="24" nzRequired
          >First Name</nz-form-label
        >
        <input nz-input placeholder="First Name" formControlName="first_name" />
      </nz-form-item>

      <nz-form-item nzBlock>
        <nz-form-label [nzSm]="8" [nzXs]="24" nzRequired
          >Last Name</nz-form-label
        >
        <input nz-input placeholder="Last Name" formControlName="last_name" />
      </nz-form-item>

      <nz-form-item nzBlock>
        <nz-form-label [nzSm]="8" [nzXs]="24" nzRequired
          >Date of Birth</nz-form-label
        >
        <nz-form-control [nzSm]="16" [nzXs]="24">
          <nz-date-picker
            nzFormat="yyyy-MM-dd"
            nzMode="date"
            nzPlacement="bottomLeft"
            formControlName="date_of_birth"
          ></nz-date-picker>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item nzBlock>
        <nz-form-label>City</nz-form-label>
        <input nz-input placeholder="City" formControlName="city" />
      </nz-form-item>

      <nz-form-item nzBlock>
        <nz-form-label>Country</nz-form-label>
        <input nz-input placeholder="Country" formControlName="country" />
      </nz-form-item>

      <nz-form-item nzBlock>
        <nz-form-label>Is College Educated</nz-form-label>
        <nz-radio-group formControlName="is_college_educated">
          <label nz-radio nzValue="true">Yes</label>
          <label nz-radio nzValue="false">No</label>
        </nz-radio-group>
      </nz-form-item>

      <nz-form-item nzBlock>
        <nz-form-label>Marital Status</nz-form-label>
        <nz-radio-group formControlName="marital_status">
          <ng-container *ngFor="let status of maritalStatus">
            <label nz-radio [nzValue]="status">{{ status | titlecase }}</label>
          </ng-container>
        </nz-radio-group>
      </nz-form-item>

      <nz-form-item nzBlock>
        <nz-form-label>Occupation</nz-form-label>
        <input nz-input placeholder="Occupation" formControlName="occupation" />
      </nz-form-item>

      <div class="mb-3">
        <label class="form-label">Religious Categories</label>
        <div *ngFor="let category of religiousCategories" class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            [value]="category"
            id="category-{{ category }}"
            name="religious_categories"
            (change)="
              updateReligiousCategories(category, $event.target.checked)
            "
            checked
          />
          <label class="form-check-label" for="category-{{ category }}">{{
            category
          }}</label>
        </div>
      </div>

      <div class="clearfix" style="margin-top: 1rem">
        <nz-upload
          [nzAction]="fileUploadUrl"
          nzFileType="image/png,image/bmp,image/jpeg,image/gif"
          nzListType="picture-card"
          [(nzFileList)]="fileList"
          [nzShowUploadList]="{
            showPreviewIcon: true,
            showRemoveIcon: true,
            showDownloadIcon: true
          }"
          [nzShowButton]="fileList.length < 4"
          [nzPreview]="handlePreview"
          (nzChange)="handleImageUploadResponse($event)"
        >
          <div>
            <span nz-icon nzType="plus"></span>
            <div style="margin-top: 8px">Upload</div>
          </div>
        </nz-upload>
        <nz-modal
          [nzVisible]="previewVisible"
          [nzContent]="modalContent"
          [nzFooter]="null"
          (nzOnCancel)="previewVisible = false"
        >
          <ng-template #modalContent>
            <img [src]="previewImage" [ngStyle]="{ width: '100%' }" />
          </ng-template>
        </nz-modal>
      </div>

      <nz-form-item nzBlock>
        <nz-form-label>Comment</nz-form-label>

        <textarea
          formControlName="description"
          nz-input
          rows="3"
          placeholder="Description"
        ></textarea>
      </nz-form-item>

      <nz-form-item>
        <nz-form-control>
          <button
            nz-button
            nzType="primary"
            [nzLoading]="isSubmittingNewProfileForm"
          >
            Submit
          </button>
        </nz-form-control>
      </nz-form-item>
    </form>
  </nz-content>
</nz-layout>
