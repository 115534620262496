<nz-layout class="outer-layout">
  <nz-sider nzWidth="200px" nzTheme="light">
    <ul class="reports-nav" nz-menu nzTheme="dark" nzMode="inline" class="sider-menu">
      <li nz-menu-item>
        <i nz-icon nzType="inbox"></i>
        <span><a [routerLink]="['tracking-numbers']" routerLinkActive="router-link-active">Tracking Numbers</a></span>
      </li>
      <li nz-menu-item>
        <i nz-icon nzType="dollar"></i>
        <span><a [routerLink]="['purchase-orders']" routerLinkActive="router-link-active">Purchase Orders</a></span>
      </li>
      <li nz-menu-item>
        <i nz-icon nzType="dollar"></i>
        <span><a [routerLink]="['invoices']" routerLinkActive="router-link-active">Invoices</a></span>
      </li>
      <li nz-menu-item>
        <i nz-icon nzType="barcode"></i>
        <span><a [routerLink]="['inventory']" routerLinkActive="router-link-active">Inventory</a></span>
      </li>
      <li nz-menu-item>
        <i nz-icon nzType="user"></i>
        <span><a [routerLink]="['buyer-reports']" routerLinkActive="router-link-active">Buyer Reports</a></span>
      </li>
      <li nz-menu-item>
        <i nz-icon nzType="user"></i>
        <span><a [routerLink]="['custom-queries']" routerLinkActive="router-link-active">Custom Queries</a></span>
      </li>

      <!-- <li nz-submenu nzOpen nzIcon="user" nzTitle="Custom Queries">
        <ul>
          <li nz-menu-item nzSelected>
            <span
              ><a [routerLink]="['custom-queries/buyers-owed-a-purchase-order']" routerLinkActive="router-link-active"
                >Buyers owed a purchase order</a
              ></span
            >
          </li>
          <li nz-menu-item>Unscanned Tracking Numbers</li>
        </ul>
      </li> -->
    </ul>
  </nz-sider>
  <nz-layout class="inner-layout">
    <router-outlet></router-outlet>
  </nz-layout>
</nz-layout>
