import { Clipboard } from '@angular/cdk/clipboard';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavBarEventService } from '../../../services/nav-bar-event.service';
import { ToastService } from 'ng-zorro-antd-mobile';
import { Subscription } from 'rxjs';
import { ShadchanService } from '../services/shadchan.service';
import { Profile } from '../../../../../types/profile';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'shdchn-tehillim-page',
  templateUrl: './tehillim-page.component.html',
  styleUrl: './tehillim-page.component.scss'
})
export class TehillimPageComponent implements OnInit, OnDestroy {
  isLoading = false;
  profiles: Profile[] = [];
  profilesFiltered: Profile[] = [];

  // Subscriptions
  navBarServiceSubscription: Subscription;

  constructor(
    private navBarService: NavBarEventService,
    private shadchanService: ShadchanService,
    private toastService: ToastService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private clipboard: Clipboard
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.shadchanService.getProfiles().subscribe((p) => {
      this.profiles = p;
      this.profilesFiltered = p;
      this.isLoading = false;
    });

    this.navBarServiceSubscription =
      this.navBarService.navBarEventEmitter.subscribe((e) => {
        if (e.type === 'copy') {
          this.copyTehillimList();
        }
      });
  }

  ngOnDestroy() {
    this.navBarServiceSubscription.unsubscribe();
  }

  copyTehillimList() {
    const tehillimNames = this.profilesFiltered
      .filter((p) => p.tehillim_name)
      .map((profile) => profile.tehillim_name)
      .join('\n');
    this.clipboard.copy(tehillimNames);
    this.toastService.info('Tehillim List Copied');
  }

  goToProfile(profileId: number) {
    this.router.navigate(['../profile/' + profileId], {
      relativeTo: this.activatedRoute
    });
  }
}
