<nz-table
  #rowSelectionTable
  nzShowPagination
  nzShowSizeChanger
  [nzData]="dataForDisplay"
  nzTableLayout="auto"
  nzPageSize="1000"
  [nzScroll]="{ x: '2000px' }"
>
  <thead>
    <tr>
      <th
        *ngFor="let columnHeaderDef of columnDefs"
        [nzCustomFilter]="columnHeaderDef.headerName"
        [nzSortDirections]="['ascend', 'descend']"
        [nzSortFn]="columnHeaderDef.sortFn"
      >
        {{ columnHeaderDef.headerName }}
        <nz-filter-trigger
          [nzVisible]="searchValues[columnHeaderDef.headerName].visible"
          (nzVisibleChange)="searchValuesOpened($event, columnHeaderDef.headerName)"
          [nzActive]="searchValues[columnHeaderDef.headerName].value.length > 0"
          [nzDropdownMenu]="menu"
        >
          <i nz-icon nzType="search"></i>
        </nz-filter-trigger>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of rowSelectionTable.data">
      <td>{{ data.packaged_product_id }}</td>
      <td>{{ data.product_name }}</td>
      <td>{{ data.product_serial }}</td>
      <td nzEllipsis>{{ data.product_description }}</td>
      <td>{{ data.buyer }}</td>
      <td>{{ data.tracking_number }}</td>
      <td>
        <a [routerLink]="['../../', 'products', data.upc]">{{ data.upc }}</a>
      </td>
      <td>{{ data.sku }}</td>
      <td>{{ data.committed_deal_id }}</td>
      <td>
        <a [routerLink]="['../../', 'deals', data.deal_id]">{{ data.deal_id }}</a>
      </td>
      <td>{{ data.committed_deal_price | currency }}</td>
      <td>{{ data.price | currency }}</td>
      <td>{{ data.check_in_time | date: 'MMM d y, h:mm a' }}</td>
      <td>{{ data.created_at | date: 'MMM d y, h:mm a' }}</td>
    </tr>
  </tbody>
</nz-table>

<!-- TODO: Move to super class -->
<nz-dropdown-menu #menu="nzDropdownMenu">
  <div class="ant-table-filter-dropdown">
    <div class="search-box">
      <input type="text" nz-input placeholder="Search name" [(ngModel)]="searchValues[currentSearchColumn].value" />
      <button nz-button nzSize="small" nzType="primary" (click)="searchColumn()" class="search-button">Search</button>
      <button nz-button nzSize="small" (click)="resetSearchColumn()">Reset</button>
    </div>
  </div>
</nz-dropdown-menu>
