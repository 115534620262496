<nz-layout>
  <nz-content>
    <nz-row class="page-header" nzJustify="space-between">
      <nz-col
        ><h2 class="mb-0" style="display: inline">Inventory</h2>
        <nz-switch
          [ngModel]="inStock"
          (ngModelChange)="onStockToggle($event)"
          nzCheckedChildren="In Stock"
          nzUnCheckedChildren="All"
        ></nz-switch>
      </nz-col>
      <nz-col class="center-col-v"> </nz-col>

      <nz-col class="center-col-v">
        <nz-radio-group [(ngModel)]="documentCreationMode">
          <label nz-radio nzDisabled nzValue="purchase_order">Purchase Order</label>
          <label nz-radio nzDisabled nzValue="invoice">Invoice</label>
        </nz-radio-group>
        <div>
          <a nz-dropdown [nzDropdownMenu]="menu">
            Group By
            <i nz-icon nzType="down"></i>
          </a>
          <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu>
              <li nz-menu-item (click)="groupInventoryBy('none')">None</li>
              <li nz-menu-item (click)="groupInventoryBy('sku')">SKU</li>
            </ul>
          </nz-dropdown-menu>
        </div>
      </nz-col>

      <nz-col class="center-col-v">
        <button [disabled]="draftPurchaseOrder.length === 0" class="header-menu-btn" nz-button nzType="default">
          Clear Selections
        </button>
        <!-- <button class="header-menu-btn" nz-button nzType="primary" (click)="showPurchaseOrderDrawer()">
          Show Purchase Order
        </button> -->
        <button class="header-menu-btn" nz-button nzType="primary" (click)="showInvoiceCreationDrawer()">
          Show Invoice Creation
        </button>
      </nz-col>
    </nz-row>

    <oka-inventory-table [data$]="inventoryData" [columnHeaders]="inventoryHeaders"></oka-inventory-table>

    <button class="btn btn-info" (click)="load()">Load More</button>
  </nz-content>
</nz-layout>
