import { GenderEnum, Profile } from '../../../types/profile';

export function formatProfile(profile: Profile): string {
  let formattedProfile = '';

  formattedProfile += `Gender: ${getGenderText(profile.gender)}\n`;

  if (profile.first_name) {
    formattedProfile += `First Name: ${profile.first_name}\n`;
  }
  if (profile.last_name) {
    formattedProfile += `Last Name: ${profile.last_name}\n`;
  }
  if (profile.date_of_birth) {
    formattedProfile += `Date of Birth: ${getFormattedDate(
      profile.date_of_birth
    )}\n`;
  }
  if (profile.city) {
    formattedProfile += `City: ${profile.city}\n`;
  }
  if (profile.country) {
    formattedProfile += `Country: ${profile.country}\n`;
  }
  if (profile.occupation) {
    formattedProfile += `Occupation: ${profile.occupation}\n`;
  }
  if (profile.description) {
    formattedProfile += `Description: ${profile.description}\n`;
  }
  if (profile.marital_status) {
    formattedProfile += `Marital Status: ${profile.marital_status}\n`;
  }
  // TODO fix issues with formatting from DB
  // if (profile.religious_seeking) {
  //   formattedProfile += `Religious Seeeking: ${profile.religious_seeking
  //     .map(getReligiousCategoryText)
  //     .join(', ')}\n`;
  // }

  return formattedProfile;
}

function getGenderText(gender: GenderEnum): string {
  return gender === GenderEnum.male ? 'Male' : 'Female'; // Assuming GenderEnum has values Male and Female
}

function getFormattedDate(date: Date | string): string {
  if (typeof date === 'string') {
    date = new Date(date);
  }
  return date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
}
