<Navbar
  [leftContent]="popover"
  [rightContent]="rightContent"
  (onLeftClick)="onMenuClick()"
>
  <a class="logo" routerLink="/user/shadchan">SHDCHN</a>
</Navbar>

<ng-template #icon>
  <Icon [type]="'left'"></Icon>
</ng-template>

<ng-template #rightContent>
  <Icon
    *ngIf="showCopyButton"
    [src]="'assets/icons/copy.svg'"
    (click)="onCopy()"
    [ngStyle]="{ marginRight: '16px', cursor: 'pointer' }"
  ></Icon>

  <Icon
    [type]="'search'"
    [ngStyle]="{ marginRight: '16px', cursor: 'pointer' }"
    (click)="onSearch()"
    *ngIf="showSearchButton"
  ></Icon>

  <common-sort-popover [show]="showSortButton"></common-sort-popover>

  <ng-container *ngTemplateOutlet="actionPopover"></ng-container>
</ng-template>

<ng-template #popover>
  <Icon
    [ngStyle]="{
      height: '100%',
      display: 'flex',
      'align-items': 'center',
    }"
    [src]="'assets/icons/burger.svg'"
    [placement]="'bottomLeft'"
    (onSelect)="onMenuClick($event)"
    (onVisibleChange)="onVisibleChange($event)"
  ></Icon>
</ng-template>

<ng-template #newuserIcon>
  <Icon [src]="'assets/icons/new-user.svg'" [size]="'xs'"></Icon>
</ng-template>

<ng-template #profilesIcon>
  <Icon [src]="'assets/icons/profiles.svg'" [size]="'xs'"></Icon>
</ng-template>

<ng-template #tehillimIcon>
  <Icon [src]="'assets/icons/tehillim.svg'" [size]="'xs'"></Icon>
</ng-template>
