import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

const BASE_URL = environment.baseUrl + '' + environment.apiUrl + '/admin';

@Injectable({
  providedIn: 'root'
})
export class AdminBuyerReportService {
  constructor(private http: HttpClient) {}

  getPackagedProductsReport(buyerId: string): Observable<any> {
    const url = BASE_URL + `/get-packaged-products-report/${buyerId}`;
    const headers = {
      'Content-Type': 'application/json'
    };

    return this.http.get<any>(url, { headers: headers });
  }
}
