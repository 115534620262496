import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgzorroCommonModule } from '../../common/ngzorro-common.module';
import { OkaCommonModule } from '../../common/oka-common.module';
import { OkaTablesModule } from '../../tables/oka-tables.module';
import { RequestPaymentModalModule } from '../admin/modals/request-payment-modal/request-payment-modal.module';
import { BuyerPaymentsPageComponent } from './buyer-payments-page/buyer-payments-page.component';
import { BuyerPurchaseOrderDetailsComponent } from './buyer-purchase-order-details/buyer-purchase-order-details.component';
import { BuyerPurchaseOrderItemComponent } from './buyer-purchase-order-item/buyer-purchase-order-item.component';
import { BuyerPurchaseOrdersPageComponent } from './buyer-purchase-orders-page/buyer-purchase-orders-page.component';

@NgModule({
  declarations: [
    BuyerPurchaseOrdersPageComponent,
    BuyerPurchaseOrderItemComponent,
    BuyerPurchaseOrderDetailsComponent,
    BuyerPaymentsPageComponent
  ],
  imports: [
    RouterModule,
    CommonModule,
    NgzorroCommonModule,

    // Oka Modules
    OkaCommonModule,
    OkaTablesModule,

    // Modals
    RequestPaymentModalModule
  ],
  exports: []
})
export class BuyerModule {}
