import { Component, Input, OnInit } from '@angular/core';
import { Product } from '../../../../../../types/product';

@Component({
  selector: 'app-admin-product-item',
  templateUrl: './admin-product-item.component.html',
  styleUrls: ['./admin-product-item.component.scss']
})
export class AdminProductItemComponent implements OnInit {
  @Input() product!: Product;

  theKeys = Object.keys;

  constructor() {}

  ngOnInit(): void {}
}
