import { EventEmitter, Injectable, TemplateRef } from '@angular/core';

export type NavBarEventType =
  | 'action' // ellipsses popover
  | 'back'
  | 'copy'
  | 'search'
  | 'menu'
  | 'set';

export type NavBarEvent = {
  type: NavBarEventType;
  data?: any;
};

@Injectable({
  providedIn: 'root'
})
export class NavBarEventService {
  navBarEventEmitter = new EventEmitter<NavBarEvent>();

  actionPopover: TemplateRef<any>;

  constructor() {}

  action() {
    this.navBarEventEmitter.emit({ type: 'action' });
  }

  back() {
    this.navBarEventEmitter.emit({ type: 'back' });
  }

  copy() {
    this.navBarEventEmitter.emit({ type: 'copy' });
  }

  search() {
    this.navBarEventEmitter.emit({ type: 'search' });
  }

  setActionPopover(actionPopoverTemplateRef: TemplateRef<any>) {
    this.actionPopover = actionPopoverTemplateRef;
    this.navBarEventEmitter.emit({ type: 'set', data: this.actionPopover });
  }

  menu(_data?: any, _action?: 'close' | 'open') {
    this.navBarEventEmitter.emit({ type: 'menu' });
  }
}
