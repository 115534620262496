import { Component } from '@angular/core';
import { GenderToggleService } from '../../services/gender-toggle.service';

@Component({
  selector: 'shdchn-gender-toggle-mobile',
  templateUrl: './gender-toggle-mobile.component.html',
  styleUrl: './gender-toggle-mobile.component.scss'
})
export class GenderToggleMobileComponent {
  genderModeIndex = 0;
  genderMode: 'male' | 'female' | 'all' = 'all';

  constructor(private genderToggleService: GenderToggleService) {}

  onChangeGenderMode(event: { selectedIndex: number; value: string }) {
    if (event.value === 'Men') {
      this.genderMode = 'male';
    } else if (event.value === 'Women') {
      this.genderMode = 'female';
    } else if (event.value === 'All') {
      this.genderMode = 'all';
    }

    this.genderToggleService.emitGenderToggleEvent({
      category: this.genderMode
    });
  }
}
