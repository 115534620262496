import { Injectable } from '@angular/core';

export interface Cacheable {
  refreshCache();
}

export interface CacheStore {
  [key: string]: any;
}

@Injectable()
export class CacheService {
  cache: CacheStore = {};

  constructor() {}

  addToCache(key: string, data: any) {
    this.cache[key] = data;
  }

  retrieveFromCache(key: string) {
    if (this.cache[key]) {
      return this.cache[key];
    }
    return null;
  }

  clearCache(key: string) {
    delete this.cache[key];
  }
}
