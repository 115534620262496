import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NzUploadChangeParam, NzUploadFile } from 'ng-zorro-antd/upload';
import { ShadchanService } from '../services/shadchan.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'shchn-new-profile',
  templateUrl: './new-profile.component.html',
  styleUrl: './new-profile.component.scss'
})
export class NewProfileComponent implements OnInit {
  createNewProfileForm: FormGroup;
  religiousCategories: string[] = [
    'Yeshivishe',
    'Modern',
    'Modern-Machmir',
    'Chasidishe'
  ]; // Add more religious categories as needed

  maritalStatus: string[] = ['single', 'widowed', 'divorced'];

  fileUploadUrl =
    environment.baseUrl + environment.apiUrl + '/shadchan/image-upload';
  fileList: NzUploadFile[] = [];
  previewImage: string | undefined = '';
  previewVisible = false;
  filePublicIds: string[] = [];

  isSubmittingNewProfileForm = false;

  selectedReligiousCategories = [...this.religiousCategories];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private apiService: ShadchanService
  ) {}

  ngOnInit(): void {
    this.createNewProfileForm = this.formBuilder.group({
      gender: ['', Validators.required],
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      date_of_birth: [''],
      city: [''],
      country: [''],
      occupation: [''],
      is_college_educated: ['false'],
      description: [''],
      marital_status: ['']
    });
  }

  handlePreview = async (file: NzUploadFile): Promise<void> => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj!);
    }
    this.previewImage = file.url || file.preview;
    this.previewVisible = true;
  };

  submitProfileForm() {
    // Handle form submission here
    // Combine with file list
    const formData = {
      ...this.createNewProfileForm.value,
      is_college_educated:
        this.createNewProfileForm.value['is_college_educated'].toString(),
      religious_categories: this.selectedReligiousCategories,
      photos: this.filePublicIds
    };

    this.apiService.createNewProfile(formData).subscribe(
      () => {
        this.router.navigate(['../home'], { relativeTo: this.activatedRoute });
      },
      (error: Error) => {
        console.error('Unable to create new profile');
        console.error(error);
      }
    );
  }

  updateReligiousCategories(category: string, isChecked: boolean) {
    if (isChecked) {
      if (!this.selectedReligiousCategories.includes(category)) {
        this.selectedReligiousCategories.push(category);
      }
    } else {
      if (this.selectedReligiousCategories.includes(category)) {
        const index = this.selectedReligiousCategories.indexOf(category);
        if (index !== -1) {
          this.selectedReligiousCategories.splice(index, 1);
        }
      }
    }
  }

  handleImageUploadResponse(event: NzUploadChangeParam) {
    if (event.file.status === 'done') {
      const uploadResponse = event.file.response;
      const data = uploadResponse.data;
      for (const item of data) {
        this.filePublicIds.push(item);
      }
    }
  }
}

const getBase64 = (file: File): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
