import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RowNode, SelectionChangedEvent } from 'ag-grid-community';
import { PurchaseOrderForAccountantColumnHeaders } from '../../../../../types/purchase-order';
import { ColumnDefinitions } from '../../../../types-frontend/aggrid-types';
import { currencyFormatter, dateFormatter } from '../../../common/utils';
import { AccountantApiService } from '../services/accountant.service';

@Component({
  selector: 'app-accountant-purchase-order-reports-page',
  templateUrl: './accountant-purchase-order-reports-page.component.html',
  styleUrls: ['./accountant-purchase-order-reports-page.component.scss']
})
export class AccountantPurchaseOrderReportsPageComponent implements OnInit {
  rowData = [];
  columnDefs: ColumnDefinitions<PurchaseOrderForAccountantColumnHeaders>[] = [];

  constructor(
    private accountantApiService: AccountantApiService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.generateColumnDefs();
    this.loadPurchaseOrders();
  }

  loadPurchaseOrders() {
    this.accountantApiService.getPurchaseOrders().subscribe((data) => {
      this.rowData = data;
    });
  }

  private generateColumnDefs() {
    const columnDefsOptions = {
      sortable: true,
      filter: true,
      resizable: true
    };
    this.columnDefs.push({
      headerName: 'id',
      field: 'id',
      width: 60,
      ...columnDefsOptions
    });
    this.columnDefs.push({
      headerName: 'PO Name',
      field: 'name',
      ...columnDefsOptions
    });
    this.columnDefs.push({
      headerName: 'Buyer Name',
      field: 'username',
      ...columnDefsOptions
    });
    this.columnDefs.push({
      headerName: 'created_at',
      field: 'created_at',
      valueFormatter: dateFormatter,
      ...columnDefsOptions
    });
    this.columnDefs.push({
      headerName: 'calculated_total',
      field: 'calculated_total',
      valueFormatter: currencyFormatter,
      ...columnDefsOptions
    });
    const columnHeaders: PurchaseOrderForAccountantColumnHeaders[] = ['notes', 'products_count'];
    for (const fieldName of columnHeaders) {
      this.columnDefs.push({
        headerName: fieldName,
        field: fieldName,
        ...columnDefsOptions
      });
    }
  }

  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }

  rowDoubleClicked(event: RowNode) {
    this.router.navigate([event.data.id], { relativeTo: this.route });
  }

  onSelectionChanged(event: SelectionChangedEvent) {}
}
