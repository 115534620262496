import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef
} from '@angular/core';
import { NavBarEventService } from '../../services/nav-bar-event.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription, filter } from 'rxjs';
import { PageNavigationService } from '../../services/page-navigation.service';
import {
  ApplicationPages,
  ApplicationPagesShadchanEnum
} from '../../../../common/constants';

@Component({
  selector: 'shdchn-nav-bar-mobile',
  templateUrl: './nav-bar-mobile.component.html',
  styleUrl: './nav-bar-mobile.component.scss'
})
export class NavBarMobileComponent implements OnInit, OnDestroy {
  @Input() actionPopover: TemplateRef<any>;

  height: number = document.documentElement.clientHeight;
  state = {
    open: false
  };

  showCopyButton = false;
  showSearchButton = true;
  showSortButton = true;
  showMenu = false;

  // Current Page
  currentPage: ApplicationPages = ApplicationPagesShadchanEnum.profile;

  // Subscriptions
  pageNavServiceSubscription: Subscription;

  constructor(
    private navbarService: NavBarEventService,
    private pageNavigationService: PageNavigationService
  ) {}

  ngOnInit(): void {
    this.pageNavServiceSubscription =
      this.pageNavigationService.pageNavigationEvent$.subscribe(
        (pageNavEvent) => {
          this.setNavBarButtons(pageNavEvent.data);
        }
      );
  }

  ngOnDestroy(): void {
    this.pageNavServiceSubscription.unsubscribe();
  }

  onCopy() {
    this.navbarService.copy();
  }

  onLeftClick() {
    this.showMenu = true;
  }

  onMenuClick() {
    this.navbarService.menu();
  }

  onSearch() {
    this.navbarService.search();
  }

  onSelect(event) {}

  onVisibleChange(event) {}

  openMenuDrawer(event) {
    this.state.open = !this.state.open;
  }

  private setNavBarButtons(pageName: ApplicationPages) {
    this.showSearchButton = pageName === 'profiles';
    this.showSortButton = pageName === 'profiles';
    this.showCopyButton = pageName === 'tehillim';
  }
}
