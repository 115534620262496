<nz-layout id="login-layout">
  <nz-layout>
    <nz-content>
      <nz-card id="authForm" [nzExtra]="extraTemplate">
        <ActivityIndicator
          [animating]="isLoading"
          [size]="'large'"
          class="center-middle"
          *ngIf="isLoading"
        ></ActivityIndicator>
        <nz-alert
          *ngIf="errorMsg"
          nzType="error"
          [nzMessage]="errorMsg"
          [@animate]="{ value: '*', params: { y: '100%' } }"
          @animateOut
        ></nz-alert>

        <ng-content></ng-content>
      </nz-card>
    </nz-content>
  </nz-layout>
</nz-layout>

<ng-template #extraTemplate>
  <i
    style="font-size: 3rem"
    nz-icon
    nzType="lock"
    nzTheme="twotone"
    [@animate]="{ value: '*', params: { y: '-100%' } }"
  ></i>
</ng-template>
