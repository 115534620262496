import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgzorroCommonModule } from '../../common/ngzorro-common.module';
import { OkaCommonModule } from '../../common/oka-common.module';
import { PurchaseOrderDetailsPageComponent } from './purchase-order-details-page/purchase-order-details-page.component';
import { InvoiceDetailsPageComponent } from './invoice-details-page/invoice-details-page.component';

@NgModule({
  declarations: [PurchaseOrderDetailsPageComponent, InvoiceDetailsPageComponent],
  imports: [CommonModule, OkaCommonModule, NgzorroCommonModule]
})
export class NonBuyerSharedModule {}
