import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ModalComponent } from '../../../../common/modal-common/modal.component';

@Component({
  selector: 'app-turn-offline-mode-off-modal',
  templateUrl: './turn-offline-mode-off-modal.component.html'
})
export class TurnOfflineModeOffModalComponent extends ModalComponent implements OnInit, AfterViewInit {
  title = 'Exit Offline Mode';
  @ViewChild('tpl') public content: TemplateRef<any>;

  constructor(nzModal: NzModalService) {
    super(nzModal);
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.openModal({}, () => {
      this.modal.close(true);
    });
  }
}
