import { Component, Injector, OnInit } from '@angular/core';
import { PackagedProductInventoryItem } from '../../../../types/packaged-product';
import { AbstractOkaTable } from '../abstract-oka-table';

@Component({
  selector: 'oka-inventory-table',
  templateUrl: './inventory-table.component.html',
  styleUrls: ['../oka-tables-styles.scss']
})
export class InventoryTableComponent extends AbstractOkaTable<PackagedProductInventoryItem> implements OnInit {
  currentSearchColumn: keyof PackagedProductInventoryItem = 'product_serial';

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
