import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzTableModule } from 'ng-zorro-antd/table';
import { ActiveDealsTablesComponent } from './active-deals-table/active-deals-table.component';
import { CustomerInvoicesTableComponent } from './customer-invoices-table/customer-invoices-table.component';
import { AdminInventoryTableComponent } from './inventory-table/admin-inventory-table.component';
import { InventoryTableComponent } from './inventory-table/inventory-table.component';
import { AdminPaymentsTableComponent } from './payments-table/admin-payments-table.component';
import { ProcessedPaymentsTableComponent } from './payments-table/processed-payments-table.component';
import { RequestedPaymentsTableComponent } from './payments-table/requested-payments-table.component';
import { PurchaseOrdersTablesComponent } from './purchase-orders-tables/purchase-orders-tables.component';
import { TrackingNumbersTableComponent } from './tracking-numbers-table/tracking-numbers-table.component';
import { UsersTableComponent } from './users-table/users-table.component';

@NgModule({
  declarations: [
    ActiveDealsTablesComponent,
    AdminPaymentsTableComponent,
    CustomerInvoicesTableComponent,
    InventoryTableComponent,
    AdminInventoryTableComponent,
    UsersTableComponent,
    TrackingNumbersTableComponent,
    ProcessedPaymentsTableComponent,
    RequestedPaymentsTableComponent,
    PurchaseOrdersTablesComponent
  ],
  imports: [
    CommonModule,
    NzButtonModule,
    NzDropDownModule,
    NzIconModule.forChild([]),
    NzInputModule,
    NzTableModule,
    FormsModule,
    RouterModule
  ],
  exports: [
    ActiveDealsTablesComponent,
    AdminPaymentsTableComponent,
    CustomerInvoicesTableComponent,
    InventoryTableComponent,
    AdminInventoryTableComponent,
    UsersTableComponent,
    TrackingNumbersTableComponent,
    PurchaseOrdersTablesComponent,
    ProcessedPaymentsTableComponent,
    RequestedPaymentsTableComponent
  ]
})
export class OkaTablesModule {}
