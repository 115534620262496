<auth-common-template-shell [errorMsg]="errorMsg" [isLoading]="isLoading">
  <form
    nz-form
    (submit)="signIn(username.value, password.value)"
    [@animateStagger]="{ value: '100' }"
  >
    <nz-form-item [@animate]="{ value: '*', params: { y: '100%' } }">
      <nz-form-control nzErrorTip="Please Enter A Valid Username!">
        <nz-input-group nzPrefixIcon="user" nzSize="large">
          <input
            id="login"
            name="username"
            #username
            nz-input
            placeholder="Username"
          />
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item [@animate]="{ value: '*', params: { y: '100%' } }">
      <nz-form-control nzErrorTip="Please input your correct password">
        <nz-input-group nzPrefixIcon="lock" nzSize="large">
          <input
            id="password"
            #password
            nz-input
            type="password"
            placeholder="Password"
          />
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
    <auth-common-button
      [@animate]="{ value: '*', params: { y: '100%' } }"
      [buttonText]="'Sign In'"
      [links]="['register', 'forgot-password']"
    ></auth-common-button>
  </form>
</auth-common-template-shell>
