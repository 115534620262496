import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseUserPage } from '../user-page';

@Component({
  selector: 'app-buyer-base',
  templateUrl: `buyer-base.component.html`,
  styleUrls: ['buyer-base.component.scss']
})
export class BuyerBaseComponent extends BaseUserPage implements OnInit {
  childPages: string[];

  constructor(activatedRoute: ActivatedRoute) {
    super(activatedRoute);
  }

  ngOnInit(): void {
    this.setChildPages();
  }
}
