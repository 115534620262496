import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NgzorroCommonModule } from '../../../common/ngzorro-common.module';

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    NgzorroCommonModule,
    NzFormModule,
    NzDatePickerModule,
    NzInputNumberModule,
    NzMessageModule,
    NzSelectModule,
  ],
  declarations: [],
  exports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    NgzorroCommonModule,
    NzFormModule,
    NzDatePickerModule,
    NzInputNumberModule,
    NzMessageModule,
    NzSelectModule,
  ],
})
export class ModalBaseModule {}
