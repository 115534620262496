import { NgModule } from '@angular/core';
import { OkaModalService } from '../../../../common/modal-common/oka-modals.service';
import { TurnOfflineModeOnModalComponent } from './turn-offline-mode-on-modal.component';

@NgModule({
  imports: [],
  declarations: [TurnOfflineModeOnModalComponent],
  exports: [TurnOfflineModeOnModalComponent],
})
export class TurnOfflineModeOnModalModule {
  constructor(private modalNavigationService: OkaModalService) {}
}
