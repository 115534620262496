<ng-template #tpl>
  <form nz-form nzLayout="vertical" [formGroup]="createNewProductForm">
    <div class="modal-body">
      <nz-form-item nzBlock>
        <nz-select
          nzPlaceHolder="Select Product Category"
          nzAllowClear
          nzShowSearch
          nzServerSearch
          (nzOnSearch)="onSearch($event)"
          formControlName="product_category"
        >
          <ng-container *ngFor="let cat of categoryList">
            <nz-option *ngIf="!isLoadingCategories" [nzValue]="cat.id" [nzLabel]="cat.name"></nz-option>
          </ng-container>
          <nz-option *ngIf="isLoadingCategories" nzDisabled nzCustomContent>
            <i nz-icon nzType="loading" class="loading-icon"></i> Loading Data...
          </nz-option>
        </nz-select>
      </nz-form-item>

      <nz-form-item nzBlock>
        <nz-form-label [nzSm]="8" [nzXs]="24" nzRequired>UPC</nz-form-label>
        <input nz-input formControlName="upc" />
      </nz-form-item>

      <nz-form-item nzBlock>
        <nz-form-label [nzSm]="8" [nzXs]="24" nzRequired>SKU</nz-form-label>
        <input nz-input formControlName="sku" />
      </nz-form-item>

      <nz-form-item nzBlock>
        <nz-form-label [nzSm]="8" [nzXs]="24" nzRequired>Product Description</nz-form-label>
        <textarea rows="4" nz-input formControlName="product_description"></textarea>
      </nz-form-item>

      <nz-form-item nzBlock>
        <nz-form-label [nzSm]="8" [nzXs]="24">Product Photo URL</nz-form-label>
        <input nz-input formControlName="product_primary_photo" />
      </nz-form-item>
    </div>
  </form>
</ng-template>
