import { NgModule } from '@angular/core';
import { ModalBaseModule } from '../modals.module';
import { RequestPaymentModalComponent } from './request-payment-modal.component';

@NgModule({
  imports: [ModalBaseModule],
  declarations: [RequestPaymentModalComponent],
  exports: [RequestPaymentModalComponent],
})
export class RequestPaymentModalModule {}
