<ng-template #tpl>
  <form nz-form nzLayout="vertical" [formGroup]="chequeIdGenerationForm">
    <div class="modal-body">
      <nz-form-item nzBlock>
        <nz-form-label [nzXs]="24" nzRequired>Initial Cheque ID</nz-form-label>
        <nz-input-number style="width: 100%" formControlName="chequeId"></nz-input-number>
      </nz-form-item>
    </div>
  </form>
</ng-template>
