<nz-card>
  <div class="top-card">
    <nz-select
      nzPlaceHolder="Select Data Type"
      nzAllowClear
      nzShowSearch
      nzServerSearch
      [(ngModel)]="importDataType"
      (ngModelChange)="onImportDataTypeChange($event)"
      style="width: 100%"
    >
      <ng-container>
        <nz-option nzValue="products" nzLabel="Products"></nz-option>
        <nz-option nzValue="users" nzLabel="Users"></nz-option>
        <nz-option nzValue="packages" nzLabel="Packages"></nz-option>
      </ng-container>
    </nz-select>
  </div>
</nz-card>

<nz-card>
  <p>
    <span *ngFor="let header of csvHeaders">
      <i>{{ header }}</i
      ><b style="font-weight: 900">,</b>
    </span>
  </p>
  <textarea rows="30" nz-input [(ngModel)]="importData" placeholder="Paste CSV here"></textarea>
</nz-card>

<nz-card>
  <button nz-button nzType="primary" (click)="submit()" [disabled]="!importData || !importDataType">
    Submit button
  </button>
</nz-card>
