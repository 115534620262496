import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class QuickbooksService {
  apiUrl = environment.baseUrl + environment.apiUrl;

  constructor(private http: HttpClient) {}

  generatePurchaseOrder(data: any): Observable<any> {
    const url = this.apiUrl + '/purchase-order';
    const headers = {};

    return this.http
      .post<any>(url, data, { headers: headers })
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }
    return throwError('Unable to process the request');
  }
}
