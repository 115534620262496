import { NgModule } from '@angular/core';
import { ModalBaseModule } from '../modals.module';
import { UpdateUserModalComponent } from './update-user-modal.component';

@NgModule({
  imports: [ModalBaseModule],
  declarations: [UpdateUserModalComponent],
  exports: [UpdateUserModalComponent],
})
export class UpdateUserModalModule {}
