import { Component, Input, OnInit } from '@angular/core';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';

@Component({
  selector: 'app-purchase-order-drawer',
  templateUrl: './purchase-order-drawer.component.html',
  styleUrls: ['./purchase-order-drawer.component.scss']
})
export class PurchaseOrderDrawerComponent implements OnInit {
  @Input() buyer = '';
  @Input() items: any[] = [];

  priceTotal = 0;

  selectedPurchaseOrder: string;
  optionList = [];
  isLoading = false;

  constructor(private drawerRef: NzDrawerRef<string>) {}

  ngOnInit(): void {
    this.priceTotal = this.items.reduce((acc, i) => acc + i.price, 0);
  }

  close(): void {
    this.drawerRef.close('Confirm');
  }

  onSearch(event: any) {}
}
