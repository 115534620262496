import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import md5 from 'md5';
import { NzMessageService } from 'ng-zorro-antd/message';
import { SerialUPCPair } from '../../../../../types/product';
import { PackageForFrontend } from '../../../../types-frontend/package';
import { OkaModalService } from '../../../common/modal-common/oka-modals.service';
import { TurnOfflineModeOffModalComponent } from '../modals/turn-offline-mode-off-modal/turn-offline-mode-off-modal.component';
import { TurnOfflineModeOnModalComponent } from '../modals/turn-offline-mode-on-modal/turn-offline-mode-on-modal.component';
import { IndexDBService, storeNameType } from '../services/indexdb.service';
import { WarehouseApiService } from '../services/warehouse.service';
import { BaseUserPage } from './../../user-page';
declare var JsBarcode: any;

type TrackingNumberStatus = 'never-checked-in' | 'appending' | 'invalid';
@Component({
  selector: 'app-warehouseagent-home-page',
  templateUrl: './warehouse-homepage.component.html',
  styleUrls: ['./warehouse-homepage.component.scss']
})
export class WarehouseHomepageComponent extends BaseUserPage implements OnInit {
  @ViewChild('upcFormElement') upcFormElement: ElementRef;
  @ViewChild('serialFormElement') serialFormElement: ElementRef;
  @ViewChild('barcode') barcode: ElementRef;
  @ViewChild('audioPlayer') audioPlayer: ElementRef<HTMLAudioElement>;

  showTrackingNumberErrorModal = false;
  showTrackingNumberAlreadyCheckedInErrorModal = false;
  showCurrentProductUPCErrorModal = false;
  // trackingNumberIsValid = false;
  // isAppendingToTrackingNumber = false;
  trackingNumberStatus: TrackingNumberStatus = 'invalid';
  currentProductUPCIsValid = false;

  buyerLookupValue = '';
  showBuyerLookup = false;
  buyerList: { user_id: number; first_name: string; last_name: string }[] = [];
  matchingBuyerListItems: { user_id: number; first_name: string; last_name: string }[] = [];

  offlineMode = false;
  minimumRecentPackagesLimit = 3;
  currentRecentPackagesLimit = this.minimumRecentPackagesLimit;

  noOfProducts = 0;
  noOfSerialNumbers = 0;

  recentPackages: PackageForFrontend[] = [];
  scannedItems: SerialUPCPair[] = [];

  currentTrackingNumber = '';
  currentProductUPC = '';
  currentProductSerial = '';

  storeName: storeNameType = 'packages';

  validateForm!: FormGroup;

  constructor(
    activatedRoute: ActivatedRoute,
    private nzMessageService: NzMessageService,
    private modalService: OkaModalService,
    private warehouseApiService: WarehouseApiService,
    private indexDBService: IndexDBService,
    @Inject(DOCUMENT) private document: Document
  ) {
    super(activatedRoute);
  }

  ngOnInit(): void {
    this.recentPackages = [];
    this.scannedItems = [];
    this.caculateNumberOfProducts();
    this.getBuyerList();
  }

  addItem() {
    if (!this.currentProductUPC || !this.currentProductSerial) {
      // DO NOTHING
    } else if (this.currentProductUPC === this.currentProductSerial) {
      this.nzMessageService.error('UPC and Serial cannot have the same value!');
    } else if (this.scannedItems.filter((i) => i.serial === this.currentProductSerial).length) {
      this.nzMessageService.error('Cannot add the same serial twice!');
    } else {
      this.scannedItems.push({
        upc: this.currentProductUPC,
        serial: this.currentProductSerial
      });
      this.currentProductUPC = '';
      this.currentProductSerial = '';
      this.currentProductUPCIsValid = false;
      this.upcFormElement.nativeElement.focus();
    }
  }

  addMoreItemsToExistingTrackingNumber() {
    this.showTrackingNumberAlreadyCheckedInErrorModal = false;
    this.trackingNumberStatus = 'appending';
    this.showBuyerLookup = false;
    setTimeout(() => {
      this.upcFormElement.nativeElement.focus();
    });
  }

  clearCurrentPackage() {
    this.currentTrackingNumber = '';
    this.scannedItems = [];
    this.clearCurrentProduct();
  }

  caculateNumberOfProducts() {
    for (const recentPackage of this.recentPackages) {
      this.noOfProducts += recentPackage.products.length;
    }
  }

  clearCurrentProduct() {
    this.currentProductUPC = '';
    this.currentProductSerial = '';
    this.currentProductUPCIsValid = false;
  }

  completePackage() {
    if (this.currentTrackingNumber.length > 0) {
      if (!this.offlineMode) {
        this.nzMessageService.success('The Package is being processed. Please wait for the upload to be complete.');
      }

      const data: any = {
        tracking_number: this.currentTrackingNumber,
        products: this.scannedItems
      };

      if (this.showBuyerLookup && this.buyerLookupValue) {
        data.buyer_id = this.buyerLookupValue;
      }

      if (!this.offlineMode) {
        this.warehouseApiService.checkInPackage(data).subscribe(
          (responseJson: any) => {
            this.completePackageSuccessCallback();
          },
          (error: any) => {
            console.error('Unable to checkin package');
            console.error(error);
            this.nzMessageService.error('Unable to checkin package');
          }
        );
      } else {
        // Store the packages in the IndexDB, when working in Offline Mode
        this.indexDBService
          .set(this.storeName, { date: new Date(), ...data })
          .then((key: string) => {
            this.completePackageSuccessCallback();
          })
          .catch((error) => {
            console.error('Unable to checkin package');
            console.error(error);
            this.nzMessageService.error('Unable to checkin package');
          });
      }
    }
  }

  completePackageSuccessCallback() {
    this.recentPackages.push({
      tracking_number: this.currentTrackingNumber,
      date_scanned: new Date(),
      products: this.scannedItems
    });
    this.clearCurrentPackage();
    this.caculateNumberOfProducts();
    this.resetPage();

    this.nzMessageService.success('Package added');
  }

  async createCustomSerial() {
    this.warehouseApiService.getProductUPC(this.currentProductUPC).subscribe((upcAndSku) => {
      const hash = md5(new Date().toString()).substr(0, 12);
      JsBarcode('#barcode', hash, { height: 30, width: 1 });

      setTimeout(() => {
        const printWindow = window.open();
        printWindow.document.open('text/html');
        console.log('this.barcode.nativeElement', this.barcode.nativeElement);
        console.log('this.barcode.nativeElement-2', this.barcode.nativeElement.children[0].getAttribute('height'));
        console.log('this.barcode.nativeElement-3', this.barcode.nativeElement.children[0].children[1]);

        this.barcode.nativeElement.children[0].setAttribute('height', '200px');
        this.barcode.nativeElement.children[0].setAttribute('viewBox', '0 0 187 200');

        const skuElement = this.document.createElement('text');
        skuElement.setAttribute('text-anchor', 'middle');
        skuElement.setAttribute('x', '83.5');
        skuElement.setAttribute('y', '80');
        skuElement.innerText = upcAndSku.sku;

        this.barcode.nativeElement.children[0].children[1].appendChild(skuElement);

        printWindow.document.write(`<html>
          <head>
            <title>Created Serial Number</title>
            <style></style>
          </head>
          <body>
            <div>
              <div style="width: 100%; margin-top: 0px;">
                ${this.barcode.nativeElement.innerHTML}
              </div>
            </div>
            <script>
            </script>
          </body>
          `);
      });
    });
  }

  focus(elem: ElementRef) {
    setTimeout(() => {
      elem.nativeElement.focus();
    });
  }

  closeAlreadyCheckedInModal() {
    this.showTrackingNumberAlreadyCheckedInErrorModal = false;
  }

  getBuyerList() {
    this.warehouseApiService.getBuyerNames().subscribe((buyerNames) => {
      this.buyerList = buyerNames;
    });
  }

  hideAllRecentPackages(event: any) {
    event.preventDefault();
    this.currentRecentPackagesLimit = this.minimumRecentPackagesLimit;
  }

  // TODO clean this up. Bad architecture. (low priority)
  offlineModeToggle(event: any) {
    const offlineModeBeforUpdate = this.offlineMode;
    const toggleModalComponent = this.offlineMode ? TurnOfflineModeOffModalComponent : TurnOfflineModeOnModalComponent;

    this.modalService.openModal(toggleModalComponent).subscribe((modalRef) => {
      modalRef.afterClose.subscribe((isSubmitted) => {
        if (isSubmitted) {
          this.offlineMode = this.offlineMode ? false : true;
        } else {
          this.offlineMode = this.offlineMode ? true : false;
        }

        // Ofline Mode On => Off
        if (offlineModeBeforUpdate && !this.offlineMode) {
          // Send bulk data to the server, when we switch off the offline mode
          this.synchronizeAllPackagesScannedWhileOffline();
        }
      });
    });
  }

  removeProduct(index: number) {
    this.scannedItems.splice(index, 1);
  }

  resetPage() {
    this.currentTrackingNumber = '';
    this.buyerLookupValue = '';
    this.currentProductUPC = '';
    this.currentProductSerial = '';
    this.trackingNumberStatus = 'invalid';
    this.currentProductUPCIsValid = false;
    this.showBuyerLookup = false;
    this.scannedItems = [];
  }

  retrieveTrackingNumber(trackingNumber: string) {
    const sanitizedTrackingNumber = sanitizeTrackingNumber(trackingNumber);

    if (this.offlineMode) {
      this.showBuyerLookup = true;
      this.currentTrackingNumber = sanitizedTrackingNumber;
      return;
    }

    this.currentTrackingNumber = sanitizedTrackingNumber;

    this.warehouseApiService.getTrackingNumber(sanitizedTrackingNumber).subscribe((result) => {
      if (!result) {
        this.showTrackingNumberErrorModal = true;
        this.audioPlayer.nativeElement.play();
        this.showBuyerLookup = true;
        this.currentTrackingNumber = sanitizedTrackingNumber;
      } else if (result.check_in_time) {
        this.showTrackingNumberAlreadyCheckedInErrorModal = true;
        this.trackingNumberStatus = 'invalid';
        this.showBuyerLookup = false;
      } else {
        this.trackingNumberStatus = 'never-checked-in';
        this.showBuyerLookup = false;
        // Since element is initially hidden, need to put in setTimeout
        setTimeout(() => {
          this.upcFormElement.nativeElement.focus();
        });
      }
    });
  }

  retrieveUPC(upc: string) {
    if (this.offlineMode) {
      this.currentProductUPCIsValid = true;
      this.focus(this.serialFormElement);
      return;
    }

    this.warehouseApiService.getProductUPC(upc).subscribe((result) => {
      if (!result) {
        this.showCurrentProductUPCErrorModal = true;
        this.currentProductUPCIsValid = false;
        this.upcFormElement.nativeElement.focus();
        this.upcFormElement.nativeElement.select();
        this.audioPlayer.nativeElement.play();
      } else {
        this.currentProductUPCIsValid = true;
        this.focus(this.serialFormElement);
      }
    });
  }

  trackingNumberAdded(newValue: string) {}

  onSearch(value: string) {
    const valueLowercase = value.toLocaleLowerCase();
    this.matchingBuyerListItems = this.buyerList.filter(
      (i) =>
        i.user_id.toString().toLocaleLowerCase().indexOf(valueLowercase) >= 0 ||
        i.first_name.toLocaleLowerCase().indexOf(valueLowercase) >= 0 ||
        i.last_name.toLocaleLowerCase().indexOf(valueLowercase) >= 0
    );
  }

  viewAllRecentPackages(event: any) {
    event.preventDefault();
    this.currentRecentPackagesLimit = this.recentPackages.length;
  }

  synchronizeAllPackagesScannedWhileOffline() {
    this.indexDBService
      .values(this.storeName)
      .then((packageData: any[]) => {
        packageData = packageData.filter((obj) => {
          if ('date' in obj) {
            delete obj['date'];
          }
          return obj;
        });
        if (packageData.length > 0) {
          this.warehouseApiService.bulkCheckInPackage(packageData).subscribe(
            (responseJson: any) => {
              this.indexDBService.clearDB(this.storeName);
              this.nzMessageService.info(responseJson.message, { nzDuration: 10000, nzPauseOnHover: true });
            },
            (error: any) => {
              console.error('Unable to upload the offline packages');
              console.error(error);
              this.nzMessageService.error('Unable to upload the offline packages', {
                nzDuration: 10000,
                nzPauseOnHover: true
              });
            }
          );
        }
      })
      .catch((error: Error) => {
        this.nzMessageService.error('No offline packages found');
      });
  }
}

/**
 * Apply special rules to determine which part of the tracking number is needed here
 */
function sanitizeTrackingNumber(trackingNumber: string) {
  if (trackingNumber[0].toLowerCase() === 'f') {
    return trackingNumber[0].substr(trackingNumber.length - 12);
  } else if (trackingNumber[0].toLowerCase() === 'u') {
    return trackingNumber[0].substr(9, trackingNumber.length - 1);
  } else if (trackingNumber[0].startsWith('420')) {
    return trackingNumber[0].substr(8, trackingNumber.length - 1);
  } else {
    return trackingNumber;
  }
}
