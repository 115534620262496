import { Profile, ProfilePopulated, ProfileSummary } from './profile';

export interface Match {
  id: number;
  shadchan_id: number;
  male_profile_id: number;
  female_profile_id: number;
  description?: string;
  created_on: Date;
  deleted_on?: Date;
}

export interface MatchFull extends Match {
  male: Profile;
  female: Profile;
  match_activities: MatchActivity[];
}
export interface MatchPopulated extends Match {
  male: ProfileSummary;
  female: ProfileSummary;
  match_activities: MatchActivity[];
}

export interface MatchActivity {
  id: number;
  match_id: number;
  match_action?: MatchActivityAction;
  additional_info?: string;
  created_on: Date;
  deleted_on?: Date;
}

export enum MatchActivityAction {
  redt = 'redt',
  investigating = 'investigating',
  accepted_by_male = 'accepted_by_male',
  accepted_by_female = 'accepted_by_female',
  rejected_by_male = 'rejected_by_male',
  rejected_by_female = 'rejected_by_female'
}
