<Card [full]="true" class="mb-2" style="margin-bottom: 1rem">
  <CardHeader
    [title]="profileData.last_name + ', ' + profileData.first_name"
    [extra]="extra"
    [thumb]="cardHeaderThumb"
    [thumbStyle]="thumbStyle"
  >
    <ng-template #extra>
      <span>{{ profileData.date_of_birth | age }}</span>
    </ng-template>
  </CardHeader>
  <CardBody (click)="openProfile()">
    <div>
      <div class="vital-data">
        <div class="vital-data-item" *ngIf="profileData.date_of_birth">
          <p class="data">
            <span nz-icon nzType="calendar" nzTheme="outline"></span>
            {{ profileData.date_of_birth | date : 'yyyy-MM-dd' }}
          </p>
        </div>
        <div class="vital-data-item" *ngIf="profileData.city">
          <p class="data">
            <span nz-icon nzType="home" nzTheme="outline"></span>
            {{ profileData.city }}
          </p>
        </div>
        <div class="vital-data-item" *ngIf="profileData.country">
          <p class="data">
            <span nz-icon nzType="global" nzTheme="outline"></span>
            {{ profileData.country }}
          </p>
        </div>
        <div class="vital-data-item" *ngIf="profileData.occupation">
          <p class="data">
            <span nz-icon nzType="tool" nzTheme="outline"></span>
            {{ profileData.occupation }}
          </p>
        </div>
        <div class="vital-data-item" *ngIf="profileData.religious_identity">
          <p>
            <span style="display: inline-block">
              <Icon [src]="'assets/icons/fedora.svg'" [size]="'sm'"></Icon
            ></span>

            {{ profileData.religious_identity | jsonProp }}

            <!-- <ng-container
              *ngFor="let religious_category of profileData.religious_seeking"
            >
              {{ religious_category.religious_identity | jsonProp }}
            </ng-container> -->
          </p>
        </div>
      </div>
    </div>
  </CardBody>

  <!-- <CardFooter [content]="'footer content'" [extra]="footerExtra">
    <ng-template #footerExtra>
      <div>extra footer content</div>
    </ng-template>
  </CardFooter> -->
</Card>

<ng-template #cardHeaderThumb>
  <!-- <pre>{{ profileData.profile_photo_url }}</pre> -->
  <img
    *ngIf="profileData.profile_photo_url"
    (click)="openProfileImageModal(profileData.id)"
    width="32"
    alt="profile-image"
    class="profile-photo"
    [src]="profileData.profile_photo_url"
  />
</ng-template>
