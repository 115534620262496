<nz-layout>
  <nz-content>
    <nz-row class="page-header" nzJustify="space-between">
      <nz-col><h2 class="mb-0">Advanced Settings</h2> </nz-col>
      <nz-col> </nz-col>
      <nz-col>
        <nz-button-group class="margin-left">
          <button nz-button (click)="refreshCache()">
            <i nz-icon nzType="reload" nzTheme="outline"></i>
          </button>
        </nz-button-group>
      </nz-col>
    </nz-row>

    <nz-row>
      <p>App works without Quickbooks integration &nbsp;</p>
      <nz-switch
        [(ngModel)]="advancedSettings.quickbooks_optional"
        (ngModelChange)="onAdvancedSettingUpdate('quickbooks_optional', $event)"
      ></nz-switch>
    </nz-row>
  </nz-content>
</nz-layout>
