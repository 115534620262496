import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ModalComponent } from '../../../../common/modal-common/modal.component';

@Component({
  selector: 'oka-generate-cheque-modal',
  templateUrl: './generate-cheque-modal.component.html',
  styleUrls: ['./generate-cheque-modal.component.scss']
})
export class GenerateChequeModalComponent extends ModalComponent implements OnInit, AfterViewInit {
  title = 'Generate Cheque';
  chequeIdGenerationForm: FormGroup = this._formBuilder.group({});
  inputs: {};

  constructor(nzModal: NzModalService, private nzMessageService: NzMessageService, private _formBuilder: FormBuilder) {
    super(nzModal);
  }

  ngOnInit(): void {
    this.chequeIdGenerationForm = new FormGroup({
      chequeId: new FormControl(undefined, [Validators.required, Validators.pattern(/^[1-9][0-9]*$/)])
    });
  }

  ngAfterViewInit(): void {
    this.openModal({}, () => {
      if (this.chequeIdGenerationForm.valid) {
        this.modal.close({
          proposedFirstChequeId: parseInt(this.chequeIdGenerationForm.get('chequeId').value)
        });
      } else {
        this.nzMessageService.error('Please enter a valid number');
      }
    });
  }
}
