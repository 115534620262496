<nz-layout>
  <nz-content>
    <nz-row nzJustify="space-between">
      <nz-col><h2 class="mb-0">Customer Invoices</h2></nz-col>
      <nz-col>
        <button nz-button nzType="primary" (click)="exportAsCSV()">Export as CSV</button>
      </nz-col>
    </nz-row>

    <nz-spin *ngIf="showSpinner" nzSimple></nz-spin>

    <oka-customer-invoices-table
      [data$]="customerInvoicesData"
      [columnHeaders]="columnHeaders"
    ></oka-customer-invoices-table>
  </nz-content>
</nz-layout>
