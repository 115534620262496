<nz-card class="mb-3" nzType="inner" nzTitle={{scannedPackage.tracking_number}}>
  <nz-card-tab>
    <nz-tabset nzSize="small" [(nzSelectedIndex)]="tabIndex">
      <nz-tab nzTitle="Products">
        <nz-table #smallTable nzSize="small" [nzFrontPagination]="false" [nzData]="scannedPackage.products">
          <thead>
            <tr>
              <th>UPC</th>
              <th>Serial</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let product of scannedPackage.products">
              <tr>
                <td>{{product.upc}}</td>
                <td>{{product.serial}}</td>
              </tr>
            </ng-container>
          </tbody>
        </nz-table>
      </nz-tab>
      <nz-tab nzTitle="Meta"></nz-tab>
    </nz-tabset>
  </nz-card-tab>
</nz-card>
