import { EventEmitter, Injectable } from "@angular/core";
import { SortByCategory, SortState } from "../../../common/constants";

export interface SortEvent {
  category: SortByCategory;
  direction: SortState;
}

@Injectable({
  providedIn: "root",
})
export class SortService {
  sortEvent = new EventEmitter<SortEvent>();

  constructor() {}

  emitSortEvent(sortEvent: SortEvent) {
    this.sortEvent.emit(sortEvent);
  }
}
