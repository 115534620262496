
<nz-layout>
  <nz-content>
    <nz-row class="page-header" nzJustify="space-between">
      <nz-col><h2 class="mb-0">Activity Feed</h2> </nz-col>
      <nz-col>

        <nz-button-group class="margin-left">
          <button nz-button (click)="refreshCache()">
            <i nz-icon nzType="reload" nzTheme="outline"></i>
          </button>
        </nz-button-group>
      </nz-col>
    </nz-row>

    <nz-row>
      <app-events-report></app-events-report>
    </nz-row>
  </nz-content>
</nz-layout>
