import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpResponse, HttpResponseBody } from '../../../../../types/misc';
import { environment } from '../../../../environments/environment';

const BASE_URL = environment.baseUrl + '' + environment.apiUrl + '/admin';

@Injectable({ providedIn: 'root' })
export class ProductCategoryService {
  constructor(private http: HttpClient) {}

  getProductCategories() {
    return this.http.get(BASE_URL + '/product-categories');
  }

  createProductCategory(category_name: string) {
    return this.http.put<HttpResponse>(BASE_URL + '/product-category', {
      category_name
    });
  }
}
