import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { BehaviorSubject } from 'rxjs';
import { CustomQuery } from '../../../../../../types/admin';
import { CustomQueryService } from '../../services/custom-query.service';

const count = 5;
const fakeDataUrl = 'https://randomuser.me/api/?results=5&inc=name,gender,email,nat&noinfo';

@Component({
  selector: 'app-custom-query',
  templateUrl: './custom-query-page.component.html',
  styleUrls: ['./custom-query-page.component.scss'],
  providers: [CustomQueryService]
})
export class CustomQueryPageComponent implements OnInit {
  customQueries: Omit<CustomQuery, 'sql'>[] = [];
  initLoading = true; // bug
  loadingMore = false;
  inputValue?: string;
  options: string[] = [];
  activeCustomQuery: Omit<CustomQuery, 'sql'> = {
    id: '',
    name: '',
    description: '',
    params: []
  };

  showSpinner = false;

  queryResults: BehaviorSubject<any[]> = new BehaviorSubject([]);
  queryResultsColumnHeaders: string[] = [];

  constructor(private customQueryService: CustomQueryService, private nzMessageService: NzMessageService) {}

  ngOnInit(): void {
    this.loadQueries();
  }

  getData(callback: (res: any) => void): void {
    // this.http.get(fakeDataUrl).subscribe((res: any) => callback(res));
  }

  loadQueries() {
    this.customQueryService.loadQueries().subscribe((customQueries) => {
      this.customQueries = customQueries;
      this.initLoading = false;
    });
  }

  onLoadMore(): void {
    // this.loadingMore = true;
    // this.list = this.data.concat([...Array(count)].fill({}).map(() => ({ loading: true, name: {} })));
    // this.http.get(fakeDataUrl).subscribe((res: any) => {
    //   this.data = this.data.concat(res.results);
    //   this.list = [...this.data];
    //   this.loadingMore = false;
    // });
  }

  edit(item: any): void {
    // this.msg.success(item.email);
  }

  onInput(event: Event): void {
    const value = (event.target as HTMLInputElement).value;
    this.options = value ? [value, value + value, value + value + value] : [];
  }

  runQuery(customQuery: CustomQuery) {
    this.showSpinner = true;
    this.activeCustomQuery = customQuery;

    this.customQueryService.runQuery(customQuery.id).subscribe((result: any[]) => {
      this.queryResults.next(result);
      if (result.length) {
        this.queryResultsColumnHeaders = Array.from(
          new Set(result.map((x) => Object.keys(x)).reduce((p, n) => p.concat(n)))
        );
      }
      this.showSpinner = false;
    });
  }

  setActiveQuery() {}
}
