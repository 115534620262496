<Icon
  Popover
  [ngStyle]="{
    height: '100%',
    display: 'flex',
    marginRight: '16px',
    'align-items': 'center'
  }"
  [type]="'ellipsis'"
  [mask]="true"
  [showArrow]="true"
  [overlay]="popoverContent"
  [placement]="'bottomRight'"
  (onSelect)="onSelect($event)"
  (onVisibleChange)="onVisibleChange($event)"
></Icon>

<ng-template #popoverContent>
  <PopoverItem [icon]="iconShareActions" (click)="showShareActions(message)"
    >Share</PopoverItem
  >
  <PopoverItem [icon]="iconProfileActions" (click)="showProfileActions(message)"
    >Actions</PopoverItem
  >
</ng-template>

<ng-template #iconShareActions>
  <Icon *ngIf="!isEditMode" [src]="'assets/icons/send.svg'"></Icon>
</ng-template>

<ng-template #iconProfileActions>
  <Icon [src]="'assets/icons/rocket.svg'"></Icon>
</ng-template>
