import { Component, ComponentRef, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ModalOptions, NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { Subject } from 'rxjs';

export class ModalItem {
  constructor(public id: string, public component: ComponentRef<any>) {}
}

export class Modal {
  id: string = null;

  context = false; // Temporary. it is meant to tell if the modal has already been initialized or not
  inputs?: any;
  options?: NgbModalOptions;
  size?: string;
  title?: string;

  // Callbacks
  onSubmit?: (modal: ModalComponent, data?: any) => void = () => {};
  onDelete?: (modal: ModalComponent, data?: any) => void = () => {};

  // Allow custom properties for different kinds of modals
  [x: string]: any;
}

@Component({
  template: ''
})
export abstract class ModalComponent implements OnDestroy {
  protected title: string;
  @ViewChild('tpl') protected content: TemplateRef<any>;
  modal: NzModalRef;
  modalRef$: Subject<NzModalRef> = new Subject();

  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  inputs: any;

  constructor(protected nzModal: NzModalService) {}

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  openModal(_modalOptions: Partial<ModalOptions>, onSubmit: () => void) {
    this.modal = this.nzModal.create({
      nzTitle: this.title,
      nzContent: this.content,
      nzFooter: [
        {
          label: 'Cancel',
          onClick: () => {
            this.modal.destroy();
          }
        },
        {
          label: 'Submit',
          type: 'primary',
          onClick: () => {
            onSubmit();
          }
        }
      ]
    });
    this.modalRef$.next(this.modal);
  }

  closeModal(): void {
    this.modal.close(this.modal);
  }
}
