import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { find } from '@common/third-party/micro-dash';
import { SelectionChangedEvent } from 'ag-grid-community';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzMessageService } from 'ng-zorro-antd/message';
import { v4 as uuidv4 } from 'uuid';
import { BuyerForAccountant } from '../../../../../types/buyer';
import { PackagedProductForAccountantHeaders } from '../../../../../types/packaged-product';
import { ColumnDefinitions } from '../../../../types-frontend/aggrid-types';
import { absAnimations } from '../../../animations';
import { OkaModalService } from '../../../common/modal-common/oka-modals.service';
import { currencyFormatter } from '../../../common/utils';
import { QuickbooksService } from '../../../services/quickbooks.service';
import { BaseUserPage } from '../../user-page';
import { SetPriceModalComponent } from '../modals/set-price-modal/set-price-modal.component';
import { AccountantApiService } from '../services/accountant.service';
import { PackagedProductForAccountant } from './../../../../../types/packaged-product.d';

@Component({
  selector: 'app-accounting-home-page',
  templateUrl: './accounting-homepage.component.html',
  styleUrls: ['./accounting-homepage.component.scss'],
  animations: absAnimations
})
export class AccountingHomepageComponent extends BaseUserPage implements OnInit {
  columnDefs: ColumnDefinitions<PackagedProductForAccountantHeaders>[] = [];
  rowData: PackagedProductForAccountant[] = [];

  buyerData: BuyerForAccountant[] = [];
  selectedBuyer: BuyerForAccountant;

  productData: PackagedProductForAccountant[] = [];
  loadingProducts = false;
  // selectedProducts = new Map<string, ProductForAccountant>();
  selectedProducts: PackagedProductForAccountant[] = [];

  purchaseOrderPriceTotal = 0;

  quikbookResponse: any;
  draftPurchaseOrder: PackagedProductForAccountant[] = [];

  constructor(
    private accountantApiService: AccountantApiService,
    activatedRoute: ActivatedRoute,
    private modalService: OkaModalService,
    private router: Router,
    private quickbookService: QuickbooksService,
    private messageService: NzMessageService,
    private drawerService: NzDrawerService
  ) {
    super(activatedRoute);
  }

  ngOnInit(): void {
    this.generateColumnDefs();
    this.loadBuyerData();
  }

  cancel() {}

  load() {}

  loadBuyerData() {
    this.accountantApiService.getBuyersWithOutstandingItems().subscribe((buyers) => {
      this.buyerData = buyers;
    });
  }

  loadCheckedInProductsForBuyer() {
    this.loadingProducts = true;
    if (!this.selectedBuyer) {
      this.messageService.error('No buyer selected');
    } else {
      this.accountantApiService
        .getPackagedProducts(this.selectedBuyer.username, undefined, true)
        .subscribe((products) => {
          console.log('Products', products);
          const productList: PackagedProductForAccountant[] = [];
          for (const product of products) {
            for (const item of product.items) {
              productList.push(item);
            }
          }
          this.productData = productList;
          this.loadingProducts = false;
        });
    }
  }

  isSelectedProduct(product: PackagedProductForAccountant) {
    return !!find(this.selectedProducts, (p) => p.product_serial === product.product_serial);
  }

  onSelectionChanged(event: SelectionChangedEvent) {
    const nodes = event.api.getSelectedNodes().map((n) => n.data as PackagedProductForAccountant);
  }

  onProductSelected(product: PackagedProductForAccountant, checked: boolean): void {
    this.updateSelectedProduct(product, checked);
    // this.refreshCheckedStatus();
  }

  createPurchaseOrder() {
    const serialNumbers = [];
    for (const item of this.selectedProducts) {
      serialNumbers.push(item.product_serial);
    }
    const purchaseOrderTotal = this.draftPurchaseOrder.reduce((acc, i) => acc + i.price, 0);
    const purchaseOrderName = uuidv4().replace(/-/g, '');
    const purchaseOrderNotes = null;

    this.accountantApiService
      .createPurchaseOrder({
        buyerUsername: this.selectedBuyer.username,
        name: purchaseOrderName,
        notes: purchaseOrderNotes,
        calculatedTotal: this.purchaseOrderPriceTotal,
        serialNumbers
      })
      .subscribe(
        (responseJson: any) => {
          this.router.navigate(['user', 'accountant', 'purchase-orders', String(responseJson.data)]);
          this.draftPurchaseOrder = [];
        },
        (error) => {
          console.error(error);
          this.messageService.error('Error creating purchase order!');
        }
      );
  }

  createPurchaseOrderForAll() {
    const buyerUsernames = [];
    for (const buyerUser of this.buyerData) {
      buyerUsernames.push(buyerUser.username);
    }

    this.accountantApiService.createAllPurchaseOrders(buyerUsernames).subscribe(
      (responseJson: any) => {
        if (responseJson?.data.length === 0) {
          this.messageService.error('No purchase orders created');
        } else {
          this.messageService.success('Purchase orders for the buyer created');
        }
      },
      (error) => {
        console.error(error);
        this.messageService.error('Error in creating all purchase orders!');
      }
    );
  }

  onSelectAllProducts(isChecked: boolean) {
    this.productData.forEach((p) => {
      this.updateSelectedProduct(p, isChecked);
    });
  }

  selectBuyer(buyer: BuyerForAccountant) {
    this.selectedBuyer = buyer;
    this.loadCheckedInProductsForBuyer();
    this.selectedProducts = [];
    this.purchaseOrderPriceTotal = 0;
  }

  updateSelectedProduct(product: PackagedProductForAccountant, checked: boolean): void {
    if (checked) {
      this.selectedProducts.push(product);
    } else {
      this.selectedProducts = this.selectedProducts.filter((p) => p.product_serial !== product.product_serial);
    }
    this.purchaseOrderPriceTotal = this.selectedProducts.reduce(
      (acc, i) => acc + (i.price || i.committed_deal_price || 0),
      0
    );
    this.purchaseOrderPriceTotal = parseFloat(this.purchaseOrderPriceTotal.toFixed(2));
  }

  setProductPrice(product: PackagedProductForAccountant) {
    const productSerial = product.product_serial;

    this.modalService.openModal(SetPriceModalComponent, { productSerial }).subscribe((modalRef) => {
      modalRef.afterClose.subscribe(() => {
        this.loadCheckedInProductsForBuyer();
        // Remove updated product from selected. Accountant must re-add it
        this.updateSelectedProduct(product, false);
      });
    });
  }

  private generateColumnDefs() {
    const columnDefsOptions = {
      sortable: true,
      filter: true,
      resizable: true
    };
    this.columnDefs.push({
      headerName: 'packaged_product_id',
      field: 'packaged_product_id',
      checkboxSelection: true,
      ...columnDefsOptions
    });
    this.columnDefs.push({
      headerName: 'price',
      field: 'price',
      valueFormatter: currencyFormatter,
      ...columnDefsOptions
    });
    const columnHeaders: PackagedProductForAccountantHeaders[] = [
      'tracking_number',
      'buyer',
      'product_serial',
      'product_name',
      'upc',
      'sku',
      'purchase_order'
    ];
    for (const fieldName of columnHeaders) {
      this.columnDefs.push({
        // headerName:fieldName.replace(/_/g, ' ').toUpperCase(),
        headerName: fieldName,
        field: fieldName,
        ...columnDefsOptions
      });
    }
  }
}
