<nz-layout>
  <nz-content>
    <nz-row class="page-header header" nzJustify="space-between">
      <nz-col><h2 class="mb-0">Deals</h2></nz-col>
      <nz-col>
        <button nz-button nzType="primary" (click)="createActiveDeal()">Create New Deal</button>
        <nz-button-group class="margin-left">
          <button nz-button (click)="refreshCache()">
            <i nz-icon nzType="reload" nzTheme="outline"></i>
          </button>
        </nz-button-group>
      </nz-col>
    </nz-row>

    <nz-spin *ngIf="showSpinner" nzSimple></nz-spin>

    <oka-active-deals-table
      *ngIf="!showSpinner"
      [data$]="dealsData"
      [columnHeaders]="columnHeaders"
    ></oka-active-deals-table>
  </nz-content>
</nz-layout>
