import { Component } from '@angular/core';
import { ShadchanService } from '../services/shadchan.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ActivatedRoute, Router, RouterConfigOptions } from '@angular/router';
import { ProfileFull } from '../../../../../types/profile';

@Component({
  selector: 'oka-profile-page',
  templateUrl: './profile-page.component.html',
  styleUrl: './profile-page.component.scss'
})
export class ProfilePageComponent {
  profileId: number;
  profileFull: ProfileFull = {} as ProfileFull;

  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router,
    // private injector: Injector,
    private apiService: ShadchanService,
    private nzMessageService: NzMessageService
  ) {
    // this.apiService = this.injector.get(AbstractApiService) as AdminPurchaseOrdersService;
  }

  ngOnInit(): void {
    this.profileId = this.activeRoute.snapshot.params.profileId;
    this.loadProfile();
  }

  loadProfile() {
    this.apiService.getFullProfile(this.profileId).subscribe(
      (result) => {
        this.profileFull = result;
      },
      (error) => {
        console.error('Error', error);
      }
    );
  }

  onOk(event) {}
  onDismiss() {}
}
