<ng-template #tpl>
  <form nz-form nzLayout="vertical" [formGroup]="createNewProductCategoryForm">
    <nz-form-item nzBlock>
      <nz-form-label nzRequired>Category Name</nz-form-label>
      <input nz-input formControlName="productCategoryName" />
    </nz-form-item>
  </form>
</ng-template>

<!-- <ng-template #footer>
  <button nz-button nzType="default" (click)="handleCancel()">Custom Callback</button>
  <button nz-button nzType="primary" (click)="handleSubmit()" [nzLoading]="isConfirmLoading">Custom Submit</button>
</ng-template> -->
