<oka-admin-base-details-page
  *ngIf="productData"
  [title]="upc"
  [subtitle]="productData.product_name"
  [description]="productData.product_description"
>
  <div class="top-row" nz-row [nzGutter]="{ xs: 8, sm: 8, md: 16, lg: 24 }">
    <div nz-col nzSpan="10">
      <base-details-core-info
        coreInputTitle="Product Information"
        [formGroup]="updateProductForm"
        (onResetFormData)="resetUpdateProductDataForm()"
        (onSubmitFormData)="updateProductData()"
      ></base-details-core-info>
    </div>

    <div nz-col nzSpan="6">
      <nz-card nzTitle="Actions">
        <div>
          <button nz-button nzSize="small" nzDanger (click)="deleteProduct(productData.product_id)">Delete Product</button>
        </div>
      </nz-card>
      <base-details-quickbooks-sync
        [quickbooksData]="quickbooksData"
        [isSyncingWithQuickbooks]="isSyncingWithQuickbooks"
        (onSynchronizeWithQuickbooks)="synchronizeWithQuickbooks()"
      ></base-details-quickbooks-sync>
    </div>

    <div nz-col nzSpan="8">
      <nz-card nzTitle="Product Images">
        <div *ngIf="productData && productData.product_primary_photo">
          <img class="product-image" [src]="productData.product_primary_photo" alt="" />
        </div>
      </nz-card>
    </div>
  </div>
</oka-admin-base-details-page>
