<nz-layout id="verification-layout">
  <nz-layout>
    <nz-content>
      <nz-card id="authForm" [nzExtra]="extraTemplate">
        <ActivityIndicator
          [animating]="isLoading"
          [size]="'large'"
          class="center-middle"
          *ngIf="isLoading"
        ></ActivityIndicator>
        <nz-alert
          *ngIf="errorMsg"
          nzType="error"
          [nzMessage]="errorMsg"
          [@animate]="{ value: '*', params: { y: '100%' } }"
          @animateOut
        ></nz-alert>
        <!-- Login Form -->
        <form
          nz-form
          [formGroup]="verificationForm"
          (submit)="submit()"
          [@animateStagger]="{ value: '100' }"
        >
          <nz-form-item [@animate]="{ value: '*', params: { y: '100%' } }">
            <nz-form-control
              nzErrorTip="Please enter the verification code sent to you"
            >
              <nz-input-group nzSize="large">
                <input
                  nz-input
                  placeholder="Verification Code"
                  formControlName="verification_code"
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item
            class="buttons"
            [@animate]="{ value: '*', params: { y: '100%' } }"
          >
            <nz-form-control>
              <button nzSize="large" nzBlock nz-button nzType="primary">
                Verify Account
              </button>
            </nz-form-control>
            <p
              class="sub-button mt-2 mb-0"
              [@animate]="{ value: '*', params: { y: '100%' } }"
              (click)="sendNewVerficationCode()"
            >
              Send New Code
            </p>
          </nz-form-item>
        </form>
      </nz-card>
      <ng-template #extraTemplate>
        <i
          style="font-size: 3rem"
          nz-icon
          nzType="lock"
          nzTheme="twotone"
          [@animate]="{ value: '*', params: { y: '-100%' } }"
        ></i>
      </ng-template>
    </nz-content>
  </nz-layout>
</nz-layout>
