import { Pipe, PipeTransform } from '@angular/core';

import { Type, ɵstringify as stringify } from '@angular/core';

export function invalidPipeArgumentError(type: Type<any>, value: Object) {
  return Error(`InvalidPipeArgument: '${value}' for pipe '${stringify(type)}'`);
}

@Pipe({ name: 'camelToTitle' })
export class CamelCase2TitleCasePipe implements PipeTransform {
  /**
   * @param value The string to transform to title case.
   */
  transform(value: string): string;
  transform(value: null | undefined): null;
  transform(value: string | null | undefined): string | null;
  transform(value: string | null | undefined): string | null {
    if (value == null) return null;
    if (typeof value !== 'string') {
      throw invalidPipeArgumentError(CamelCase2TitleCasePipe, value);
    }

    let result = value
      .replace(/([A-Z])/g, ' $1')
      .replace('_', ' ')
      .replace(/\s(\w)/g, (x) => x.toUpperCase());
    return result.charAt(0).toUpperCase() + result.slice(1);
  }
}
