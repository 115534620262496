<nz-card style="margin-bottom: 10px">
  <nz-alert *ngIf="message" nzType="error" [nzMessage]="message"></nz-alert>
  <nz-list nzItemLayout="horizontal">
    <nz-list-item>
      <nz-list-item-meta>
        <nz-list-item-meta-avatar>
          <img *ngIf="dealItem.product_primary_photo" width="272" alt="logo" [src]="dealItem.product_primary_photo" />
        </nz-list-item-meta-avatar>

        <nz-list-item-meta-title>
          {{ dealItem.product_name }} <strong>{{ dealItem.price | currency }}</strong>
        </nz-list-item-meta-title>
        <nz-list-item-meta-description>
          <p>
            Deal #{{ dealItem.deal_id }} <span *ngIf="mode !== 'available'"> / {{ dealItem.buyer_deal_id }} </span>
          </p>
          <p class="deliver-by" *ngIf="dealItem.end_time">
            Must be delivered by <b>{{ dealItem.end_time | date: 'MM/d/y hh:mm' }}</b>
          </p>
          {{ dealItem.product_description }}
          <div class="d-block mt-2" *ngIf="mode === 'available'">
            Buy:
            <nz-input-number
              [nzMin]="1"
              [nzMax]="dealItem.available_quantity"
              [nzStep]="1"
              [(ngModel)]="buyQuantity"
              [nzSize]="'small'"
              nz
            ></nz-input-number>
            <button
              *ngIf="mode === 'available'"
              nz-button
              nzType="primary"
              nzSize="small"
              (click)="commitToDeal()"
              style="margin-left: 10px"
            >
              Confirm
            </button>
          </div>
          <!-- <div class="d-block mt-2" *ngIf="isDealCommitted">Committed: {{ dealItem.quantity }}</div> -->

          <div class="deal-sources" *ngIf="dealItem?.dealSources?.length > 0">
            <h3>Deal Sources</h3>
            <ul>
              <li *ngFor="let dealSource of dealItem?.dealSources">
                <a [href]="dealSource.source_link" target="_blank">{{ dealSource.source_name }}</a>
              </li>
            </ul>
          </div>

          <div>
            <ul>
              <nz-list-item-action *ngIf="isAdmin">
                <a nz-button nzType="primary" nzSize="small" (click)="editAD()">Edit Active Deal</a>
              </nz-list-item-action>
            </ul>
          </div>
        </nz-list-item-meta-description>
      </nz-list-item-meta>
      <nz-list-item-extra>
        <div class="committed-quantity" *ngIf="dealItem.quantity">
          <div *ngIf="mode !== 'available'">
            <h4>
              Committed <strong>{{ dealItem.quantity }} </strong>
            </h4>
            <h4>
              &nbsp;on
              <strong>
                {{ dealItem.timestamp | date: 'MM/d/y hh:mm' }}
              </strong>
            </h4>
          </div>

          <div *ngIf="mode !== 'available' && dealItem.completed_quantity > 0">
            <h4>
              Items Received:
              <strong>
                {{ dealItem.completed_quantity }}
              </strong>
            </h4>
          </div>

          <!-- For now, we will not allow cancellations when deal is partially filled -->
          <a (click)="toggleShowModifyQuantify()" nzSize="small" nz-button> Modify Quantity </a>
          <nz-input-number
            *ngIf="showModifyQuantify"
            [nzMin]="1"
            [nzMax]="dealItem.max_quantity"
            [nzStep]="1"
            [(ngModel)]="modifiedBuyQty"
            [nzSize]="'small'"
            nz
          ></nz-input-number>
          <a *ngIf="showModifyQuantify" nz-button nzType="primary" nzSize="small" (click)="modifyCommitment()">
            Submit
          </a>
          <a
            *ngIf="isDealCommitted && mode === 'committed' && !dealItem.completed_quantity"
            nz-button
            nzType="primary"
            nzSize="small"
            (click)="cancelCommitment()"
            >Cancel Deal Commitment</a
          >

          <div *ngIf="mode === 'cancelled'">
            Cancelled on
            <strong>
              {{ dealItem.cancelled_at | date: 'MMM d y, h:mm a' }}
            </strong>
          </div>
        </div>
      </nz-list-item-extra>
    </nz-list-item>
  </nz-list>
</nz-card>
