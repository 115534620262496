import { BuyerItemReportFlat } from '../types-frontend/buyer';

export const buyerReportsMock: BuyerItemReportFlat[] = [
  {
    tracking_number: 'Z100200300',
    created_at: new Date(),
    description: ''
  },
  {
    tracking_number: 'Z100200301',
    product_name: 'Cool Product',
    upc: '123456',
    sku: 'A100',
    product_serial: '000001',
    check_in_time: new Date()
  },
  {
    tracking_number: 'Z100200302',
    product_name: 'Boring Product',
    upc: '123456',
    sku: 'A100',
    product_serial: '000001',
    check_in_time: new Date()
  },
  {
    tracking_number: 'Z100200302',
    upc: '123456',
    sku: 'A100',
    product_serial: '000001',
    check_in_time: new Date(),
    created_at: new Date(),
    price: 10.5,
    purchase_order_id: 2,
    check_number: 50
  }
];
