<form nz-form [formGroup]="invoiceCreationForm">
  <div class="top-card">
    <nz-select
      nzPlaceHolder="Select Customer"
      nzAllowClear
      nzShowSearch
      nzServerSearch
      (nzOnSearch)="onCustomerSearch($event)"
      style="width: 100%"
      formControlName="customerName"
    >
      <ng-container *ngFor="let o of matchingCustomerListItems">
        <nz-option
          *ngIf="matchingCustomerListItems?.length > 0"
          [nzValue]="o.user_id"
          [nzLabel]="o.first_name + ' ' + o.last_name + ' / ' + o.user_id"
        ></nz-option>
      </ng-container>
      <nz-option
        *ngIf="
          matchingCustomerListItems?.length === 0 && customerList?.length === 0
        "
        nzDisabled
        nzCustomContent
      >
        <i nz-icon nzType="loading" class="loading-icon"></i> Loading Data...
      </nz-option>
      <nz-option
        *ngIf="
          matchingCustomerListItems?.length === 0 && customerList?.length > 0
        "
        nzDisabled
        nzCustomContent
      >
        <i nz-icon nzType="exclamation" nzTheme="outline"></i> No Results Found
      </nz-option>
    </nz-select>
  </div>

  <nz-divider></nz-divider>
  <!-- <ng-container *ngFor="let invoiceSkuItem of invoiceSkuItems"> -->

  <!-- <nz-collapse class="sku-items" nzGhost formArrayName="skuItems">
    <nz-collapse-panel nzHeader="Select by SKU" nzActive="true"> -->
  <div class="sku-items">
    <ng-container nzGhost formArrayName="skuItems">
      <nz-row
        [nzGutter]="[16, 0]"
        *ngFor="let skuItemForm of skuItems.controls; let i = index"
        [formGroupName]="i"
      >
        <ng-container [formGroup]="skuItemForm">
          <nz-col nzFlex="10">
            <nz-select
              nzPlaceHolder="Select SKU"
              nzAllowClear
              nzShowSearch
              nzServerSearch
              (nzOnSearch)="onSkuSearch($event)"
              (ngModelChange)="onSkuSelection($event, i)"
              style="width: 100%"
              [attr.id]="'select-sku'"
              formControlName="sku"
            >
              <ng-container *ngFor="let o of matchingSKUs">
                <nz-option
                  *ngIf="matchingSKUs?.length > 0"
                  [nzValue]="o.sku"
                  [nzLabel]="o.sku"
                ></nz-option>
              </ng-container>

              <nz-option
                *ngIf="matchingSKUs?.length === 0 && skuList?.length === 0"
                nzDisabled
                nzCustomContent
              >
                <i nz-icon nzType="loading" class="loading-icon"></i> Loading
                Data...
              </nz-option>

              <nz-option
                *ngIf="matchingSKUs?.length === 0 && skuList?.length > 0"
                nzDisabled
                nzCustomContent
              >
                <i nz-icon nzType="exclamation" nzTheme="outline"></i>
                No Results Found
              </nz-option>
            </nz-select>
          </nz-col>

          <nz-col>
            <nz-form-item>
              <nz-form-label nzFor="quantity" [nzSpan]="null"
                >Quantity</nz-form-label
              >

              <nz-input-number
                [nzSize]="'large'"
                nzMin="1"
                [nzMax]="maxQty[i]"
                nzStep="1"
                [nzSize]="'small'"
                id="quantity"
                formControlName="quantity"
              ></nz-input-number>
            </nz-form-item>
          </nz-col>

          <nz-col>
            <nz-form-item>
              <nz-form-label nzFor="price" [nzSpan]="null">Price</nz-form-label>

              <nz-input-number
                nzMin="1"
                nzMax="10000"
                nzStep=".01"
                [nzSize]="'small'"
                id="price"
                formControlName="price"
              ></nz-input-number>
            </nz-form-item>
          </nz-col>

          <!-- <nz-row style="margin-top: 1rem">
      <nz-col nzSpan="24">
        <button
          nzType="default"
          [disabled]="!skuLookupValue || skuPrice === 0 || skuQty === 0"
          (click)="makeSelectionOfSKUs()"
          nz-button
        >
          Select Items
        </button>
      </nz-col>
    </nz-row> -->
        </ng-container>
      </nz-row>
    </ng-container>
  </div>
  <!-- </nz-collapse-panel> -->
  <!-- </nz-collapse> -->
  <!-- </ng-container> -->

  <button nzType="primary" (click)="addInvoiceSkuItem()" nz-button>
    Add Item
  </button>

  <nz-divider></nz-divider>

  <ng-container *ngFor="let item of items">
    <div class="purchase-order-item">
      <h4>{{ item.product_serial }}</h4>
      <nz-table nzSize="small" nzTemplateMode>
        <tbody>
          <tr>
            <td><b>Tracking Number</b></td>
            <td>{{ item.tracking_number }}</td>
          </tr>
          <tr>
            <td><b> Product</b></td>
            <td>{{ item.product_name }} - {{ item.upc }}</td>
          </tr>
          <tr>
            <td><b> Price </b></td>
            <td>{{ item.price | currency }}</td>
          </tr>
        </tbody>
      </nz-table>
    </div>
  </ng-container>
</form>

<nz-divider></nz-divider>

<div>
  <nz-table nzSize="small" nzTemplateMode>
    <tbody>
      <tr>
        <td><b>Total</b></td>
        <td>{{ priceTotal | currency }}</td>
      </tr>
    </tbody>
  </nz-table>
</div>

<div>
  <nz-divider></nz-divider>
  <button
    nzType="primary"
    [disabled]="!customerName.value || skuItems.length === 0"
    (click)="close()"
    nz-button
  >
    Create Invoice
  </button>
</div>
