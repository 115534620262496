import { ApplicationRef, ComponentFactoryResolver, Injectable, Injector, Type } from '@angular/core';
import { ModalComponent } from './modal.component';

@Injectable({ providedIn: 'root' })
export class OkaModalService {
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private appRef: ApplicationRef
  ) {}

  openModal<P extends ModalComponent>(modal: Type<P>, modalInput: P['inputs'] = {}) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(modal);
    const componentRef = componentFactory.create(this.injector);
    componentRef.instance.inputs = modalInput;
    this.appRef.attachView(componentRef.hostView);
    return componentRef.instance.modalRef$;
  }

  // TODO
  displayImportantMessage() {}
}
