import { Injectable } from '@angular/core';
import { SerialUPCPair } from '../../../../../types/product';

import { openDB, DBSchema, IDBPDatabase } from 'idb';

interface OfflinePackage {
  date: Date; // Date is used to maintain the order in which the packages would be checked in
  tracking_number: string;
  products: SerialUPCPair[];
  buyer_id?: number;
}

interface OfflineWarehouseDB extends DBSchema {
  packages: {
    value: OfflinePackage;
    key: string;
    indexes: { date: Date };
  };
}

export type storeNameType = 'packages';

@Injectable({
  providedIn: 'root'
})
export class IndexDBService {
  dbName = 'offlinewarehouse';

  indexDB: Promise<IDBPDatabase<OfflineWarehouseDB>> = openDB<OfflineWarehouseDB>(this.dbName, 1, {
    upgrade(db) {
      const packageStore = db.createObjectStore('packages', { keyPath: 'date' });
      packageStore.createIndex('date', 'date');
    }
  });

  async get(storeName: storeNameType, key: string): Promise<OfflinePackage> {
    return (await this.indexDB).get(storeName, key);
  }

  async set(storeName: storeNameType, value: OfflinePackage): Promise<string> {
    // The key needs to be part of the value object defined by the keyPath
    return (await this.indexDB).put(storeName, value);
  }

  async delete(storeName: storeNameType, key: string): Promise<void> {
    return (await this.indexDB).delete(storeName, key);
  }

  async clearDB(storeName: storeNameType,): Promise<void> {
    return (await this.indexDB).clear(storeName);
  }

  async keys(storeName: storeNameType,): Promise<string[]> {
    return (await this.indexDB).getAllKeys(storeName);
  }

  async values(storeName: storeNameType,): Promise<OfflinePackage[]> {
    return (await this.indexDB).getAllFromIndex(storeName, 'date');
  }
}
