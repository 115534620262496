<oka-admin-base-details-page [title]="username">
  <div class="top-row" nz-row [nzGutter]="{ xs: 8, sm: 8, md: 16, lg: 24 }">
    <div nz-col nzSpan="10">
      <base-details-core-info
        coreInputTitle="Account Information"
        [formGroup]="updateUserForm"
        (onResetFormData)="resetUpdateUserDataForm()"
        (onSubmitFormData)="updateUserData()"
      ></base-details-core-info>
    </div>

    <div nz-col nzSpan="4">
      <nz-card nzTitle="Actions">
        <div>
          <button nz-button nzSize="small" nzDanger (click)="deleteUser()" class="search-button">Delete User</button>
        </div>
      </nz-card>
      <base-details-quickbooks-sync *ngIf="userType === 'buyer'"
        [quickbooksData]="quickbooksData"
        [isSyncingWithQuickbooks]="isSyncingWithQuickbooks"
        (onSynchronizeWithQuickbooks)="synchronizeWithQuickbooks()"
      ></base-details-quickbooks-sync>
    </div>

    <div nz-col nzSpan="10">
      <nz-card nzTitle="User Stats">
        <div></div>
      </nz-card>
    </div>
  </div>

  <nz-divider nzPlain nzText="Historical Data" nzOrientation="left"></nz-divider>

  <div nz-row [nzGutter]="{ xs: 8, sm: 8, md: 16, lg: 24 }">
    <div nz-col nzSpan="8">
      <nz-card nzTitle="Committed Deals"> Under Construction </nz-card>
    </div>
    <div nz-col nzSpan="8">
      <nz-card nzTitle="Tracking Numbers"> Under Construction </nz-card>
    </div>
    <div nz-col nzSpan="8">
      <nz-card nzTitle="Purchase Orders"> Under Construction </nz-card>
    </div>
  </div>

  <nz-divider></nz-divider>

  <div nz-row>
    <div nz-col nzSpan="24">
      <nz-card nzTitle="Purchase Orders"> Under Construction </nz-card>
    </div>
  </div>
</oka-admin-base-details-page>
