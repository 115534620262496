import { Component, ContentChild, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'oka-infinite-list',
  templateUrl: './infinite-list.component.html',
  styleUrls: ['./infinite-list.component.scss']
})
export class InfiniteListComponent implements OnInit {
  @Input() dataSource;

  @ContentChild(TemplateRef) listItemTemplate: TemplateRef<any>;

  constructor() {}

  ngOnInit(): void {}
}
