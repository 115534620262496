import { Observable } from 'rxjs';

export const CACHE_TIME = 5 * 60 * 1000; // 5 minutes

export interface CacheEntry<T> {
  expiry: number;
  value: T;
}

export class CacheUtils<T> {
  private cache = new Map<number, CacheEntry<T>>();
  private readonly cacheDuration: number;

  constructor(cacheDuration: number, cacheDestroyer?: Observable<any>) {
    this.cacheDuration = cacheDuration;

    if (cacheDestroyer) {
      cacheDestroyer.subscribe(() => {
        this.clearCache();
      });
    }
  }

  clearCache() {
    this.cache = new Map<number, CacheEntry<T>>();
  }

  deleteFromCache(id: number): void {
    this.cache.delete(id);
  }

  getFromCache(id: number): T | null {
    const idParsed = parseInt(String(id));
    const currentTime = Date.now();
    const cachedEntry = this.cache.get(idParsed);

    if (cachedEntry && cachedEntry.expiry > currentTime) {
      return cachedEntry.value;
    }

    return null;
  }

  setInCache(id: number, value: T): void {
    const idParsed = parseInt(String(id));
    const expiry = Date.now() + this.cacheDuration;
    this.cache.set(idParsed, { value, expiry });
  }
}
