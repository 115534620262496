<nz-page-header>
  <nz-page-header-title>Deals</nz-page-header-title>
  <nz-page-header-subtitle></nz-page-header-subtitle>
  <nz-page-header-extra> </nz-page-header-extra>
</nz-page-header>

<nz-tabset>
  <nz-tab (nzClick)="tabChange('available-deals')" nzTitle="Available Deals">
    <div class="row">
      <div class="col">
        <nz-spin *ngIf="isLoading" nzSimple></nz-spin>
        <ng-container *ngFor="let dealItem of dealsActive">
          <app-buyer-deal-item [dealItem]="dealItem" mode="available"></app-buyer-deal-item>
        </ng-container>
      </div>
    </div>
  </nz-tab>

  <nz-tab (nzClick)="tabChange('committed-deals')" nzTitle="Committed Deals">
    <div class="row">
      <div class="col">
        <nz-spin *ngIf="isLoading" nzSimple></nz-spin>
        <ng-container *ngFor="let dealItem of dealsCommitted">
          <app-buyer-deal-item [dealItem]="dealItem" mode="committed"></app-buyer-deal-item>
        </ng-container>
        <div *ngIf="!dealsCommitted.length">No Active Committed Deals</div>
      </div>
    </div>
  </nz-tab>

  <nz-tab (nzClick)="tabChange('completed-deals')" nzTitle="Completed Deals">
    <div class="row">
      <div class="col">
        <nz-spin *ngIf="isLoading" nzSimple></nz-spin>
        <ng-container *ngFor="let dealItem of dealsCompleted">
          <app-buyer-deal-item [dealItem]="dealItem" mode="completed"></app-buyer-deal-item>
        </ng-container>
        <div *ngIf="!dealsCompleted.length">No Completed Deals</div>
      </div>
    </div>
  </nz-tab>

  <nz-tab (nzClick)="tabChange('cancelled-deals')" nzTitle="Cancelled Deals">
    <div class="row">
      <div class="col">
        <nz-spin *ngIf="isLoading" nzSimple></nz-spin>
        <ng-container *ngFor="let dealItem of dealsCancelled">
          <app-buyer-deal-item [dealItem]="dealItem" mode="cancelled"></app-buyer-deal-item>
        </ng-container>
        <div *ngIf="!dealsCancelled.length">No Cancelled Committed Deals</div>
      </div>
    </div>
  </nz-tab>
</nz-tabset>
